import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { Text, View } from "../../components/BaseComponents/styles";
import { Box } from "../../components/Box";
import FlexPanel from "../../components/FlexPanel";
import { Line } from "../../components/Line/styles";
import Stats from "../../services/Stats";
import LineChart from "../../components/LineChart";
import NoData from "../../components/NoData";
import { array2Obj, percentOfTotal } from "../../utils";
import { PercentCircle } from "./PercentCircle";
import { Circle } from "./styles";
import { useTranslation } from "react-i18next";
import { DropdownSelect } from "../../components/DropdownSelect/styles";
import List from "../../services/List";
import { useOutletContext } from "react-router-dom";


export default () => {
  
  
  const [ loading, setLoading ] = useState(false);
  const [ loadingUsage, setLoadingUsage ] = useState(false);

  const [ overtimeData, setOvertimeData ] = useState([]);
  const [ usageData, setUsageData ] = useState();

  const { t } = useTranslation('common');
  
  const [ filters, setFilters ] = useState({});
  const [ sectors, setSectors ] = useState([]);
  const [ businessUnits, setBusinessUnits ] = useState([]);
  const [ setOptions ] = useOutletContext();

  const loadFilters = async () => {
    const {business_units: _businessUnits} = await List.businessUnits();
    setBusinessUnits(_businessUnits.map(unit => unit.title).filter(unit => unit.length != 0));
    const { sectors: _sectors} = await List.sectors();
    setSectors(_sectors.map(sector => sector.title).filter(sector => sector.length != 0));
  }

  const setFilter = (param, value) => {
    setFilters(prev => {
      let _newFilters = {...prev};
      _newFilters[param] = value;
      return _newFilters;
    });
  }

  useEffect(()=>{
    loadFilters();
  }, []);

  useEffect(()=>{
    setOptions({
      end: [
        <DropdownSelect onChange={(e)=>setFilter('business_unit',e.currentTarget.value)} value={filters.business_unit}>
          <option value="">{t('all_business_units')}</option>
          {businessUnits.map(b => { return <option value={b}>{b}</option>} )}
        </DropdownSelect>,
        <DropdownSelect onChange={(e)=>setFilter('sector',e.currentTarget.value)} value={filters.sector}>
          <option value="">{t('all_sectors')}</option>
          {sectors.map(s => { return <option value={s}>{s}</option>} )}
        </DropdownSelect>
      ]
    });
    
    return () => setOptions(null);
  }, [ sectors, businessUnits ]);
  
  const getUsage = async () => {
    setLoadingUsage(true)
    const data = await Stats.usageDetails(filters);

    const _categories = data.activity.categories;
    const _usage = data.usage_data;

    const _body = _categories.filter(c => c.name === 'Corpo')[0];
    const _mind = _categories.filter(c => c.name === 'Mente')[0];
    const _food = _categories.filter(c => c.name === 'Alimentação')[0];

    setUsageData({
      categories: {
        body: {
          usage_percent: percentOfTotal(_body.total, _usage[0].active_users),
          subcategories: _body.subcategories
        },
        mind: {
          usage_percent: percentOfTotal(_mind.total, _usage[0].active_users),
          subcategories: _mind.subcategories
        },
        food: {
          usage_percent: percentOfTotal(_food.total, _usage[0].active_users),
          subcategories: _food.subcategories
        },
      }
    });
    setLoadingUsage(false);

    
  }

  const getOvertimeData = async () => {
    const { stats: data } = await Stats.overtimeUsage(filters);
    const pointStyle = 'point { stroke-width: 2; fill-color: #36B37E; stroke-color: white; }';
    const raw = array2Obj(data, 'period');
    const stats = [];
    for (let i = 0; i <= 30; i++) {
      stats.unshift([ moment().subtract(i, 'd').format('DD MMM'), raw[i]?.active_users || 0, pointStyle ]);
    }
    stats.unshift([ 'x', 'Usuários Ativos', { 'type': 'string', 'role': 'style' } ]);
    setOvertimeData(stats);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getOvertimeData();
    getUsage();
  }, [filters]);

  const CategoryUsage = ({data, color, subtitleColor}) => {
    return (
      <>
      <View>
        <Line align={'center'}>
          <PercentCircle size={200} percentage={data.usage_percent} color={color}/>
        </Line>

        <View style={{display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
          {data.subcategories.map(s=>{
            return <Line style={{margin: '10px'}}>
              <Circle color={subtitleColor || color} size={10}></Circle>
              <Text style={{marginLeft: 5}} size={12} color={'black'}>{s.total} - {s.name}</Text>
            </Line>
          })}
          </View>
        </View>
      </>
    );
  }
  

  return (
  <>
    <FlexPanel>
      <Box 
        title={'Corpo'} 
        headStyle={{ border: 0, padding: '1rem 1.5rem' }}
        bodyStyle={{ overflow: 'auto', height: '300px', paddingTop: 0 }}
      >
        {!usageData ?
          <NoData loading={loadingUsage}/>
        :
          <CategoryUsage data={usageData.categories.body} color={'#3ED1D2'}/>
        }
      </Box>
      <Box 
        title={'Mente'} 
        headStyle={{ border: 0, padding: '1rem 1.5rem' }}
        bodyStyle={{ overflow: 'auto', height: '300px', paddingTop: 0 }}
      >
        {!usageData ?
          <NoData loading={loadingUsage}/>
        :
          <CategoryUsage data={usageData.categories.mind} color={'#FF8850'}/>
        }
      </Box>
      <Box 
        title={'Alimentação'} 
        headStyle={{ border: 0, padding: '1rem 1.5rem' }}
        bodyStyle={{ overflow: 'auto', height: '300px', paddingTop: 0 }}
      >
        {!usageData ?
          <NoData loading={loadingUsage}/>
        :
          <CategoryUsage data={usageData.categories.food} color={'#FA3333'}/>
        }
      </Box>
    </FlexPanel>

    <Box title={'Usuários Ativos por Período'} noPadding>
      {!overtimeData.length ?
        <NoData loading={loading}/>
      :
        <LineChart data={overtimeData} />
      }
    </Box>
  </>
  );
};