import styled from "styled-components";
import { Colors } from "../../values/colors";

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;

`;

export const Container = styled.div`
  position: relative;
`;
export const DropdownContainer = styled.div`
  position: absolute;
  border-radius: 7px;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13);
  right: 0;
  top: 1rem;
  width: 200px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  z-index: 100;
  &.show {
    opacity: 1;
    visibility: visible;
  }
`;

export const DropdownItem = styled.button`
	background: none;
	color: ${props => props.color || 'black'};
	border: none;
	outline: inherit;
	font-size: 14px;
	cursor: pointer !important;
  font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
  padding: 1rem;
  width: 100%;
  transition: background .1s ease-in-out;
  text-align: center;
  &:hover{
    background: #EEE;
  }
`;