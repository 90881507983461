import { useState } from "react";
import { Container, Content, StretchedContainer } from "./styles";
import Filter from '../../assets/icons/filter.svg';
import { useTranslation } from "react-i18next";
import Image from "../Image";
import { Input, Text, TextButton, Title } from "../BaseComponents/styles";
import { Line } from "../Line/styles";
import { Search, X } from "react-feather";
import { Colors } from "../../values/colors";
import TextBox from "../TextBox";
import Select from "react-select";
import { components } from "react-select";
import { useEffect } from "react";
import { Button } from "../Button";
import List from "../../services/List";
import { t } from "i18next";
import moment from 'moment';


const keyAliases = {
  sector: 'Setor',
  business_unit: 'BusinessUnit',
  city: 'Cidade',
  state: 'Estado',
  gender: 'Sexo',
  userType: 'Tipo de usuário'
}


export const FilterItem = ({ name, values, filters, onChange }) => {

  const { t } = useTranslation('common');
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);

  const removeItem = ({ value }) => {
    const index = selected.findIndex((d) => d.value == value);
    setSelected(prev => {
      return prev.filter((d, i) => i !== index);
    });
  }

  useEffect(() => {
    if (!filters.hasOwnProperty(keyAliases[name])) {
      setSelected(options[0]);
    }
  }, [filters]);

  useEffect(() => {
    const ops = formattedValues(values);
    setOptions(ops);
    setSelected(ops[0])
  }, [values]);

  const formattedValues = (v) => {
    const dataWithAll = [{ value: '[ALL]', label: 'Todos' }, ...v.map(d => ({ value: d, label: d }))];
    return dataWithAll;
  }

  useEffect(() => {
    onChange && onChange(name, selected?.value);
  }, [selected]);

  return (
    <StretchedContainer>
      <Content>
        <Title size={16}>{t(name)}</Title>
        <Select
          onChange={(value) => setSelected(value)}
          value={selected}
          styles={{
            container: (baseStyles, state) => ({
              ...baseStyles,
              marginTop: 10
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? Colors.primaryPurple : baseStyles.borderColor,
              boxShadow: 'none'
            }),
            option: (baseStyles, state) => ({
              ...baseStyles,
              background: state.isSelected ? Colors.primaryPurple :
                (state.isFocused ? Colors.primaryPurple + '33' :
                  baseStyles.background)
            }),
            menu: (baseStyles, state) => ({
              ...baseStyles,
              maxHeight: 200,
            }),
            menuList: (baseStyles, state) => ({
              ...baseStyles,
              maxHeight: 200,
            }),
          }}
          noOptionsMessage={() => 'Nenhuma opção'}
          options={options}
          components={{ DropdownIndicator: (props) => <components.DropdownIndicator {...props}><Search size={18} color={Colors.primaryColor} /></components.DropdownIndicator> }}
          placeholder={''}
        />
        {/* <div style={{marginTop: 10}}>
          {selected.map(s => {
            return <Line key={s} style={{marginTop: 8}}><TextButton><X size={20} onClick={()=>removeItem(s)}/></TextButton> <Text color={Colors.primaryColor}>{s}</Text></Line>
          })}
        </div> */}
      </Content>
    </StretchedContainer>
  );

}


export const FilterItemRange = ({ name, filters, onChange }) => {
  const { t } = useTranslation('common');
  const [startAge, setStartAge] = useState(18);
  const [endAge, setEndAge] = useState(65);
  const [all, setAll] = useState(true);

  useEffect(() => {
    if (!filters.hasOwnProperty('AllAges')) {
      setAll(true);
    }
  }, [filters]);

  useEffect(() => {

    if (!onChange) {
      return;
    }

    if (all) {
      onChange('AllAges', true);
      onChange('IdadeInicial', 0);
      onChange('IdadeFinal', 1000);
      return;
    }

    onChange('AllAges', false);
    onChange('IdadeInicial', parseInt(startAge));
    onChange('IdadeFinal', parseInt(endAge));
  }, [startAge, endAge, all]);

  return (
    <StretchedContainer>
      <Content className="filter-content">
        <Title size={16}>{t(name)}</Title>

        <Line style={{ marginTop: 10 }}>
          <Text color={Colors.primaryColor}>{'Entre'}</Text>
          <Input type={'number'} min={0} max={endAge} disabled={all} className="filter-input" value={startAge} onChange={(e) => setStartAge(e.currentTarget.value)} />
          <Text color={Colors.primaryColor}>{'e'}</Text>
          <Input type={'number'} min={startAge} max={120} disabled={all} className="filter-input" value={endAge} onChange={(e) => setEndAge(e.currentTarget.value)} />
        </Line>

        <TextButton style={{ marginTop: 10 }}>
          <Line onClick={(e) => setAll(!all)}>
            <Input type="checkbox" color={Colors.mutedGray} value="1" onChange={(e) => setAll(e.currentTarget.value)} checked={all} />
            <Text color={Colors.primaryColor} weight={700} style={{ marginLeft: 5 }}>Todas as idades</Text>
          </Line>
        </TextButton>

      </Content>
    </StretchedContainer>
  );

}

export const FilterItemRadio = ({ name, onChange, filters, values }) => {
  const { t } = useTranslation('common');
  const [selected, setSelected] = useState([]);


  useEffect(() => {
    if (!filters.hasOwnProperty(keyAliases[name])) {
      setSelected();
    }
  }, [filters]);

  useEffect(() => {
    onChange && onChange(name, selected);
  }, [selected]);

  if (values.length == 0) {
    return null;
  }

  return (
    <StretchedContainer>
      <Content className="filter-content">
        <Title size={16}>{t(name)}</Title>

        <Line wrap="wrap" style={{ marginTop: 10 }}>
          {values.map(v => (
            <Button
              key={v}
              onClick={() => {
                if (selected == v) {
                  setSelected()
                } else {
                  setSelected(v)
                }
              }}
              rounded
              style={{
                width: '100%',
                border: selected == v ? 'none' : '1px solid ' + Colors.primaryColor,
                marginBottom: 10
              }}
              color={selected == v ? Colors.primaryPurple : 'white'}
              textColor={selected == v ? Colors.white : Colors.primaryColor}
            >
              {t(v)}
            </Button>
          ))}
        </Line>

      </Content>
    </StretchedContainer>
  );

}

export const BoxFilter = ({ isOpen, onFilter }) => {

  const { t } = useTranslation('common');
  const [opened, setOpened] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [genders, setGenders] = useState([]);
  const [filters, setFilters] = useState({});
  const [userType, setUserType] = useState([]);

  useEffect(() => {
    isOpen && isOpen(opened);
  }, [opened]);

  useEffect(() => {
    loadFilters();
  }, []);

  const loadFilters = async () => {
    try {
      const { data: dashboardFilters } = await List.dashboardFilters();
      setSectors(dashboardFilters.sector);
      setBusinessUnits(dashboardFilters.business_unit);
      setStates(dashboardFilters.state);
      setCities(dashboardFilters.city);
      setGenders(dashboardFilters.gender);
      setUserType(['holder','dependent'])
    } catch (e) {
      console.log(e);
    }
  }

  const setFilter = (param, value) => {

    setFilters(prev => {
      let _newFilters = { ...prev };
      if (value === null || value === undefined || value === '') {
        _newFilters[keyAliases[param] || param] = '[ALL]';
      } else {
        _newFilters[keyAliases[param] || param] = value;
      }
      return _newFilters;
    });
  }

  const submit = () => {
    onFilter && onFilter(filters);
    setOpened(false);
  }

  return (
    <Container className={opened ? 'open' : ''} onClick={() => !opened && setOpened(true)}>

      <Line align="space-between" className="top">
        <span className="fake-button">
          <svg className="icon" width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.91667 13.5C8.60972 13.5 8.35261 13.396 8.14533 13.188C7.93733 12.9807 7.83333 12.7236 7.83333 12.4167C7.83333 12.1097 7.93733 11.8526 8.14533 11.6453C8.35261 11.4373 8.60972 11.3333 8.91667 11.3333H11.0833C11.3903 11.3333 11.6478 11.4373 11.8558 11.6453C12.063 11.8526 12.1667 12.1097 12.1667 12.4167C12.1667 12.7236 12.063 12.9807 11.8558 13.188C11.6478 13.396 11.3903 13.5 11.0833 13.5H8.91667ZM1.33333 2.66667C1.02639 2.66667 0.769278 2.56303 0.562 2.35575C0.354 2.14775 0.25 1.89028 0.25 1.58333C0.25 1.27639 0.354 1.01892 0.562 0.810917C0.769278 0.603639 1.02639 0.5 1.33333 0.5H18.6667C18.9736 0.5 19.2307 0.603639 19.438 0.810917C19.646 1.01892 19.75 1.27639 19.75 1.58333C19.75 1.89028 19.646 2.14775 19.438 2.35575C19.2307 2.56303 18.9736 2.66667 18.6667 2.66667H1.33333ZM4.58333 8.08333C4.27639 8.08333 4.01892 7.97933 3.81092 7.77133C3.60364 7.56406 3.5 7.30694 3.5 7C3.5 6.69306 3.60364 6.43558 3.81092 6.22758C4.01892 6.02031 4.27639 5.91667 4.58333 5.91667H15.4167C15.7236 5.91667 15.9807 6.02031 16.188 6.22758C16.396 6.43558 16.5 6.69306 16.5 7C16.5 7.30694 16.396 7.56406 16.188 7.77133C15.9807 7.97933 15.7236 8.08333 15.4167 8.08333H4.58333Z" fill="white" />
          </svg>
          <Text className="title" color={'white'} weight={'bold'}>{t('filters')}</Text>
        </span>

        {opened && <X color={Colors.primaryColor} onClick={() => setOpened(false)} />}
      </Line>
      <div style={{
        display: opened ? 'block' : 'none',
        width: '100%'
      }}>
        <Line wrap="wrap" gap="16" alignItems={'space-between'} className="filter-body">
          <FilterItem name="state" filters={filters} values={states} onChange={setFilter} />
          <FilterItem name="city" filters={filters} values={cities} onChange={setFilter} />
          <FilterItem name="business_unit" filters={filters} values={businessUnits} onChange={setFilter} />
          <FilterItem name="sector" filters={filters} values={sectors} onChange={setFilter} />
          <FilterItemRange name="age" filters={filters} onChange={setFilter} />
          <FilterItemRadio name="gender" filters={filters} values={genders} onChange={setFilter} />
          <Line style={{ marginRight: '48rem', marginTop: '-55px'}}>
            <FilterItemRadio name="userType" filters={filters} values={userType} onChange={setFilter} />
          </Line>
        </Line>
        <Line style={{ width: '100%', marginTop: '1rem', paddingTop: '1rem', borderTop: '1px solid #eee' }} gap="20" align="end">
          <TextButton onClick={() => setFilters({})}><Text weight="bold">Limpar filtros</Text></TextButton>
          <Button onClick={submit} color={Colors.primaryPurple}>Filtrar</Button>
        </Line>
      </div>
    </Container>
  );

}