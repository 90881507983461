import styled from "styled-components";
import { Colors } from "../../values/colors";

export const Background = styled.div`
  background-color: ${Colors.primaryColor};
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
`;

export const CardBusinessContainer = styled.div`
  position: relative;
  &.child{
    margin-left: calc(2rem + 12px);
    
    &:after{
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      background-color: #836AFB;
      border-radius: 100%;
      left: calc(-1rem - 12px);
      top: calc(30px + 6px);
    }
  
    &:not(:last-child):before{
      content: '';
      position: absolute;
      width: 2px;
      background-color: #B7BBBE;
      height: 100%;
      left: calc(-1rem - 7px);
      top: calc(30px + 6px);
    }

    &.limit{
      margin-left: 0;

      &:after{
        background-color: ${Colors.secondaryPurple};
      }
    }
  }
`

export const CardBusiness = styled.button`
  font-size: 15px;
  font-weight: 600;
  background-color: #f5f5f5;
  width: 100%;
  cursor: pointer !important;
  border-radius: 15px;
  border: none;
  padding: 0 1rem;
  height: 60px;
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  transition: background-color .2s ease-in-out;

  .text{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .button{
    background-color: ${Colors.primaryPurple}2A;
    border-radius: 100%;
    padding: 15px;
  }

  &.parent {
    background-color: #EDEAFB;
    &:hover{
      background-color: #E3E0F1;
    }
  }

  &:hover {
    background-color: #e7e7e7;
  }

  &:active {
    background-color: #e0e0e0;
  }
`;

export const Dot = styled.div`
  width: 12px;
  height: 12px;
  background-color: #836AFB;
  border-radius: 100%;
  z-index: 1000000000;
  margin-right: 1rem;
  position: absolute;

  &:before{
    content: '';
    position: absolute;
    width: 2px;
    background-color: #B7BBBE;
    height: 100%;
    left: 0;
  }
`

export const ExpandIcon = styled.div`
  position: relative;
	width: 13px;
	height: 13px;
	
	&.show {
		&:before {
			transform: translatey(-50%) rotate(90deg);
			opacity: 0;
		}
		&:after {
			transform: translatey(-50%) rotate(0);
		}
	}
	
	&:before , &:after {
		content: "";
		display: block;
		background-color: #333;
		position: absolute;		
		top: 50%; 
    left: 0;
		width: 100%;
		height: 2px;
    zoom: 1.1;
		transition: .35s;
	}
	
	&:before {		
		transform: translatey(-50%);
	}
	
	&:after {
		transform: translatey(-50%) rotate(-90deg);
	}
`
