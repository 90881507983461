import styled from "styled-components";

export const Input = styled.input`
    background: transparent;
    border: 1px solid white;
    border-radius: 7px;
    max-width: 259px;
    padding: 12px 49px;
    color: white;
    font-size: 0.9rem;
    font-weight: 400;

    ::placeholder {
        color: #FFFFFF;
        opacity: 1;
      }
      
      :-ms-input-placeholder {
       color: #FFFFFF;
      }
      
      ::-ms-input-placeholder {
       color: #FFFFFF;
      }

    option {
        color: #000;
    }
`;

export const Icon = styled.p`
    position: absolute;
    left: 230px; 
    color: white; 
    top: 0px; 
    height: 100%; 
    display: flex; 
    align-items: center; 
    justify-content: center;
    `;