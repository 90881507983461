import { Outlet, useLocation, Navigate } from 'react-router-dom';
import Header from '../../components/Header';
import MacroContainer from '../../components/MacroContainer';
import Auth from '../../services/Auth';
import { MainContainer } from '../../components/MainContainer/styles';
import BreadCrumb from '../../components/BreadCrumb';
import { OptionsSection } from '../../components/OptionsSection';
import { useState } from 'react';

export default ({optionsComponents}) => {
  const { signedIn } = Auth;
  const location = useLocation();

  const [ options, setOptions ] = useState(null);

  return signedIn ? (
    <>
      <Header/>
      <OptionsSection options={options}/>
      <MainContainer>
        <MacroContainer>
          <Outlet context={[setOptions]}/>
        </MacroContainer> 
      </MainContainer>
    </>
  ): (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
};