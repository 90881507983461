import { useEffect } from "react";
import { useState } from "react";
import { ChevronLeft } from "react-feather";
import { useTranslation } from "react-i18next";
import { Colors } from "../../../values/colors";
import { Text, TextArea, TextButton, Title, View } from "../../BaseComponents/styles";
import { Button } from "../../Button";
import { FormField } from "../../FormField";
import { Line } from "../../Line/styles";

export const Suggestions = ({onClose}) => {

  const [ t, i18n ] = useTranslation('common');

  const [ suggestion, setSuggestion] = useState('');
  
  return (
    <View>
      <Line>
        <ChevronLeft color={Colors.lightGray} onClick={onClose}/>
        <Title style={{marginLeft: '1rem'}}>{t('suggestions')}</Title>
      </Line>

      <View style={{marginTop: '2rem'}}>
        <form>
          <Text color={Colors.mediumGray}>{t('leave_here_your_suggestion')}</Text>

          <TextArea style={{marginTop: '1rem'}} height={300} value={suggestion} onChange={(e)=>setSuggestion(e.currentTarget.value)}/>

          <Line align={'center'} style={{marginTop: '2rem'}}>
            <Button type="button" color={Colors.primaryPurple}>{t('send_suggestion')}</Button>
          </Line>
        </form>
      </View>
    </View>
  );
}