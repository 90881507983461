import API from "../api"

export default class {
  static async changePassword (data) {
    const res = await API.post('changepassword', data);
    return res.data;
  }

  static async roles (){
    const res = await API.get('roles');
    return res.data
  }

  static async usageDataReport (bussines_unit, sector, day, format = 'json') {
    const response = await API.get(`/usagedata?business_unit=${bussines_unit}&sector=${sector}&days=${day}&format=${format}`,
      {
        responseType: 'blob'
      }
    );
    return response;
  }

}