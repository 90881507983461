import styled from "styled-components";
import { Colors } from "../../values/colors";

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;

`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  .text-button {
    position: relative;
    color: white;
    font-weight: 800;
    text-decoration: none;
  }

  &.active:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background: white;
    left: 0;
    bottom: -30px;
  }

`;
export const DropdownContainer = styled.div`
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13);
  right: -1.5rem;
  top: 52px;
  width: calc(100% + 3rem);
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  max-height: 0px;
  transition-property: opacity, max-height, visibility;
  transition-duration: .2s, .4s, .4s;
  transition-delay: .0s, 0s, 0s;
  z-index: 10;
  
  &.show {
    opacity: 1;
    visibility: visible;
    max-height: 500px;

  }

  .dropdown-item:last-child{
    border-bottom: none;
  }

  .dropdown-item{
    border-bottom: 1px solid ${Colors.lightGray};
    background: none;
    color: ${props => props.color || Colors.blueGray2};
    outline: inherit;
    font-size: 14px;
    cursor: pointer !important;
    font-weight: 700;
    font-size: 1rem;
    display: block;
    text-decoration: none;
    padding: .75rem 0;
    width: 100%;
    text-align: center;
    transition: background .1s ease-in-out;
    &:hover{
      background: #EEE;
    }
  }

`;