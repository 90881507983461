import { Outlet, useLocation, Navigate } from 'react-router-dom';
import Header from '../../components/Header';
import MacroContainer from '../../components/MacroContainer';
import Auth from '../../services/Auth';
import { MainContainer } from '../../components/MainContainer/styles';
import BreadCrumb from '../../components/BreadCrumb';
import { OptionsSection } from '../../components/OptionsSection';
import { useEffect, useLayoutEffect, useState } from 'react';

export default () => {

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://static.zdassets.com/ekr/snippet.js?key=e3c740c7-1ec3-40cc-8d00-b4fb477e9bd5";
    script.async = true;
    script.id = "ze-snippet";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <>
      <Outlet/>
    </>
  )
};