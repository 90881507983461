import { Frown, Loader } from "react-feather";
import { Container, Label, Spinner } from "./styles";
import { Button } from '../../components/Button';
import { Colors } from "../../values/colors";
import { Plus } from "react-feather";

export default ({ message = 'Dados insuficientes', buttonText, onButtonClick, loading = false }) => {
  if (loading) {
    return (
      <>
        <Container>
          <Spinner>
            <Loader color={'#BBC3CD'} size={30}/>
          </Spinner>
          <Label>Carregando dados...</Label>
        </Container>
      </>
    )
  } else {
    return (
      <>
        <Container>
          <Frown color={'#BBC3CD'} size={30} style={{ marginBottom: '1rem' }}/>
          <Label>{message}</Label>
          {buttonText && <Button color={Colors.defaultBlue} icon={<Plus size={18}/>} style={{ marginTop: '1rem' }} onClick={onButtonClick}>{buttonText}</Button>}
        </Container>
      </>
    )
  }
}