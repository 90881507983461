import styled from "styled-components";
import { Colors } from "../../../values/colors";

export const CircleWrap = styled.div`

  width: 33%;
  justify-content: space-around ;

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 1;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart .circle {
  stroke: ${props=>props.color || Colors.primaryColor};
}

.current {
  fill: ${Colors.defaultBlue};
  font-weight: 700;
  font-size: 0.35em;
  text-anchor: end;
}
.target {
  fill: #000;
  font-weight: 700;
  font-size: 0.2em;
  text-anchor: start;
}
`;
