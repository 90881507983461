import styled from 'styled-components'
import { Colors } from '../../../values/colors'
import { FlatButton } from "../../../components/BaseComponents/styles";

export const RejectModalHeader = styled.header`
  display: flex;
  flex-direction: column;

  gap: 16px;
`

export const TitleHeader = styled.h1`
  color: ${({ isReject }) => isReject ? Colors.redSecondary : Colors.secondaryPurple};
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
`
export const RejectText = styled.strong`
  color: ${Colors.blueGray2};
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`

export const RejectModalMain = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const InfoModalMain = styled.main`
  display: flex;
  justify-content: end;
`

export const BackButton = styled.button`
  width: 202px;
  height: 42px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  background: transparent;
  border-radius: 99px;
  border: none;

  :hover {
    cursor: pointer !important;
    opacity: 0.8;
  }
`

export const RejectButton = styled.button`
  width: 202px;
  height: 42px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  background: ${({ isReject }) => isReject ? Colors.redSecondary : Colors.secondaryPurple};
  color: #FFF;
  border-radius: 99px;
  border: none;

  :hover {
    cursor: pointer !important;
    opacity: 0.8;
  }
`

export const CustomFlatButton = styled(FlatButton)`
  width: 45px;
  height: 44px;

  background: ${Colors.secondaryGreen};
  border-radius: 10px;
  margin-right: 5px;
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
`