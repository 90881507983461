import styled, { css } from 'styled-components';
import { Colors } from '../../values/colors';
import { FlatButton } from '../BaseComponents/styles';

export const Container = styled.div`
  overflow-x: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;

  &.no-border {
    
    thead {
      tr th {
        border-bottom-width: 0;
        color: black;
        font-weight: 600;
        font-size: 1rem;
        padding: 5px;
      }
    }
    tbody{
      tr {
        border-top: none;
        td {
          padding: 5px;
        }
      }
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 1.5rem 2rem;
  width: 100%;
  border-top: 1px solid #EFF2F7;
  font-size: 13px;
`;

export const SelectRowsNumber = styled.select`
  border: none; 
  outline: none;
  margin: 0 10px;
  color: ${Colors.blueGray};
`;

export const THead = styled.thead`
  tr th {
    border-top: 0;
    border-bottom-width: 1px;
    font-weight: 600;
    font-size: 14px;
    color: #8593A5;
  }

  tr th {
    padding: 1rem 1.5rem;
    white-space: nowrap;
  }
`;

export const TBody = styled.tbody`
  tr {
    border-top: 1px solid #EFF2F7;

    &.inactive {
      opacity: .4;
    }

  }

  

  tr td {
    &:first-of-type {
      width: ${({ isLeaderBoard }) => isLeaderBoard ? '50px' : '360px'};
    }
    ${({ isLeaderBoard }) => isLeaderBoard && css`
      &:nth-of-type(2){
        width: 310px;
      }
      &:nth-of-type(3){
        width: 200px;
      }
    `}
    
    padding: 1rem 1.5rem;
    font-weight: 400;
    font-size: 13px;
  }
`;

export const CustomFlatButton = styled(FlatButton)`
  width: 45px;
  height: 44px;

  background: ${({ customColor }) => customColor};
  border-radius: 10px;
`
