import { ArrowDown, ArrowUp, Edit, Edit2 } from "react-feather";
import { Box } from "../../components/Box";
import FlexPanel from "../../components/FlexPanel";
import { AddBtn, Divider, GoalContainer, GoalPercent, GoalPin, GraphContainer, IndicatorHead, Input, InputGroup, Label, Option, Percent, SectionTitle, Select, Table, TBody, THead, VariationContainer } from './styles';
import { Chart } from 'react-google-charts';
import NoData from '../../components/NoData';
import { useEffect, useState } from "react";
import { Modal } from "../../components/Modal";
import { Button } from "../../components/Button";
import { Colors } from "../../values/colors";
import { Line } from "../../components/Line/styles";
import { DropdownSelect } from "../../components/DropdownSelect/styles";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ModalAdd = ({ isOpen, onClose }) => {
  const [ monthly, setMonthly ] = useState(true);

  return (
    <Modal title={'Cadastrar Indicador'} open={isOpen} onClose={onClose}>
      <Select>
        <option value="0" disabled>Selecione Indicador</option>
        <option value="1">Taxa de Absenteísmo</option>
        <option value="2">Taxa de Sinistralidade</option>
        <option value="3">Taxa de Rotatividade (Turnover)</option>
        <option value="4">Índice de Retenção de Talentos</option>
      </Select>
        
      <SectionTitle>Selecione o tipo de apuração</SectionTitle>
      <FlexPanel>
        <Option active={monthly} onClick={() => setMonthly(true)}>Mensal</Option>
        <Option active={!monthly} onClick={() => setMonthly(false)}>Anual</Option>
      </FlexPanel>

      <SectionTitle>Selecione o período e a meta desejada</SectionTitle>
      <FlexPanel style={{ alignItems: 'center', marginBottom: '1rem' }}>
        <InputGroup>
          <Input placeholder={monthly ? "06/2022" : "2022"}/>  
        </InputGroup>
        <InputGroup>
          <Label>Taxa Atual</Label>
          <Input type={"number"} placeholder={"50%"}/>
        </InputGroup>
        <InputGroup>
          <Label>Meta</Label>
          <Input type={"number"} placeholder={"100%"}/>
        </InputGroup>
      </FlexPanel>
      

      <AddBtn>
        + Adicionar mais periodos
      </AddBtn>

      <Line style={{ justifyContent: 'center', marginTop: '1rem' }}>
        <Button color={Colors.defaultBlue} style={{ marginTop: '1rem' }}>Confirmar alteração</Button>
      </Line>
    </Modal>
  );
};

export default () => {
  const [ modalAdd, setModalAdd ] = useState(false);

  const [ filters, setFilters ] = useState({
    typeIndicator: '1'
  });

  const { t } = useTranslation('common');

  const [ setOptions ] = useOutletContext();

  const setFilter = (param, value) => {
    setFilters(prev => {
      let _newFilters = {...prev};
      _newFilters[param] = value;
      return _newFilters;
    });
  }

  useEffect(()=>{
    setOptions({
      end: [
        <DropdownSelect onChange={(e)=>setFilter('typeIndicator',e.currentTarget.value)} value={filters.typeIndicator}>
          <option value={"1"}>{t('Mensal')}</option>
          <option value={"0"}>{t('Anual')}</option>
        </DropdownSelect>
      ]
    });
    
    return () => setOptions(null);
  }, [filters]);

  const Variation = ({ value, positive }) => (
    <VariationContainer>
      {value}% {positive ? <ArrowUp size={'1rem'}/> : <ArrowDown/>}
    </VariationContainer>
  );
  
  var configPointColor = `point { stroke-width: 2; fill-color: #5D3DED; }`;
  var configPointColor2 = `point { stroke-width: 1; fill-color: #3ED1D2; }`;

  const graphData = [
    [ 'x', 'Resultado', { 'type': 'string', 'role': 'style' }, 'Meta', { 'type': 'string', 'role': 'style' } ],
    [ 'Jan', 10, configPointColor, 10, configPointColor2 ],
    [ 'Fev', 30, configPointColor, 15, configPointColor2 ],
    [ 'Mar', 20, configPointColor, 20, configPointColor2 ],
    [ 'Abr', 10, configPointColor, 25, configPointColor2 ],
    [ 'Mai', 40, configPointColor, 30, configPointColor2 ],
    [ 'Jun', 20, configPointColor, 35, configPointColor2 ],
    [ 'Jul', 10, configPointColor, 40, configPointColor2 ],
    [ 'Ago', 30, configPointColor, 45, configPointColor2 ]
  ];

  const Graph = ({ data }) => (
    <GraphContainer>
      <Chart
        width={'100%'}
        height={'100px'}
        chartType="LineChart" 
        loader={<div>Carregando dados...</div>}
        data={data}
        options={{
          chartArea: {
            width: '100%',
            height: '60%'
          },
          colors: [ '#5D3DED', '#3ED1D2' ],
          legend: 'none',
          pointsVisible: false, 
          pointSize: 0,
          vAxis: {
            minValue: 0, 
            maxValue: 10,
            baselineColor: 'transparent',
            gridlines: { color: 'transparent', minSpacing: 10 },
            textPosition: 'none'
          },
          hAxis: { 
            textPosition: 'none'
          },
          lineWidth: 2,
          series: {
            0: { curveType: 'function' },
            1: { curveType: 'none' }
          },
          tooltip: { trigger: 'selection' },
        }}
        rootProps={{ 'data-testid': '2' }}
      />
    </GraphContainer>
  );

  return (
    <>
      <Box title={"Indicadores por período"}>
        <NoData message={"Você não tem indicatores cadastrados"} buttonText={"Cadastrar indicador"} onButtonClick={() => setModalAdd(true)}/>
      </Box>
      <FlexPanel>
        {[ 0, 1, 2 ].map(e => 
          <Box
            title="Taxa de Absenteísmo"
            headStyle={{ padding: '.5rem 1.5rem' }}
          >
            <IndicatorHead>
              <Percent>37%</Percent>
              <Variation value={18} positive={true}/>
              <GoalContainer>
                <GoalPin/>
                Meta mensal
                <GoalPercent>30%</GoalPercent>
                <Edit2 size={'1rem'}/>
              </GoalContainer>
            </IndicatorHead>
            <Graph data={graphData}/>
            <Table>
              <THead>
                <tr>
                  <th>Mês</th>
                  <th>Meta</th>
                  <th>Resultado</th>
                </tr>
              </THead>
              <TBody>
                <tr>
                  <td>Janeiro/20</td>
                  <td>20%</td>
                  <td>15%</td>
                </tr>
                <tr>
                  <td>Fevereiro/20</td>
                  <td>10%</td>
                  <td>20%</td>
                </tr>
                <tr>
                  <td>Março/20</td>
                  <td>30%</td>
                  <td>40%</td>
                </tr>
              </TBody>
            </Table>
          </Box>
        )}
      </FlexPanel>

      <ModalAdd isOpen={modalAdd} onClose={() => setModalAdd(false)}/>
    </>
  );
};