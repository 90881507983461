import styled from "styled-components";
import { Colors } from "../../../../values/colors";



export const Container = styled.div`

  background-color: ${Colors.primaryBlue};
  height: 55px;
  width: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  .manu-circle{
    width: 45px;
    height: 45px;
    display: flex;
    border-radius: 100%;
    overflow: hidden;
    justify-content: center;
    background-color: ${Colors.primaryBlue2};
    img {
      align-self: flex-end;
      width: 90%;
    }
  }

`;