/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from 'react';
import { Check, ChevronLeft, ChevronRight, Edit2, Trash, X } from 'react-feather';
import { Colors } from '../../values/colors';
import { FlatButton, Text, View } from '../BaseComponents/styles';
import { Line } from '../Line/styles';
import { Table, THead, TBody, Container, Pagination, SelectRowsNumber, CustomFlatButton } from "./styles";
import { cpfMask } from '../../utils/masks'

export default ({
  data = [],
  totalEntries = 0,
  collumns,
  onUpdate,
  onDelete,
  defaultPage = null,
  onPageChange,
  onPerPageChange,
  defaultActions = true,
  actions,
  defaultActionNewStyle = false,
  isRemoveRegisterPerPage = false,
  withPagination = true,
  isLeaderBoard,
  hideDelete,
  expandedRows
}) => {
  const [page, setPage] = useState(defaultPage ? defaultPage : 1);
  const [pages, setPages] = useState(totalEntries / 10);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    if (defaultPage !== null) {
      setPage(defaultPage);
    }
  }, [defaultPage]);

  const previousPage = () => {
    const nPage = Math.max(page - 1, 1);
    setPage(nPage);
    onPageChange(nPage);
  }

  const nextPage = () => {
    const nPage = Math.min(page + 1, pages);
    setPage(nPage);
    onPageChange(nPage);
  }

  const changePerPage = (value) => {
    setPerPage(value);
    onPerPageChange(value);
    setPage(1);
    onPageChange(1);
    setPages(totalEntries / value);
  }

  useEffect(() => {
    setPages(Math.ceil(totalEntries / perPage));
  }, [totalEntries, perPage]);

  const Head = () => (
    <THead>
      <tr>
        {collumns.map(({ title }) => <th key={title}>{title}</th>)}
      </tr>
    </THead>
  );

  const Field = ({ field, ...props }) => {
    const _field = field;
    return field ? <_field {...props} /> : <input {...props} />;
  };

  const Row = ({ entry, index, key }) => {
    const [editing, setEditing] = useState(false);
    const [values, setValues] = useState(entry);
    const [newValues, setNewValues] = useState({});

    const onValueChange = (key, value) => {
      if (key === 'cpf') {
        setNewValues(prev => {
          let _new = { ...prev };
          _new[key] = cpfMask(value);
          return _new;
        });
        return
      }
      setNewValues(prev => {
        let _new = { ...prev };
        _new[key] = value;
        return _new;
      });
    };

    const deleteEntry = () => {
      onDelete(index, entry);
    };

    const saveChanges = () => {
      const data = {
        ...values,
        ...newValues
      };
      setEditing(false);
      setValues(data);
      if (onUpdate) onUpdate(data, index);
    };

    const isExpanded = expandedRows !== undefined ? expandedRows.has(entry.id) : false;

    return (
      <>
      <tr key={key} className={(!entry.hasOwnProperty('active') || (entry.hasOwnProperty('active') && !!entry.active)) ? '' : 'inactive'}
      style={{ 
        backgroundColor: isExpanded ? '#EDEAFB' : 'transparent',
        borderTop: entry.parent_id ? 'none' : '1px solid #EFF2F7'
       }}>
        {collumns.map(({ render, key, editable, field, fieldProps }, index) =>
          <td key={key} style={{ 
            width: key === 'expand' ? '170px' : '360px',
            borderTopLeftRadius: index === 0 ? '30px' : '0',
            borderBottomLeftRadius: index === 0 ? '30px' : '0'
           }}>{
            editing && editable ?
              key === 'cpf' ? (<Field value={cpfMask(newValues[key] || values[key])} field={field} onValueChange={(value) => onValueChange(key, value)} {...fieldProps} />) : (
                <Field value={newValues[key] || values[key]} field={field} onValueChange={(value) => onValueChange(key, value)} {...fieldProps} />
              ) :
              !render ? key === 'cpf' ? cpfMask(values[key]) : values[key]
                :
                key === 'dependent' && entry?.parent_id && entry?.parent_id !== null ? '' : render(values)
          }</td>
        )}
        {(!entry.hasOwnProperty('active') || (entry.hasOwnProperty('active') && !!entry.active)) && <td style={{
          borderTopRightRadius: isExpanded ? '30px' : '0',
          borderBottomRightRadius: isExpanded ? '30px' : '0'
        }}>
          <Line align={'end'}>
            {actions && actions(entry) && actions(entry).map((action, index) => (
              <View style={{ marginRight: actions.length - 1 === index && !defaultActions ? 0 : 10 }}>
                {action}
              </View>
            ))}
            {defaultActions && (
              defaultActionNewStyle ? (
                !editing ?
                  <>
                  {!entry?.hideEdit && (
                    <CustomFlatButton customColor={Colors.secondaryPurple} style={{ marginRight: 10 }} onClick={() => setEditing(true)}>
                    <Edit2 size={18} color="#fff" />
                  </CustomFlatButton>
                  )}
                    
                    {!hideDelete && (
                      <CustomFlatButton customColor={Colors.redSecondary} style={{ marginLeft: 5 }} onClick={deleteEntry}>
                      <Trash size={18} color="#fff" />
                    </CustomFlatButton>
                    )}
                    
                  </>
                  :
                  <>
                    <CustomFlatButton customColor={Colors.secondaryPurple} style={{ marginRight: 10 }} onClick={saveChanges}>
                      <Check size={18} color="#fff" />
                    </CustomFlatButton>
                    <CustomFlatButton customColor={Colors.redSecondary} onClick={() => setEditing(false)}>
                      <X size={18} color="#fff" />
                    </CustomFlatButton>
                  </>
              ) : (
                !editing ?
                  <>
                    {!entry?.hideEdit && (
                      <FlatButton style={{ marginRight: 10 }} onClick={() => setEditing(true)}>
                      <Edit2 size={18} color={Colors.mediumGray} />
                    </FlatButton>
                    )}
                    
                    {!hideDelete && (
                      <FlatButton style={{ marginLeft: 5 }} onClick={deleteEntry}>
                      <Trash size={18} color={Colors.red} />
                    </FlatButton>
                    )}
                    
                  </>
                  :
                  <>
                    <FlatButton style={{ marginRight: 10 }} onClick={saveChanges}>
                      <Check size={18} color={Colors.successGreen} />
                    </FlatButton>
                    <FlatButton onClick={() => setEditing(false)}>
                      <X size={18} color={Colors.red} />
                    </FlatButton>
                  </>
              )

            )}
          </Line>
        </td>}
      </tr>
      {isExpanded && entry.dependents && entry.dependents.length > 0 &&
          entry.dependents.map((dependent, depIndex) => (
            <Row key={`dependent-${entry.id}-${depIndex}`} entry={dependent} index={depIndex} />
          ))}
    </>
    );
  };

  return (
    <Container>
      <Table>
        <Head />
        <TBody isLeaderBoard={isLeaderBoard}>
          {data.map((entry, index) => <Row entry={entry} index={index} key={index} />)}
        </TBody>
      </Table>
      {
        withPagination && (
          <Pagination>
            {
              isRemoveRegisterPerPage ? (
                <>
                  <Text>Registros por página</Text>
                  <SelectRowsNumber value={perPage} onChange={(e) => changePerPage(e.target.value)}>
                    <option value="6">6</option>
                  </SelectRowsNumber>
                </>
              ) : (
                <>
                  <Text>Registros por página</Text>
                  <SelectRowsNumber value={perPage} onChange={(e) => changePerPage(e.target.value)}>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                    <option value="30">30</option>
                  </SelectRowsNumber>
                </>
              )
            }

            <Text style={{ marginRight: 10 }}>Página {page} de {pages}</Text>
            <FlatButton disabled={page == 1} opacity onClick={() => {
              previousPage();
            }}>
              <ChevronLeft color={Colors.blueGray} />
            </FlatButton>
            <FlatButton disabled={page == pages} opacity onClick={() => {
              nextPage();
            }}>
              <ChevronRight color={Colors.blueGray} />
            </FlatButton>
          </Pagination>
        )
      }
    </Container>
  );
};