import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import General from "../../../services/General";
import Managers from "../../../services/Managers";
import { Colors } from "../../../values/colors";
import { ActionButton } from "../../ActionButton";
import { Badge, Title, View, FlatButton } from "../../BaseComponents/styles";
import { FormField } from "../../FormField";
import { Line } from "../../Line/styles";

export const AddAdminUser = ({ visible, onConfirm, roles, onClose }) => {
  const [ t, i18n ] = useTranslation('common');

  const [ loading, setLoading ] = useState(false);
  const [ role, setRole ] = useState(1);
  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ sector, setSector ] = useState('');
  const [ subsector, setSubsector ] = useState('');
  const [ business_unit, setBusiness_unit ] = useState('');

  const addUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      await Managers.create({
        name,
        email,
        sector,
        role,
        subsector,
        business_unit
      });
      toast.success('Administrador adicionado!');
      onConfirm();
      setRole(1);
      setName('');
      setEmail('');
      setSector('');
      setSubsector('');
      setBusiness_unit('');
      onClose();
    } catch (e) {
      toast.error(e?.response?.data?.error ? e?.response?.data?.error : 'Falha ao adicionar, tente novamente!');
    }

    setLoading(false);
  }

  return (
    <View 
      className={'fade ' + (visible ? 'show' : 'hide')}
      style={{
        backgroundColor: 'white',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0
      }}
      >
      <form onSubmit={addUser}>

        {roles.length && 
          <>
            <Title style={{fontSize: '1rem'}}>{t('roles')}</Title>
            <Line gap={10} style={{marginTop: 10}}>
              {roles.map(r => <FlatButton type="button" key={r.id} onClick={()=>setRole(r.id)}>
                <Badge color={role === r.id ? 'primaryPurple' : 'lightGray'}>{r.name}</Badge>
              </FlatButton>)}
            </Line>
          </>
        }

        <FormField
          label={t('name')}
          name="name"
          placeholder={t('name')}
          value={name}
          onChangeValue={setName}
          required
        />
        <FormField
          label={t('email')}
          name="email"
          type={'email'}
          placeholder={t('email')}
          value={email}
          onChangeValue={setEmail}
          required
        />
        <FormField
          label={t('sector')}
          name="sector"
          placeholder={t('sector')}
          value={sector}
          onChangeValue={setSector}
        />
        <FormField
          label={t('subsector')}
          name="subsector"
          placeholder={t('subsector')}
          value={subsector}
          onChangeValue={setSubsector}
        />
        <FormField
          label={t('business_unit')}
          name="business_unit"
          placeholder={t('business_unit')}
          value={business_unit}
          onChangeValue={setBusiness_unit}
        />


        <Line align={'center'} style={{marginTop: '2rem'}}>
        <ActionButton isLoading={loading} type="submit" color={Colors.primaryPurple}>{t('add')}</ActionButton>
        </Line>
      </form>

    </View>
  );
}