import styled from 'styled-components';

export const Container = styled.div`
  background: #FFFFFF;
  border: 1px solid #EFF2F7;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.02);
  border-radius: 7px;
  margin-bottom: 1rem;
`;

export const Head = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid #EFF2F7;
`;

export const Body = styled.div`
  padding: ${props => props?.noPadding ? '0' : '1.5rem'};
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
`;