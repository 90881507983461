import styled from "styled-components";
import { Colors } from "../../values/colors";

export const Container = styled.div`
  padding: 1rem;
`;


export const Tab = styled.div`
  cursor: pointer !important;
  padding: 10px 20px;
  border: 1px solid ${Colors.primaryPurple};
  font-weight: bold;
  border-radius: 5px;
  color: ${Colors.primaryPurple};
  margin-right: 10px;

  &.active{
    background: ${Colors.primaryPurple};
    color: white;
  }
`;