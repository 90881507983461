import React from "react";
import { SwitchInput, SwitchLabel, SwitchButton, ToggleSwitch, Text, Label, TextActive, TextInactive } from "./styles";

const Switch = ({ id, toggled, onChange, name, disabled }) => {
  return (
    <>
    <ToggleSwitch>
      <SwitchInput
        className="switch-checkbox"
        id={id}
        name={name}
        type="checkbox"
        checked={toggled}
        onChange={onChange}
        disabled={disabled}
      />
      <SwitchLabel className="switch-label" htmlFor={id}>
        <SwitchButton className="switch-button" />
      </SwitchLabel>
      <Label>
      {toggled ? <TextActive>Ativa</TextActive> : <TextInactive>Inativa</TextInactive>}
      </Label>
      </ToggleSwitch>
    </>
  );
};

export default Switch;
