import styled from "styled-components";

export const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

export const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer !important;
  width: 56px;
  height: 28px;
  background: #e7ebef;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
`;

export const SwitchButton = styled.span`
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 28px;
  height: 28px;
  border-radius: 45px;
  transition: 0.2s;
  background: #ACB1B7;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  ${SwitchInput}:checked + ${SwitchLabel} & {
    left: calc(100% - 0px);
    transform: translateX(-100%);
    background: #4FD5AE;
  }

  ${SwitchLabel}:active & {
    width: 45px;
    background: #4FD5AE;
  }
`;

export const ToggleSwitch = styled.div`
    display: flex;
    align-items: center;
`;

export const Label = styled.div`
    margin-left: 6px;
`;

export const TextActive = styled.h5`
    color: #8492A6;
`;

export const TextInactive = styled.h5`
    color: #ACB1B7;
`;