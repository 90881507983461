import styled from "styled-components";
import { Colors } from "../../../../values/colors";



export const Container = styled.form`
  z-index: 10;
  height: 65px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; 

  .message-container {
    flex: 1;
    margin-right: 10px;
    /* margin-left: 10px; */
    outline: none;
    border: none;
    overflow: none;
    resize: none;
    font-size: 16px;
    font-weight: 700;
    box-shadow: 0px 0px 10px #00000011;
    background-color: white;
    border-radius: 50px;
    padding: 14px 16px;
  }

  .message {
    flex: 1;
    margin-right: 10px;
    /* margin-left: 10px; */
    outline: none;
    border: none;
    overflow: none;
    resize: none;
    font-size: 16px;
    font-weight: 700;
  }

  .circle-button {
    background-color: ${Colors.defaultBlue};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 50px;
    aspect-ratio: 1;
  }

`;