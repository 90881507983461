import { Line } from "../Line/styles";
import { Container } from "./styles";



export const Button = ({ icon, color, rounded, onClick, textColor, style, children, ...rest }) => {

  return (
    <Container rounded={rounded} onClick={onClick} style={{ backgroundColor: color, color: textColor, ...style }} {...rest}>
      <Line className="content" align={'space-around'}>
        {!!icon && <Line style={{ marginRight: 10 }}>{icon}</Line>}
        {children}
      </Line>
    </Container>

  );


}