import { useState } from "react";
import { ChevronLeft } from "react-feather";
import { useTranslation } from "react-i18next";
import General from "../../../services/General";
import { ErrorMessage, SuccessMessage } from "../../../utils/alerts";
import { Colors } from "../../../values/colors";
import { TextButton, Title, View } from "../../BaseComponents/styles";
import { Button } from "../../Button";
import { FormField } from "../../FormField";
import { Line } from "../../Line/styles";

export const ChangePass = ({ onClose }) => {
  const [ t, i18n ] = useTranslation('common');
  const [ currentPassword, setCurrentPassword ] = useState('');
  const [ newPassword, setNewPassword ] = useState('');
  const [ repeatPassword, setRepeatPassword ] = useState('');

  const saveData = async (e) => {
    e.preventDefault();
    const data = await General.changePassword({
      currentPassword,
      newPassword,
      repeatPassword
    });

    if (data.success) {
      SuccessMessage({ message: 'Senha alterada!' });
    } else {
      ErrorMessage({ message: 'Verifique os dados inseridos.' });
    }
  };

  return (
    <View>
      <Line>
        <ChevronLeft color={Colors.lightGray} onClick={onClose}/>
        <Title style={{marginLeft: '1rem'}}>{t('change_pass')}</Title>
      </Line>

      <View style={{marginTop: '2rem'}}>
        <form onSubmit={saveData}>
          <FormField
            type={'password'}
            label={t('insert_current_pass')}
            placeholder={t('•••••••')}
            value={currentPassword}
            onChangeValue={setCurrentPassword}
          />
          <FormField
            type={'password'}
            label={t('insert_new_pass')}
            placeholder={t('•••••••')}
            value={newPassword}
            onChangeValue={setNewPassword}
          />
          <FormField
            type={'password'}
            label={t('insert_new_pass_again')}
            placeholder={t('•••••••')}
            value={repeatPassword}
            onChangeValue={setRepeatPassword}
          />
          
          <Line align={'center'} style={{marginTop: '2rem'}}>
          <Button color={Colors.primaryPurple}>{t('change_pass')}</Button>
          </Line>
        </form>
      </View>
    </View>
  );
}