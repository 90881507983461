import jwtDecode from "jwt-decode";
import { useState } from "react";
import API from '../api';

export default class {
  /**
   * @returns {boolean}
   */

  static get signedIn () {
    return !!localStorage.getItem('access_token');
  }

  static get permissions () {
    const token = localStorage.getItem('access_token');
    const decoded = jwtDecode(token);
    return decoded.permissions || [];
  }

  /**
   * @param {string} email
   * @param {string} password
   * @returns {{ success: boolean }}
  */

  static async signIn (email, password, remember) {
    const res = await API.post('signin', {
      email,
      password,
      remember
    });

    const { data } = res;

    if (data.success) {
      localStorage.setItem('access_token', data.token)
    }

    return data;
  }

  static signOut () {
    localStorage.removeItem('access_token');
    window.location = "/login";
  }

  static async signUpInvite (data) {
    const res = await API.post('/signup/invite', data);
    return res.data;
  } 

  static async resetPassword (data) {
    const res = await API.post('/password/reset', data);
    return res.data;
  }

  static async sendResetMail (data) {
    const res = await API.post('/password/mail', data);
    return res.data;
  }

  static async getBusinesses () {
    const res = await API.get('/businesses');
    return res.data;
  }

  static async switchBusiness (id) {
    const { data } = await API.post(`/switch/${id}`);
    if (data.success) {
      localStorage.setItem('access_token', data.token)
    }
    return;
  }
}