import styled from 'styled-components'
// import BackgroundImg from '../../../assets/images/background.svg'
import BackgroundImg from '../../../assets/images/backgroundb2c.svg'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.625rem;
  overflow: hidden;
  height: 100vh;
  overflow-y: auto;
  background-color: #EBEBEB;
  background-image: url(${BackgroundImg});
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 100vh;
  position: relative;
`

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;

  margin-top: 1.625rem;
  width: 100%;
  height: 5.8125rem;
`

export const MenuBusinessImageContainer = styled.div`
  width: 152px;
  height: 41px;

  @media (max-width: 800px)
  {
    width: 122px;
  }
`

export const MenuBusinessImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const MenuImageElipseContainer = styled.div`
  width: 92.7px;
  height: 92.7px;
`

export const MenuNamuBusinessLogo = styled.div`
  width: 119.81px;
  height: 38.32px;
  margin-left: 1rem;

  @media (max-width: 800px)
  {
    margin-left: .5rem;
  }
`

export const MenuImage = styled.img`
  object-fit: contain;
`

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0px 32px;
  margin-bottom: 3rem
`

export const MainTitle = styled.h1`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  color: #29C9CA;
  font-size: 1.2rem;
`

export const MainSubTitle = styled.strong`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.03rem;
  line-height: 29px;
  color: #485465;
`

export const SuccessMain = styled.main`
  padding: 1rem;

  background: #FFFFFF;
  border: 1px solid #EFF2F7;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.02);
  border-radius: 7px;
`

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  padding: 0px 4.875rem;
  padding-top: 1.25rem;
  gap: 1.3125rem;

  @media (max-width: 695px) {
    padding: 0 1rem;
  }
`

export const DownloadLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  :hover {
    cursor: pointer !important;
  }
`

export const DownloadImage = styled.img`
  width: 9.375rem;
  height: 3.75rem;
  object-fit: contain;
`
export const CheckImg = styled.img`
  width: 90px;
  height: 90px;
  object-fit: contain;
`

export const SuccessTitle = styled(MainTitle)`
  text-align: center;
  font-size: 1.6875;
`

export const SucessSubTitle = styled(MainSubTitle)`
  text-align: center;

  width: 100%;
`