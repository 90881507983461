import styled from "styled-components";
import { Colors } from "../../values/colors";

export const Backdrop = styled.div`
	position: fixed;
	z-index: 10;
	background: ${Colors.black40};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
	width: 100%;
	height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  overflow: auto;
  transform: translate(0,0);
  
  @media (max-width: 650px){
    padding: 1rem;
  }
  &.show{
    opacity: 1;
    visibility: visible;


    .modal-container{
      transform: translateY(0px);
      opacity: 1;
      transition: all .2s ease-in-out;
    }
  }


  .modal-container{
    transform: translateY(50px);
    opacity: 0;
    transition: all .2s ease-in-out;

  }
`;

export const Container = styled.div`
  border-radius: 7px;
  box-shadow: 0px 6px 20px #8492A6;
  background: white;
  width: 600px;
  position: relative;
  @media (max-width: 650px){
    width: 100%;
  }
`;

export const ModalHeader = styled.div`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${Colors.ultraLightGray2};

  &.no-title{
    border-bottom: none;
  }
`;
export const ModalBody = styled.div`
  padding: 1rem 2rem;
`;

export const ModalTitle = styled.h3`
  font-weight: 700;
  font-size: 1rem;
  color: ${Colors.blueGray2};
`;

export const ModalSubtitle = styled.h4`
  font-weight: 700;
  color: ${Colors.mutedGray};
`;