import { useState } from "react";
import { useEffect } from "react";
import { Circle } from "react-feather";
import { Colors } from "../../../values/colors";
import { CircleMask, CircleSvg, CircleWrap } from "./styles";


export const TargetCircle = ({current = 0, target = 0, size = 100, color}) => {

  const [ currentPercentage, setCurrentPercentage ] = useState(current/target * 100);

  useEffect(() => {
    setCurrentPercentage(current/target * 100);
  }, [ current, target ]);

  return (
    <CircleWrap className="single-chart" style={{width: size, height: size}} color={color}>
    <svg viewBox={"0 0 36 36"} className="circular-chart">
      <path className="circle-bg"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path className="circle"
        strokeDasharray={currentPercentage+", 100"}
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <text x="20" y="20.35" fill={Colors.primaryBlue} className="current">{current}</text>
      <text x="20" y="20.35" style={{fill: '#000'}} className="target">/{target}</text>
    </svg>
  </CircleWrap>
  );
}