import { Box } from "../../components/Box";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import Stats from "../../services/Stats";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import { Tabs } from "../../components/Tabs";
import { useState } from "react";
import { LoadingOver } from "../../components/LoadingOver";
import { Content } from "./styles";
import List from "../../services/List";
import { useOutletContext } from "react-router-dom";
import { Text, TransparencyOver } from "../../components/BaseComponents/styles";
import { DropdownSelect } from "../../components/DropdownSelect/styles";
import { Button } from "../../components/Button";
import { Colors } from "../../values/colors";
import { Filter, Plus } from "react-feather";
import { BoxFilter } from "../../components/BoxFilter";

export default ({ multi }) => {
  const [t, i18n] = useTranslation('common');
  const embedContainer = useRef();
  const [activeTab, setActiveTab] = useState('main');
  const [tabs, setTabs] = useState([
    { key: 'main', title: 'Análise Geral' }
  ]);

  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({});
  const [boxFilterOpened, setBoxFilterOpened] = useState(false);
  const [setOptions] = useOutletContext();

  const dashboard = useRef();

  const getData = async () => {
    setLoading(true);
    try {
      const tabsData = await Stats.getQSTabs(multi ? 'multi' : 'single');
      const data = await Stats.getQSEmbed(activeTab, multi ? 'multi' : 'single');
      const _tabs = [
        { key: 'main', title: 'Análise Geral' }
      ];

      if (tabsData?.tele_enabled) _tabs.push({
        key: 'tele', title: 'Teleorientação'
      });

      if (tabsData?.sipat_enabled) _tabs.push({
        key: 'sipat', title: 'SIPAT'
      });
      if (tabsData?.healthscan_enabled) _tabs.push({
        key: 'healthscan', title: 'Namu VitalScan'
      });
      if (tabsData?.gym_enabled) _tabs.push({
        key: 'pass', title: 'Namu PASS'
      });

      setTabs(_tabs);

      const _filters = filters;

      embedContainer.current.innerHTML = '';
      dashboard.current = embedDashboard({
        url: data.embed_url,
        container: embedContainer.current,
        parameters: multi ? { ...filters } : {
          BusinessId: data.business_id,
          ...filters
        },
        scrolling: "no",
        errorCallback: () => setLoading(false),
        loadCallback: () => setLoading(false),
        height: "AutoFit",
        loadingHeight: "750px",
        width: "100%",
        locale: "pt-BR",
        footerPaddingEnabled: true,
        undoRedoDisabled: true,
        resetDisabled: true
      });
    } catch (e) {
      setLoading(false);
    }

  }

  useEffect(() => {
    getData();
  }, [activeTab]);


  useEffect(() => {
    if (!dashboard.current) return;
    dashboard.current.setParameters(filters);
  }, [filters]);

  const filter = (values) => {
    setFilters(values);
  }

  return (
    <>
      <Box noPadding noHeader style={{ overflow: 'hidden', position: 'relative', overflow: 'none' }}>
        {<BoxFilter onFilter={filter} isOpen={(isOpen) => setBoxFilterOpened(isOpen)} />}

        <Tabs
          activeTab={activeTab}
          onChange={setActiveTab}
          tabs={tabs}
        />

        <Content>
          <TransparencyOver className={!boxFilterOpened ? 'hide' : ''} />
          {loading && <LoadingOver loading={loading} />}
          <div ref={embedContainer}></div>
        </Content>

      </Box>
    </>
  );
};