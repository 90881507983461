import { Flip, toast } from "react-toastify";

const toastOptions = ({position, containerId}) => ({
  position: position,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  transition: Flip,
  containerId: containerId
});

export const SuccessMessage = ({ message, headerMessage = false, position = 'top-right', containerId = 'normal' }) => {
  let fullMessage = headerMessage ? <div>{headerMessage}<br /> {message}</div> : <div>{message}</div>;
  toast.success(fullMessage, toastOptions({position, containerId}));
}
export const ErrorMessage = ({ message, headerMessage = false, position = 'top-right', containerId = 'normal' }) => {
  let fullMessage = headerMessage ? <div>{headerMessage}<br /> {message}</div> : <div>{message}</div>;
  toast.error(fullMessage, toastOptions({position, containerId}));
}
export const AlertMessage = ({ message, headerMessage = false, position = 'top-right', containerId = 'normal' }) => {
  let fullMessage = headerMessage ? <div>{headerMessage}<br /> {message}</div> : <div>{message}</div>;
  toast.warn(fullMessage, toastOptions({position, containerId}));
}