import API from '../api'

export const createNewUserOn2b2PreRegister = async (data, business_name) => {
  try {
    return await API.post(`/preregister/${business_name}`, data)
  } catch (error) {
    throw new Error(error);
  }
}

export const getUsers = async (page) => {
  try {
    const { data } = await API.get(`/preregister/all?page=${page}&limit=10&situation=0`)

    return data
  } catch (error) {
    return error
  }
}

export const putPreRegisterUser = async (user) => {
  try {
    const { data } = await API.put(`/preregister/${user.id}`, user)

    return data.data
  } catch (error) {
    return error
  }
}

export const getRejectPreRegisterUser = async (id) => {
  try {
    await API.get(`preregister/reject/${id}`)
  } catch (error) {
    return error
  }
}

export const getAcceptPreRegisterUser = async (id) => {
  try {
    await API.get(`/preregister/migrate/${id}`)
  } catch (error) {
    return error
  }
}

export const getBusinessInfo = async (businessName) => {
  try {
    const { data } = await API.get(`/preregister/business/${businessName}`)

    return data
  } catch (error) {
    return error
  }
}

export const getBusiness = async () => {
  try {
    const res = await API.get('companys-management')

    return res.data
  } catch (error) {
    return error
  }
}