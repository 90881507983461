import styled from "styled-components";
import { Colors } from "../../values/colors";

export const Container = styled.div`
  margin-top: 1rem;
  label {
    color: ${Colors.blueGray2};
    display: block;
    font-weight: 700;
    font-size: 1rem;
  }
  
  input {
    border: 0;
    width: 100%;
    border-bottom: 1px solid ${Colors.lightGray};
    padding: .75rem .5rem;
    display: block;
    outline: none;
    font-size: 1rem;
    font-weight: 700;
    margin-top: .5rem;
  }
`;