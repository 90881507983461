function TranslateError(error) {
  let errorMessage = '';
  switch (error) {
      case 100: errorMessage = 'Ocorreu um erro na requisição'; break;
      case 101: errorMessage = 'Email já cadastrado'; break;
      case 102: errorMessage = 'Este email não está cadastrado'; break;
      case 103: errorMessage = 'A senha não está correta'; break;
      case 104: errorMessage = 'Usuário não autenticado'; break;
      case 105: errorMessage = 'Um Campo obrigatório não foi enviado'; break;
      case 106: errorMessage = 'Arquivo inválido'; break;
      case 107: errorMessage = 'Email invalido'; break;
      case 108: errorMessage = 'Token inválido'; break;
      case 109: errorMessage = 'Você realizou uma solicitação de recuperação de senha recentemente'; break;
      case 110: errorMessage = 'Formato de arquivo invalido'; break;
      case 111: errorMessage = 'Arquivo muito grande'; break;
      case 112: errorMessage = 'Campo com valor invalido'; break;
      case 113: errorMessage = 'Você já registrou uma empresa'; break;
      case 114: errorMessage = 'Key de imagem invalida'; break;
      case 115: errorMessage = 'Usuario nao autorizado'; break;
      case 116: errorMessage = 'Objeto inacessivel (inexistente/nao autorizado)'; break;
      case 117: errorMessage = 'Confirmacao de senha invalida'; break;
      case 118: errorMessage = 'Usuario nao terminou o cadastro da empresa'; break;
      case 119: errorMessage = 'Cartao invalido'; break;
      case 120: errorMessage = 'CPF/CNPJ invalido'; break;
      case 121: errorMessage = 'Metodo de pagamento nao definido'; break;
      case 122: errorMessage = 'Sem assinatura ativa'; break;
      case 123: errorMessage = 'O periodo nao terminou, portanto nao pode ser pago'; break;
      default: errorMessage = 'Ocorreu um erro na requisição'; break;
  }
  return errorMessage;
}

export default TranslateError;