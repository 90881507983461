import styled from 'styled-components';
import { Colors } from '../../values/colors';
import { FlatButton } from "../../components/BaseComponents/styles";

export const RejectModalHeader = styled.header`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

export const UserAvatar = styled.img`
  width: ${props => props.size || 53}px;
  height: ${props => props.size || 53}px;

  border-radius: 50%;
  object-fit: cover;
`

export const TitleHeader = styled.h1`
  color: ${({ isReject }) => isReject ? Colors.redSecondary : Colors.secondaryPurple};
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
`;

export const PointsContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: end;
`;

export const RankText = styled.strong`
  display: flex;
  justify-content: center;
  width: 40px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16.8031px;
  line-height: 20px;

  color: #5B607B;
`

export const PointsText = styled.strong`
  color: ${Colors.blueGray2};
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`

export const PointsSecondText = styled.strong`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19 px;

  color: #646673;
`

export const BadgeImg = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`

export const CustomFlatButton = styled(FlatButton)`
  width: 45px;
  height: 44px;

  background: ${Colors.secondaryGreen};
  border-radius: 10px;
  margin-right: 5px;
`
