import styled from "styled-components";


export const Container = styled.button`
	background: none;
	color: white;
	border: none;
	padding: .75rem 1.5rem;
	border-radius: ${(props) => props.rounded ? '10' : '100'}px;
	outline: inherit;
	font-weight: 700;
	font-size: 14px;
	cursor: pointer !important;
`;