import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer !important;
  background: ${((props)=>props.gradient ? `linear-gradient(90deg,${props.backgroundColor.join(',')})` : props.backgroundColor)};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  margin-bottom: 1rem;
  overflow: hidden;
  flex: 1;
  height: 150px;
  position: relative;
  display: flex;
  padding-left: 2rem;
  padding-right: 2rem;
  align-items: center;
  transition: transform .2s ease-in-out;

  &:hover{ 
    transform: scale(1.03);
  }

`;

export const Bg = styled.img`
  position: absolute;
  left: -7px;
  bottom: -7px;
  object-fit: contain;
`;

export const Body = styled.div`
  padding: ${props => props?.noPadding ? '0' : '1.5rem'};
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: white;
`;

export const SubTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: white;
`;