import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Auth from "../services/Auth";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const usePermissions = () => {
  const [ permissions, setPermissions ] = useState([]);

  const { permissions: userPermissions } = Auth;

  useEffect(() => {
    setPermissions(userPermissions);
  }, []);

  const checkPermissions = (permission, partial = false) => {
    if(partial)
      return permissions.some(p => p.includes(permission))
    return permissions.includes(permission)
  }

  return {
    checkPermissions
  }

}

export { useQuery, usePermissions };