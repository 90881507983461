import { useEffect, useState } from "react"
import { Search, X } from "react-feather"
import { Colors } from "../../values/colors"
import { FlatButton } from "../BaseComponents/styles"
import { Line } from "../Line/styles"
import { Container, SearchInput } from "./styles"


export const SearchBarCollapsible = ({placeholder, onSearch}) => {

  const [ active, setActive ] = useState(false);
  const [ value, setValue ] = useState(null);

  useEffect(() => {
    onChange();
  }, [value]);

  const onChange = () => {
    if(value !== null && value.length >= 3 || value === ''){
      onSearch(value);
    }
  }

  return (
    <Container className={active ? 'show' : ''}>
      <Line className="search-field">
        <FlatButton onClick={()=>(setActive(false), setValue(''))}>
          <X color={Colors.red} size={18}/>
        </FlatButton>
        <SearchInput placeholder={placeholder || 'Pesquisar'} value={value} onChange={(e)=>setValue(e.currentTarget.value)}/>
      </Line>
      <FlatButton onClick={()=>!active ? setActive(true) : onChange()}>
        <Search className={'search-button'} size={20}/>
      </FlatButton>
    </Container>
  )

}