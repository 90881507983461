export const array2Obj = (array, key) => {
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item
    }
  }, {})
}

export const percentOfTotal = (current, total) => {
  return parseFloat((parseFloat(total) == 0 ? 0 : (parseFloat(current) * 100) / parseFloat(total)).toFixed(0));
}

export const toCurrency = (value) => {
  return value.toLocaleString('pt-br', { 
    style: 'currency', 
    currency: 'BRL'
  })
}