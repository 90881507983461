import styled from "styled-components";
import { Colors } from "../../values/colors";


export const Container = styled.div`
  background-color: ${Colors.lightBg};
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  z-index: -1;
  overflow: hidden;

  .circle-bg{
    width: 90%;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
`;