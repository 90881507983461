import API from '../api'

export const getLeaderboard = async (period) => {
  try {
    const { data } = await API.get(`/game-users/leaderboard`, {
      params: {
        period: period,
      }
    })

    return data
  } catch (error) {
    return error
  }
}