import { useEffect } from "react";
import { X } from "react-feather";
import { Colors } from "../../values/colors";
import { FlatButton, TextButton, View } from "../BaseComponents/styles";
import { Line } from "../Line/styles";
import { Backdrop, Container, ModalBody, ModalHeader, ModalTitle } from "./styles";


export const Modal = ({ 
  open, title, containerStyle, 
  bodyStyle, onConfirm, onClose, 
  children, disableOnClose = false,
  disableHeader = false }) => {

  useEffect(() => {
    if (!open) {
      const modals = document.getElementsByClassName('modal');
      let closed = true;
      for (const modal of modals) {
        if (modal.classList.contains('show')) {
          closed = false;
          break;
        }
      }

      if (closed) {
        document.querySelector('body').style.overflow = 'auto';
      }
    } else {
      document.querySelector('body').style.overflow = 'hidden';
    }

  }, [open]);

  return (
    <Backdrop onMouseDown={onClose} className={'modal ' + (open ? 'show' : '')}>
      <Container style={containerStyle} className="modal-container" onMouseDown={(e) => e.stopPropagation()}>
        {!disableHeader && 
        <ModalHeader className={title ? '' : 'no-title'}>
          <Line align={'space-between'}>
            <ModalTitle>
              {title}
            </ModalTitle>
            {
              !disableOnClose && (
                <FlatButton onClick={onClose}>
                  <X color={Colors.mediumGray} />
                </FlatButton>
              )
            }
          </Line>
        </ModalHeader>}
        <ModalBody style={bodyStyle}>
          {children}
        </ModalBody>
      </Container>
    </Backdrop>
  );

}