import { useEffect, useState } from "react";
import FlexPanel from "../../components/FlexPanel";
import NoData from "../../components/NoData";
import { Box } from "../../components/Box";
import { Chart } from 'react-google-charts';
import { TrendingDown, TrendingUp } from 'react-feather';
import { toCurrency } from "../../utils";
import { Bar, BarProgress, BarContainer, BarFlexContainer, BarLabel, BarInfo, BarPrice } from "./styles";
import Stats from '../../services/Stats';

export default () => {
  const [ data, setData ] = useState([{
    category: 'Psicologo',
    categoryId: 1,
    sessions: 10,
    max: 10000,
    min: 8000,
    avg: 9000
  },{
    category: 'Nutricionista',
    categoryId: 2,
    sessions: 20,
    max: 11000,
    min: 9000,
    avg: 10000
  },{
    category: 'Personal Trainer',
    categoryId: 3,
    sessions: 30,
    max: 12000,
    min: 10000,
    avg: 11000
  },{
    category: 'Educador Financeiro',
    categoryId: 4,
    sessions: 35,
    max: 13000,
    min: 11000,
    avg: 12000
  }]);
  
  const colors = [ 
    'FF5050', 'FF8850', '3ED1D2', 'FFDC27', '4969D8',
    '2D9CEE', 'FF4B8B', '6240FF', 'FF8CC7', '485465',
    '36B37E'
  ];

  const getData = async () => {
    const data = await Stats.sessions();
    setData(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const PercentBar = ({ category, categoryId, min, max, avg }) => {
    const percent = parseInt(min * 100 / max) || 0;
    const color = '#' + colors[categoryId - 1] || 'blue';
  
    return (
      <BarContainer>
        <BarFlexContainer>
          <BarLabel>{category}</BarLabel>
        </BarFlexContainer>
        <BarFlexContainer>
          <BarInfo>Preço médio: <BarPrice>{toCurrency(avg/100)}</BarPrice></BarInfo>
          <Bar color={color}>
            <BarProgress percent={percent} color={color}></BarProgress>
          </Bar>
          <BarInfo>
            Preço mínimo e máximo:
            <BarPrice>
              <TrendingDown color={'#FF5050'} size={'1rem'} style={{ margin: '0 .5rem' }}/>
              {toCurrency(min/100)}
            </BarPrice>
            <BarPrice>
              <TrendingUp color={'#36B37E'} size={'1rem'} style={{ margin: '0 .5rem' }}/>
              {toCurrency(max/100)}
            </BarPrice>
          </BarInfo>
        </BarFlexContainer>
      </BarContainer>
    );
  };

  return (
    <>
      <FlexPanel>
        <Box 
          title={'Ranking de teleatendimentos realizados por área de atuação'} 
          headStyle={{ border: 0, padding: '1rem 1.5rem' }}
          bodyStyle={{ overflow: 'auto', height: '470px', paddingTop: 0 }}
        >
          {!data?.length ?
            <NoData loading={data === false}/>
          :
            <Chart
              chartType="PieChart"
              loader={<div>Carregando dados...</div>}
              width={'95%'}
              height={'95%'}
              data={[
                [ 'Area de Atuação', 'Atendimentos' ],
                ...data.map(({ category, sessions }) => [ category, sessions ])
              ]}
              options={{
                  legend: {
                    position: 'bottom',
                    labeledValueText: 'both',
                    textStyle: {
                      fontSize: 20,
                      color: '#485465'
                    }
                  },
                  pieHole: 0.5,
                  pieSliceText: 'value-and-percentage',
                  slices: [
                    { color: '#FF5050' }, { color: '#3ED1D2' },
                    { color: '#FF8850' }, { color: '#FFDC27' },
                    { color: '#4969D8' }, { color: '#2D9CEE' },
                    { color: '#FF4B8B' }, { color: '#6240FF' },
                    { color: '#FF8CC7' }, { color: '#485465' },
                    { color: '#36B37E' }
                  ],
                  chartArea: {
                      height: '80%',
                      width: '80%',
                  },
                  enableInteractivity: false
              }}
            />
          }
        </Box>
        <Box 
          title={'Preço médio de teleatendimento por área de atuação'} 
          headStyle={{ border: 0, padding: '1rem 1.5rem' }}
          bodyStyle={{ overflow: 'auto', height: '470px', paddingTop: 0 }}
        >
          {!data?.length ?
            <NoData loading={data === false}/>
          :
            data.map(category => {
              return PercentBar(category)
            })
          }
        </Box>
      </FlexPanel>
    </>
  );
};