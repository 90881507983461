import { Container } from "./styles";

export const FormField = ({value, onChangeValue, type = 'text', placeholder, label, ...props}) => {

  return (
    <Container>
      <label>{label}</label>
      <input type={type} {...props} placeholder={placeholder} value={value} onChange={(e) => onChangeValue(e.target.value)}/>
    </Container>
  );


}