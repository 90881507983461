import React from 'react';

import { BottomCircle, Circle, TopRightCircle, BottomLeftCircle } from './styles';

/**
 * COMPONENTE QUE RENDERIZA OS CIRCULOS DE BACKGROUND QUE APARECEM EM ALGUMAS TELAS
 * 
 * level: define qual o nível do circulo (0, 1 ou 3)
 */
export const BackgroundCircle = ({level, bottom=false, left=false, circleStyle, topRight=false}) => {
    return(
        bottom ?
            left ? 
            <BottomLeftCircle level={level} bottom={bottom} /> : <BottomCircle level={level} bottom={bottom} /> : 
        topRight ?
        (<TopRightCircle level={level} topRight={topRight} />) : 
        (<Circle style={circleStyle} level={level}  />)
        
    );
}
