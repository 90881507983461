import { ChevronLeft } from "react-feather";
import { useTranslation } from "react-i18next";
import { Colors } from "../../../values/colors";
import { TextButton, Title, View } from "../../BaseComponents/styles";
import { Button } from "../../Button";
import { FormField } from "../../FormField";
import { Line } from "../../Line/styles";

export const Terms = ({onClose}) => {

  const [ t, i18n ] = useTranslation('common');

  return (
    <View>
      <Line>
        <ChevronLeft color={Colors.lightGray} onClick={onClose}/>
        <Title style={{marginLeft: '1rem'}}>{t('terms_and_privacy')}</Title>
      </Line>

      <View style={{marginTop: '2rem', textIndent: '1rem', textAlign: 'justify', color: Colors.mediumGray}}>
        <p style={{marginTop: '1rem' }}>A sua privacidade é importante para nós. É política do respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site , e outros sites que possuímos e operamos.</p>
        <p style={{marginTop: '1rem' }}>Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.</p>
        <p style={{marginTop: '1rem' }}>Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis ​​para evitar perdas e roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.</p>
        <p style={{marginTop: '1rem' }}>Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.</p>
        <p style={{marginTop: '1rem' }}>O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.</p>
        <p style={{marginTop: '1rem' }}>Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados.</p>
        <p style={{marginTop: '1rem' }}>O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco.</p>
      </View>
    </View>
  );
}