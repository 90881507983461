import API from '../api';

/**
 * @typedef BusinessUnits
 * @prop {boolean} success
 * @prop {array} business_units
 */

/**
 * @typedef Sectors
 * @prop {boolean} success
 * @prop {array} sectors
 */

/**
 * @typedef States
 * @prop {boolean} success
 * @prop {array} states
 */

/**
 * @typedef Cities
 * @prop {boolean} success
 * @prop {array} cities
 */

/**
 * @typedef Genders
 * @prop {boolean} success
 * @prop {array} genders
 */

/**
 * @typedef DashboardFilters
 * @prop {array} sector
 * @prop {array} business_unit
 * @prop {array} state
 * @prop {array} city
 * @prop {array} gender
 */

export default class {
  /**
   * @param {BusinessUnits} data
   */
  static async businessUnits (data) {
    const res = await API.get('list/business_units', data);
    return res.data;
  }

  /**
   * @param {Sectors} data
   */
  static async sectors (data) {
    const res = await API.get('list/sectors', data);
    return res.data;
  }

  /**
   * @param {States} data
   */
  static async states (data) {
    const res = await API.get('list/states', data);
    return res.data;
  }

  /**
   * @param {Cities} data
   */
  static async cities (data) {
    const res = await API.get('list/cities', data);
    return res.data;
  }

  /**
   * @param {Genders} data
   */
  static async genders (data) {
    const res = await API.get('list/genders', data);
    return res.data;
  }

  /**
   * @param {DashboardFilters} data
   */
  static async dashboardFilters (data) {
    const res = await API.get('list/dashboard-filters', data);
    return res.data;
  }
}