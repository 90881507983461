import { Chart } from 'react-google-charts';

export default ({ data }) => (
  <Chart
    width={'100%'} height={'100%'}
    chartType="LineChart" loader={<div>Carregando dados...</div>}
    data={data}
    options={{
      chartArea: {
        width: '91%',
        height: '75%'
      },
      colors: [ '#36B37E' ],
      legend: 'none',
      pointsVisible: true, 
      pointSize: 6,
      fillColor: '#657E94',
      borderColor: '#657E94',
      vAxis: {
        minValue: 0,
        maxValue: 10,
        textStyle: { color: '#657E94' },
        baselineColor: '#ECF0F6',
        gridlines: { color: '#ECF0F6', minSpacing: 20, count: 5 },
        viewWindowMode: 'maximized'
      },
      hAxis: { 
        textStyle: { 
          color: '#657E94'
        },
        showTextEvery: 10
      },
      lineWidth: 5,
      series: { 0: { curveType: 'function' } },
      tooltip: { trigger: 'selection' },
      height: '100%',
      width: '100%',
      backgroundColor: '#fff'
    }}
    rootProps={{ 'data-testid': '2' }}
  />
)