import styled from 'styled-components'

export const Main = styled.main`
  display: flex;
  flex-direction: column;

  width: 50%;

  background: #FFFFFF;
  border: 1px solid #EFF2F7;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.02);
  border-radius: 7px;

  padding: 3.125rem;
  gap: 0.625rem;

  @media (max-width: 768px)
  {
    padding: 2rem;
    width: 100%;
  }
`

export const PreSingUpForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`