import styled from 'styled-components';

export const IndicatorHead = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #EFF2F7;
  align-items: center;
`;

export const Percent = styled.h3`
  font-size: 30px;
  font-weight: 400;
`;

export const VariationContainer = styled.div`
  background: #D7F0E5;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  color: #36B37E;
  display: flex;
  padding: .2rem;
`;

export const GoalContainer = styled.div`
  display: flex;
  align-items: center;
  padding: .5rem;
  background: #EFF2F7;
  border-radius: 5px;
  width: 196px;
  height: 51px;
  color: #485465;
  font-size: 13px;
  justify-content: space-between;
`;

export const GoalPin = styled.div`
  display: block;
  width: 8px;
  height: 8px;
  background: #3ED1D2;
  border-radius: 100%;
`;

export const GoalPercent = styled.span`
  font-size: 17px;
`;

export const GraphContainer = styled.div`
  border-bottom: 1px solid #EFF2F7;
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;
`;

export const THead = styled.thead`
  th {
    font-weight: 700;
    font-size: 15px;
    color: #3F4059;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #EFF2F7;
  }

  th:not(:last-of-type) {
    border-right: 1px solid #EFF2F7;
  }
`;

export const TBody = styled.tbody`
  td {
    padding: .8rem 1.5rem;
    font-weight: 600;
    font-size: 21px;
    color: #8492A6;
  }

  td:first-of-type {
    font-size: 15px;
  }
  
  td:not(:last-of-type) {
    border-right: 1px solid #EFF2F7;
  }
`;

export const Select = styled.select`
  width: 100%;
  background-color: transparent;
  border: transparent;
  padding: 1rem 0;

  font-weight: 700;
  font-size: 15px;
  color: #8492A6;
  border-bottom: 1px solid #EFF2F7;
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media (max-width: 1080px) {
    display: block
  }
`;

export const Input = styled.input`
  border: 1px solid #A9BBC9;
  border-radius: 7px;
  padding: 1rem;
  width: 100%;
`;

export const SectionTitle = styled.h4`
  font-weight: 700;
  font-size: 15px;
  color: #8492A6;
  border-bottom: 1px solid #EFF2F7;
  padding: 1rem 0;
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  margin-right: 1rem;
`;

export const Option = styled.div`
  padding: .5rem 1rem;
  border: 1px solid ${props => props.active ? '#6240FF' : '#A9BBC9'};
  border-radius: 7px;
  font-weight: 700;
  font-size: 15px;
  color: ${props => props.active ? '#6240FF' : '#A9BBC9'};
  cursor: pointer !important;
  flex: 0 !important
`;

export const AddBtn = styled.span`
  cursor: pointer !important;
  color: #3ED1D2;
  border-bottom: 1px solid #3ED1D2;
  padding: .5rem;
`;