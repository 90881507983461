import styled from "styled-components";
import { Colors } from "../../values/colors";


export const Container = styled.div`
  padding-left: 8rem;
  padding-top: 8rem;
  width: 50%;
  @media (max-width: 700px){
    width: 100%;
    padding: 5rem 3rem;

    img {
      width: 100%;
      height: auto;
    }
  }

`;

export const HomeText = styled.h1`
  margin-top: 4rem;
  color: ${Colors.blueGray2};
  font-weight: 600;
  font-size: 30px;
`;