import { Link, NavLink } from "react-router-dom";
import Image from "../Image";
import { Container, Nav, NavLeft, NavMenu, NavUser } from "./styles";
import MacroContainer from "../MacroContainer";
import Logo from "../../assets/images/logo_white.svg";
import DefaultImage from "../../assets/images/profile_business.jpg";
import { SideBar } from "../SideBar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownNav } from "../DropdownNav";
import Business from "../../services/Business";
import Auth from "../../services/Auth";
import { usePermissions } from "../../hooks";

export default () => {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [business, setBusiness] = useState({});
  const [t, i18n] = useTranslation('common');
  const { checkPermissions } = usePermissions();
  const getData = async () => {
    const { data } = await Business.get();
    setBusiness(data);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <SideBar visible={sidebarOpen} onClose={() => setSidebarOpen(false)} />
      <Container>
        <MacroContainer>
          <Nav>
            <NavLeft>
              <Image src={Logo} width={50} height={50} />
              <NavMenu>
                <NavLink to="/">{t('home')}</NavLink>
                {checkPermissions('menu:dashboard:single') && <NavLink to="/dashboards">{t('internal_dashboard')}</NavLink>}
                {checkPermissions('menu:dashboard:multi') && <NavLink to="/general-dashboard">{t('general_dashboard')}</NavLink>}
                {checkPermissions('menu:dashboard:vitalscanalerts') && <NavLink to="/vitalscan-alerts">{t('vitalscan_alerts')}</NavLink>}
                {/* <DropdownNav 
                style={{marginLeft: '2rem'}}
                text={t('dashboards')}
                items={[
                  { text: t('user_profiler'), to: '/user_profiler' },
                  { text: t('usage'), to: '/usage' },
                  //{ text: t('indicators'), to: '/indicators' },
                  { text: t('teleattendances'), to: '/tele_stats' },
                  // { text: t('management_report'), to: '/report' },
                ]}
                /> */}
                {checkPermissions('menu:users') && <NavLink to="/users">{t('users')}</NavLink>}
                {checkPermissions('menu:preregister') && <NavLink to="/pre-registration">{t('pre-registration')}</NavLink>}
                {checkPermissions('menu:gamification') && <NavLink to="/leaderboard">{t('gamification')}</NavLink>}
                {checkPermissions('menu:companiesmanagement') && <NavLink to="/companies-management">{t('companies-management')}</NavLink>}

                {/*<NavLink to="/new_dashboard">{t('new_dashboard')}</NavLink>*/}
              </NavMenu>
            </NavLeft>
            <NavUser onClick={() => setSidebarOpen(true)}>
              <Image src={DefaultImage} width={36} height={36} style={{ borderRadius: '100%' }} />
              <span to="/user_profiler">{business.title}</span>
            </NavUser>
          </Nav>
        </MacroContainer>
      </Container>
    </>
  );
};