import styled from "styled-components";


export const Line = styled.div`
    position: relative;
    display: flex;
    flex-wrap: ${props => props.wrap || 'nowrap'};
    justify-content: ${props => props.align || 'start'};
    align-items: ${props => props.alignItems || 'center'};
    gap: ${props => props.gap || '0'}px;
`;