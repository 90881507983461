import styled from "styled-components";
import { Colors } from "../../../../values/colors";

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const InputText = styled.input`
	margin-right: 20px;
	background: none;
	width: 100%;
	font-size: 12px;
	font-weight: 700;
	color: ${Colors.mediumGray};
	border: none;
	outline: none;
	border: none;
	padding: 10px 0;
	border-bottom: 1px solid ${Colors.lightGray};

	&.title-name {
		color: ${Colors.blueGray};
		font-size: 1rem;
	}

	&::placeholder {
		color: ${Colors.mediumGray};
	}

	&.filled:disabled {
		border-radius: 50px;
		color: white;
		background: ${Colors.lightGray};
		padding: 5px;
		width: auto;
		text-align: center;
		borderRadius: 5px;
	}

	&.filled::placeholder {
		color: white;
	}

	&:disabled{
		padding: 0;
		border: none;
		background: none;
	}
`;

export const Dropdown = styled.div`
  display: ${props => props.open ? 'block' : 'none'};
  border: 1px solid #EFF2F7;
  border-top: none;
  background-color: #FFFFFF;
  position: absolute;
  width: 100%;
  z-index: 1000;
  max-height: 400px;
  overflow: auto;
`;

export const Item = styled.div`
  padding: .5rem;
  cursor: pointer !important;
  &:hover {
    background-color: #EFF2F7;
  }
`;

export const SuffixIcon = styled.div`
  position: absolute;
  bottom: 6px;
  right: .75rem;
`;