import { Link } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../../values/colors";

export const Container = styled.header`
  width: 100%;
  background-color: ${Colors.primaryColor};
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid #FFF;
`;

export const NavLeft = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const NavMenu = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  &>a {
    position: relative;
    margin-left: 2rem;
    color: white;
    font-weight: 800;
    text-decoration: none;
    &.active:after{
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      background: white;
      left: 0;
      bottom: -30px;
    }
  }
`;

export const NavUser = styled.div`
  float: right;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 5px 5px;
  border-radius:50px;
  background: rgba(0,0,0,.3);
  cursor: pointer !important;
  span {
    margin: 0 1rem;
    color: white;
    text-decoration: none;
    font-weight: 800;
    font-size: 14px;
  }

  @media (max-width: 768px){
    span{
      display: none;
    }
  }

`;