import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import common_pt_BR from "./translations/pt_BR/common.json";
import "react-activity/dist/library.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { CompaniesManagementProvider } from './screens/CompaniesManagement/context/CompaniesManagement';

i18next.init({
  interpolation: {escapeValue: false},
  lng: 'pt_BR',
  fallbackLng: 'pt_BR',
  resources: {
    pt_BR: {
      common: common_pt_BR
    }
  }
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <CompaniesManagementProvider>
      <App />
      <ToastContainer theme={'colored'}/>
      </CompaniesManagementProvider>

    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
