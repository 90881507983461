import Image from "../../../components/Image";
import LogoFullWhite from "../../../assets/images/logo_full_white.svg";
import { Container } from "./styles";
import { FlatButton, Input, Text, Title, View } from "../../../components/BaseComponents/styles";
import { Colors } from "../../../values/colors";
import { useTranslation } from "react-i18next";
import { BackgroundCircle } from "../../../components/BackgroundCircle";
import { FormFieldBox } from "../../../components/FormFieldBox";
import { Eye, EyeOff } from "react-feather";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { TextLink } from "../../../components/TextLink";
import { Line } from "../../../components/Line/styles";
import { useState } from "react";
import { ActionButton } from "../../../components/ActionButton";
import Auth from "../../../services/Auth";
import { ErrorMessage } from '../../../utils/alerts';

export default () => {
  const navigate = useNavigate();
  const  [ t, i18n ] = useTranslation('common');
  const [ showPass, setShowPass ] = useState(false);
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ remember, setRemember ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);

  const location = useLocation();

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try{
      const data = await Auth.signIn(email, password, remember);
      if (data.success) {
        navigate('/');
      } else {
        ErrorMessage({ message: 'Email ou senha incorretos' });
      }
    }catch(e){
      ErrorMessage({ message: 'Email ou senha incorretos' });
    }
    setIsLoading(false);
  }

 
  return (
    !Auth.signedIn ?
    <Container>
      <View className="login-container" style={{backgroundColor: Colors.primaryBlue2}}> 
        <View style={{zIndex: 1}}>
          <Image src={LogoFullWhite}/>
          <div className="horizontal-line"></div>
          <Title className="home-text">{t('homepage_text')}</Title> 
        </View>

        <BackgroundCircle circleStyle={{zIndex: 0, background: Colors.primaryBlue, position: 'absolute'}} level={0}/>
        <BackgroundCircle circleStyle={{zIndex: 0, background: `${Colors.primaryBlue}66`, position: 'absolute'}} level={1}/>
      </View>
      <View className="login-container" style={{alignItems: 'start', textAlign: 'left'}}>

        <Title className="login-title">{t('do_your_login')}</Title>

        <form onSubmit={onSubmit}>
          <FormFieldBox
            label={'E-mail'}
            labelStyle={{fontSize: '1.2rem'}}
            onChange={e => setEmail(e.target.value)}
          />
          <FormFieldBox
            type={showPass ? 'text' : 'password'}
            label={'Senha'}
            labelStyle={{fontSize: '1.2rem'}}
            onChange={e => setPassword(e.target.value)}
            suffixIconButton={
              <FlatButton
                type='button'
                onClick={()=>setShowPass(prev=>!prev)}
              >
                {
                  showPass ? 
                  <EyeOff color={Colors.primaryPurple}/>
                  :
                  <Eye 
                    color={Colors.primaryPurple}
                    />

                }
              </FlatButton>
            }
          />

          <TextLink color={Colors.mutedGray} to="/redefine-senha" style={{display: 'inline-block', marginTop: '1rem'}}>{t('forgot_pass')}</TextLink>

          <Line role="button" style={{marginTop: '2rem'}}>
            <Input id="remember" type="checkbox" color={Colors.mutedGray} onChange={e => setRemember(!remember)} checked={remember}/>
            <label style={{cursor: 'pointer'}} htmlFor="remember"><Text color={Colors.mutedGray} weight={700} style={{marginLeft: 5}}>{t('remember_me')}</Text></label>
          </Line>

          <Line align={'space-between'} style={{marginTop: '3rem'}}>
            <ActionButton isLoading={isLoading}>
              {t('do_login')}
            </ActionButton>
            {/* <TextLink color={Colors.primaryPurple} to="/cadastrar">{t('register_new_account')}</TextLink> */}
          </Line>
        </form>

      </View>
    </Container>
  : 
  <Navigate to="/" replace state={{ path: location.pathname }}/>
  );
}