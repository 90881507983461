import styled from 'styled-components';

export const Circle = styled.div`
    position: fixed;
    transform: rotate(-135deg);
    border-radius: 50%;
    width: ${props => props.level === 0 ? '425px' : props.level === 1 ? '1400px' : '2200px'};
    height: ${props => props.level === 0 ? '425px' : props.level === 1 ? '1400px' : '2200px'};
    background: ${props => props.level == 0 ? 
        'linear-gradient(180deg,rgb(189,201,214,1) 0%,rgb(190,203,215,1) 100%)' 
        : props.level === 1 ? 'linear-gradient(180deg,rgb(204,214,224,1) 0%,rgb(204,214,224,1) 100%)' 
        : 'linear-gradient(180deg,rgb(211,219,228,1) 0%,rgb(211,219,228,1) 100%)'};
    top: ${props => props.level === 0 ? '-215px' : props.level === 1 ? '-850px' :  props.level === 2 ? '-1103px' : '1103px'};
    left: ${props => props.level === 0 ? '-215px' : props.level === 1 ? '-750px' :  props.level === 2 ? '-1103px' : '1103px'};
    z-index: ${props => props.level === 0 ? '-1' : props.level === 1 ? '-2' :  props.level === 2 ? '-3' : '-4'};
`;

export const BottomCircle = styled.div`
    position: fixed;
    transform: rotate(-135deg);
    border-radius: 50%;
    width: ${props => props.level === 0 ? '425px' : props.level === 1 ? '800px' : '950px'};
    height: ${props => props.level === 0 ? '425px' : props.level === 1 ? '800px' : '950px'};
    background: ${props => props.level == 0 ? 
        'linear-gradient(180deg,rgb(143,131,227,1) 0%,rgb(143,131,227,1) 100%)' 
        : props.level === 1 ? 'linear-gradient(180deg,rgb(143,131,227,0.5) 0%,rgb(143,131,227,0.5)  100%)' 
        : 'linear-gradient(180deg,rgb(143,131,227,0.1) 0%,rgb(143,131,227,0.1)  100%)'};
    bottom: ${props => props.level === 0 ? '-300px' : props.level === 1 ? '-500px' :  props.level === 2 ? '-500px' : '500px'};
    right: ${props => props.level === 0 ? '-300px' : props.level === 1 ? '-500px' :  props.level === 2 ? '-500px' : '500px'};
    z-index: ${props => props.level === 0 ? '-1' : '-2'};
`;

export const BottomLeftCircle = styled.div`
    position: fixed;
    transform: rotate(-135deg);
    border-radius: 50%;
    width: ${props => props.level === 0 ? '425px' : props.level === 1 ? '800px' : '950px'};
    height: ${props => props.level === 0 ? '425px' : props.level === 1 ? '800px' : '950px'};
    background: ${props => props.level == 0 ? 
        'linear-gradient(180deg,rgb(143,131,227,1) 0%,rgb(143,131,227,1) 100%)' 
        : props.level === 1 ? 'linear-gradient(180deg,rgb(143,131,227,0.5) 0%,rgb(143,131,227,0.5)  100%)' 
        : 'linear-gradient(180deg,rgb(143,131,227,0.1) 0%,rgb(143,131,227,0.1)  100%)'};
    bottom: ${props => props.level === 0 ? '-300px' : props.level === 1 ? '-500px' :  props.level === 2 ? '-500px' : '500px'};
    left: ${props => props.level === 0 ? '-300px' : props.level === 1 ? '-500px' :  props.level === 2 ? '-500px' : '500px'};
    z-index: ${props => props.level === 0 ? '-1' : '-2'};
`;

export const TopRightCircle = styled.div`
    position: fixed;
    transform: rotate(-135deg);
    border-radius: 50%;
    width: ${props => props.level === 0 ? '300px' : props.level === 1 ? '1000px' : '1500px'};
    height: ${props => props.level === 0 ? '300px' : props.level === 1 ? '1000px' : '1500px'};
    background: ${props => props.level == 0 ? 
        'linear-gradient(180deg,rgb(189,201,214,1) 0%,rgb(190,203,215,1) 100%)' 
        : props.level === 1 ? 'linear-gradient(180deg,rgb(204,214,224,0.5) 0%,rgb(204,214,224,0.5) 100%)' 
        : 'linear-gradient(180deg,rgb(211,219,228,0.2) 0%,rgb(211,219,228,0.2) 100%)'};
    top: ${props => props.level === 0 ? '-140px' : props.level === 1 ? '-550px' :  props.level === 2 ? '-850px' : '1103px'};
    right: ${props => props.level === 0 ? '-200px' : props.level === 1 ? '-600px' :  props.level === 2 ? '-800px' : '1103px'};
    z-index: ${props => props.level === 0 ? '-1' : props.level === 1 ? '-2' :  props.level === 2 ? '-3' : '-4'};
`;

export const LoginCircle = styled.div`
    position: fixed;
    transform: rotate(-135deg);
    border-radius: 50%;
    width: ${props => props.level === 0 ? '425px' : props.level === 1 ? '1400px' : '2200px'};
    height: ${props => props.level === 0 ? '425px' : props.level === 1 ? '1400px' : '2200px'};
    background: ${props => props.level == 0 ? 
        'linear-gradient(180deg,rgb(61,227,228,1) 0%,rgb(61,227,228,1) 100%)' 
        : props.level === 1 ? 'linear-gradient(180deg,rgb(61,227,228,1) 0%,rgb(61,227,228,1) 100%)' 
        : 'linear-gradient(180deg,rgb(211,219,228,1) 0%,rgb(211,219,228,1) 100%)'};
    top: ${props => props.level === 0 ? '-215px' : props.level === 1 ? '-850px' :  props.level === 2 ? '-1103px' : '1103px'};
    left: ${props => props.level === 0 ? '-215px' : props.level === 1 ? '-750px' :  props.level === 2 ? '-1103px' : '1103px'};
    z-index: ${props => props.level === 0 ? '-1' : props.level === 1 ? '-2' :  props.level === 2 ? '-3' : '-4'};
`;