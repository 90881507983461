import React, { useEffect, useState } from "react";
import { Check, Info } from "react-feather";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Box } from "../../components/Box";
import DataTable from "../../components/DataTable";
import { Modal } from "../../components/Modal";
import TextBox from "../../components/TextBox";
import { Colors } from "../../values/colors";

import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

import {
  getAcceptPreRegisterUser,
  getRejectPreRegisterUser,
  getUsers,
  putPreRegisterUser,
} from "../../services/PreRegister";

import List from "../../services/List";

import {
  TextContainer,
  UserContainer,
  UserName,
  UserPic,
} from "../Users/styles";

import {
  BackButton,
  CustomFlatButton,
  InfoModalMain,
  RejectButton,
  RejectModalHeader,
  RejectModalMain,
  RejectText,
  TitleHeader,
} from "./styles";

import { ErrorMessage, SuccessMessage } from "../../utils/alerts";

import { Text } from "../../components/BaseComponents/styles";

import Business from "../../services/Business";

const renderUserNameWithProfilePicCol = (row) => {
  const rowFormated = { ...row, key: row.id };
  return (
    <UserContainer>
      <UserPic user={rowFormated} />
      <TextContainer>
        <UserName>{rowFormated?.name}</UserName>
      </TextContainer>
    </UserContainer>
  );
};

const AcceptButton = ({ onClick }) => (
  <CustomFlatButton onClick={onClick}>
    <Check size={18} color="#fff" />
  </CustomFlatButton>
);

const RejectModal = ({ isOpen, onClose, onReject, id }) => {
  const { t } = useTranslation("common");
  return (
    <Modal
      title={
        <RejectModalHeader isReject>
          <TitleHeader>{t("decline-request")}</TitleHeader>
          <RejectText>{t("pre-registration-reject-modal-text")}</RejectText>
        </RejectModalHeader>
      }
      open={isOpen}
      onClose={onClose}
      disableOnClose
    >
      <RejectModalMain>
        <BackButton onClick={onClose}>{t("go-to-back")}</BackButton>
        <RejectButton onClick={() => onReject(id)} isReject>
          {t("decline")}
        </RejectButton>
      </RejectModalMain>
    </Modal>
  );
};

const InfoModal = ({ isOpen, onClose, businessName }) => {
  const replaceBlankSpace = (string = "") => {
    return string.replaceAll(" ", "_");
  };
  const { t } = useTranslation("common");
  return (
    <Modal
      title={
        <RejectModalHeader>
          <TitleHeader>{t("pre-registration-info-modal")}</TitleHeader>
          <RejectText>
            {t("pre-registration-info-modal-text-part-1")}
          </RejectText>
          <RejectText>
            {t("pre-registration-info-modal-text-part-2")}
          </RejectText>
          <RejectText>{t("info-modal-business-link")}</RejectText>
          <RejectText>
            <a
              target="_blank"
              href={`https://business.namu.com.br/pre-registration/${replaceBlankSpace(
                businessName
              )}`}
              rel="noreferrer"
            >
              {`https://business.namu.com.br/pre-registration/${replaceBlankSpace(
                businessName
              )}`}
            </a>
          </RejectText>
        </RejectModalHeader>
      }
      open={isOpen}
      onClose={onClose}
      disableOnClose
    >
      <InfoModalMain>
        <RejectButton onClick={onClose}>{t("ok")}</RejectButton>
      </InfoModalMain>
    </Modal>
  );
};

export const PreRegistration = () => {
  const [preRegisterUsers, setPreRegisterUsers] = useState([]);
  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);
  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [page, setPage] = useState(1);

  const [sectors, setSectors] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [preRegisterUserCount, setPreRegisterUserCount] = useState(0);
  const [businessData, setBusinessData] = useState({});

  const [setOptions] = useOutletContext();

  const { t } = useTranslation("common");

  useEffect(() => {
    loadFilters();
    loadBusinessInfo();
  }, []);

  useEffect(() => {
    setOptions({
      start: (
        <Text
          color={"white"}
          weight={700}
          style={{ textDecoration: "underline" }}
        >
          {t("pre-registration-management")}
        </Text>
      ),
      middle: (
        <Text color={"white"} size={14}>
          <Text color={"white"} size={30} weight={700}>
            {preRegisterUserCount}
          </Text>{" "}
          {t("pre-registered_users")}
        </Text>
      ),
    });

    return () => setOptions(null);
  }, [sectors, businessUnits, preRegisterUserCount]);

  useEffect(() => {
    getPreRegisterUsers(page);
  }, [page]);

  const loadBusinessInfo = async () => {
    try {
      const data = await Business.get();
      setBusinessData(data.data);
    } catch (error) {
      console.warn(error);
    }
  };

  const loadFilters = async () => {
    const { business_units: _businessUnits } = await List.businessUnits();
    setBusinessUnits(
      _businessUnits
        .map((unit) => unit.title)
        .filter((unit) => unit.length != 0)
    );
    const { sectors: _sectors } = await List.sectors();
    setSectors(
      _sectors
        .map((sector) => sector.title)
        .filter((sector) => sector.length != 0)
    );
  };

  const getPreRegisterUsers = async (page) => {
    try {
      const users = await getUsers(page);

      setPreRegisterUsers(users.data);
      setPreRegisterUserCount(users.totalUsers);
    } catch (error) {
      ErrorMessage({ message: t("failed-to-fetch-users") });
      console.warn(error);
    }
  };

  const handleUpdatePreRegisterUser = async (user) => {
    try {
      const updatedUser = await putPreRegisterUser(user);

      setPreRegisterUsers((state) => {
        return state.map((item) => (item.id === user.id ? updatedUser : item));
      });
      SuccessMessage({ message: t("success-to-edit-user") });
    } catch (error) {
      ErrorMessage({ message: t("failed-to-edit-user") });
      console.warn(error);
    }
  };

  const handleRejectPreRegisterUser = async (id) => {
    try {
      await getRejectPreRegisterUser(id);
      setPreRegisterUsers((state) => {
        return state.filter((item) => item.id !== id);
      });
      handleCloseModal();
      setCurrentId(0);
      setPreRegisterUserCount((state) => state - 1);
      SuccessMessage({ message: t("success-to-reject-user") });
    } catch (error) {
      ErrorMessage({ message: t("failed-to-reject-user") });
      console.warn(error);
    }
  };

  const handleAcceptPreRegisterUser = async (user) => {
    try {
      await getAcceptPreRegisterUser(user.id);
      setPreRegisterUsers((state) => {
        return state.filter((item) => item.id !== user.id);
      });
      setPreRegisterUserCount((state) => state - 1);
      SuccessMessage({ message: t("success-to-accept-user") });
    } catch (error) {
      ErrorMessage({ message: t("failed-to-accept-user") });
      console.warn(error);
    }
  };

  const formatDate = (row) => {
    const dateToFormat = new Date(row.createdAt);
    return format(dateToFormat, "dd/MM/yyyy HH:mm", {
      locale: ptBR,
    });
  };

  const handleCloseModal = () => setIsOpenRejectModal(false);

  const handleOpenModal = (index, user) => {
    setCurrentId(user.id);
    setIsOpenRejectModal(true);
  };

  const handleCloseOrOpenInfoModal = () =>
    setIsOpenInfoModal((state) => !state);

  return (
    <>
      <Box
        title={t("pre-registered-requests")}
        noPadding={true}
        actions={
          <Info
            size={18}
            color={Colors.secondaryPurple}
            onClick={handleCloseOrOpenInfoModal}
          />
        }
      >
        <DataTable
          collumns={[
            {
              title: "Nome",
              key: "name",
              editable: true,
              field: TextBox,
              render: renderUserNameWithProfilePicCol,
            },
            {
              title: "Email",
              key: "email",
              editable: true,
              field: TextBox,
            },
            {
              title: "Setor",
              key: "sector",
              editable: true,
              field: TextBox,
            },
            {
              title: "Unidade de Negócio",
              key: "business_unit",
              editable: true,
              field: TextBox,
            },
            {
              title: `CPF`,
              key: "cpf",
              editable: true,
              field: TextBox,
            },
            {
              title: "Data de requisição",
              key: "createdAt",
              render: (row) => formatDate(row),
            },
          ]}
          actions={(row) => [
            <AcceptButton onClick={() => handleAcceptPreRegisterUser(row)} />,
          ]}
          data={preRegisterUsers}
          onUpdate={handleUpdatePreRegisterUser}
          onDelete={handleOpenModal}
          totalEntries={preRegisterUserCount}
          onPageChange={setPage}
          defaultActionNewStyle
        />
      </Box>
      <RejectModal
        onClose={handleCloseModal}
        isOpen={isOpenRejectModal}
        onReject={handleRejectPreRegisterUser}
        id={currentId}
      />

      <InfoModal
        isOpen={isOpenInfoModal}
        onClose={handleCloseOrOpenInfoModal}
        businessName={businessData.title}
      />
    </>
  );
};
