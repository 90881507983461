import { useState } from "react";
import { MoreHorizontal, MoreVertical } from "react-feather";
import { Colors } from "../../values/colors";
import { FlatButton, Text } from "../BaseComponents/styles";
import { Container, DropdownContainer } from "./styles";


export const DropdownMenu = ({icon, text, style, dropDownStyle, renderButton, children}) => {

  const [ active, setActive ] = useState(false);

  return (
    <Container style={style}>
      <FlatButton onFocus={() => setActive(true)} onBlur={(e) => { setTimeout(() => setActive(false), 250)}}>
      {renderButton || (
        text ? <Text>{text}</Text> : (icon || <MoreHorizontal color={Colors.mediumGray} size={20}/>)
      )}
      </FlatButton>

      <DropdownContainer style={dropDownStyle} className={active ? 'show' : ''}>
        {children}
      </DropdownContainer>

    </Container>
  );
}