import React from 'react';
import { Line } from '../Line/styles';
import { Container, Tab } from './styles';

export const Tabs = ({ activeTab, onChange, tabs }) => {

  return (
    <Container>
      <Line>
        {tabs.map(tab => <Tab key={tab.key} className={activeTab == tab.key ? 'active' : ''} onClick={()=>onChange(tab.key)}>{tab.title}</Tab>)}
      </Line>
    </Container>
  );
}