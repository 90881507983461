import FlexPanel from '../../components/FlexPanel';
import { Box } from "../../components/Box";
import NoData from '../../components/NoData';
import Stats from '../../services/Stats';
import { useEffect, useState } from 'react';
import { PercentBar } from '../../components/PercentBar';
import { DropdownSelect } from '../../components/DropdownSelect/styles';
import { Text } from '../../components/BaseComponents/styles';
import { useOutletContext } from 'react-router-dom';
import List from '../../services/List';
import { useTranslation } from 'react-i18next';

export default () => {
  const [ data, setData ] = useState({});
  const [ loading, setLoading ] = useState(false);
  
  const { t } = useTranslation('common');
  
  const [ filters, setFilters ] = useState({});
  const [ sectors, setSectors ] = useState([]);
  const [ businessUnits, setBusinessUnits ] = useState([]);
  const [ setOptions ] = useOutletContext();

  const loadFilters = async () => {
    const {business_units: _businessUnits} = await List.businessUnits();
    setBusinessUnits(_businessUnits.map(unit => unit.title).filter(unit => unit.length != 0));
    const { sectors: _sectors} = await List.sectors();
    setSectors(_sectors.map(sector => sector.title).filter(sector => sector.length != 0));
  }

  const setFilter = (param, value) => {
    setFilters(prev => {
      let _newFilters = {...prev};
      _newFilters[param] = value;
      return _newFilters;
    });
  }

  useEffect(()=>{
    loadFilters();
  }, []);

  useEffect(()=>{
    setOptions({
      end: [
        <DropdownSelect onChange={(e)=>setFilter('business_unit',e.currentTarget.value)} value={filters.business_unit}>
          <option value="">{t('all_business_units')}</option>
          {businessUnits.map(b=> { return <option value={b}>{b}</option>} )}
        </DropdownSelect>,
        <DropdownSelect onChange={(e)=>setFilter('sector',e.currentTarget.value)} value={filters.sector}>
          <option value="">{t('all_sectors')}</option>
          {sectors.map(s=> { return <option value={s}>{s}</option>} )}
        </DropdownSelect>
      ]
    });
    
    return () => setOptions(null);
  }, [ sectors, businessUnits ]);

  const getData = async () => {
    setLoading(true);
    const data = await Stats.userProfiler(filters);
    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [filters]);

  const BarList = ({ entries, color }) => {
    const total = !entries?.length ? 0 : entries.reduce((total, obj) => total + obj.value, 0);
    return !entries?.length ?
    (<NoData loading={loading}/>) :
    (<>
      {entries.map(entry =>
        <PercentBar total={total} color={color} {...entry}/>
      )}
    </>);
  };

  return (
    <>
      <FlexPanel>
        <Box 
          title={'Ranking de objetivos'} 
          headStyle={{ border: 0, padding: '1rem 1.5rem' }}
          bodyStyle={{ overflow: 'auto', height: '470px', paddingTop: 0 }}
        >
          <BarList entries={data?.goals} color={'#2d9cee'}/>          
        </Box>
        <Box 
          title={'Ranking de interesses ou práticas'} 
          headStyle={{ border: 0, padding: '1rem 1.5rem' }}
          bodyStyle={{ overflow: 'auto', height: '470px', paddingTop: 0 }}
        >
          <BarList entries={data?.interests} color={'#3ed1d2'}/>   
        </Box>
      </FlexPanel>

      <FlexPanel>
        <Box 
          title={'Ranking de alergias e restrições'} 
          headStyle={{ border: 0, padding: '1rem 1.5rem' }}
          bodyStyle={{ overflow: 'auto', height: '470px', paddingTop: 0 }}
        >
          <BarList entries={data?.dietaryRestrictions} color={'#ff5050'}/>   
        </Box>
        <Box
          title={'Ranking de restrições físicas'}
          headStyle={{ border: 0, padding: '1rem 1.5rem' }}
          bodyStyle={{ overflow: 'auto', height: '470px', paddingTop: 0 }}
        >
          <BarList entries={data?.muscleRestrictions} color={'#3ed1d2'}/>
        </Box>
      </FlexPanel>

    </>
  );
};