import styled from 'styled-components';

export const Container = styled.div`
  //position: relative;
`;

export const Head = styled.div`
  padding: ${props => props.open ? '.5rem' : '0'};
  border-radius: 4px 4px 0 0;
  border: ${props => props.open ? '1px solid #EFF2F7' : 'none'};
  border-bottom: none;
`;

export const Dropdown = styled.div`
  display: ${props => props.open ? 'block' : 'none'};
  border: 1px solid #EFF2F7;
  border-top: none;
  background-color: #FFFFFF;
  position: absolute;
  width: 140px;
  z-index: 1000;
  max-height: 400px;
  overflow: auto;
`;

export const Item = styled.div`
  padding: .5rem;
  cursor: pointer !important;
  &:hover {
    background-color: #EFF2F7;
  }
`;

export const Input = styled.input`
  padding: .5rem;
  font-weight: 600;
  font-size: 1rem;
  border: 0.7px solid #6244EB;
  box-shadow: inset 0px 5px 5px rgba(101, 126, 148, 0.04);
  border-radius: 4px;
  width: 100%
`;