import styled from "styled-components";
import { Colors } from "../../values/colors";


export const Container = styled.div`
  position: absolute;
  border-radius: 10px;
  overflow: hidden; 
  right: -1px;
  top: -70px;
  z-index: 5;
  background-color: ${Colors.primaryPurple};
  width: 130px;
  max-height: 43px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer !important;
  transition: background-color .3s, width .3s, max-height .3s;

  .fake-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    .icon { 
      margin-right: 10px;
    }
    .icon path {
      fill: white;
      transition: fill 0.3s;
    }

    .title {
      color: white;
      transition: color .3s;
    }

  }

  &.open {
    box-shadow: 0px 0px 10px #00000022;
    align-items: start;
    cursor: auto;
    min-height: 260px;
    overflow: hidden;
    overflow-y: auto;
    background-color: white;
    width: calc(100% + 2px);
    max-height: 600px;
    padding: 1.6rem 1.5rem;
    .top {
      width: 100%;
      padding-bottom: 1rem;
      border-bottom: 1px solid #eee;
    }
    .fake-button {
      padding: 0;

      .icon path {
        fill: ${Colors.primaryPurple}
      }
      .title {
        color: ${Colors.primaryPurple};
      }
    }

    .filter-body{
      margin-top: 1rem;
    }

  }



`;

export const StretchedContainer = styled.div`
  flex-grow: 1;
`;

export const Content = styled.div`
  width: 200px;

  .filter-input{
    margin: 0 5px;
    padding: 8px;
    width: 70px;
    border-radius: 8px;
    outline: none;
    border: 1px solid ${Colors.primaryPurple}44;
    box-shadow: inset 0px 0px 10px ${Colors.primaryPurple}11;
    &:focus{
      border: 1px solid ${Colors.primaryPurple};
    }
  }
`;
