import API from '../api';

export default class {
  static async list () {
    const res = await API.get('managers');
    return res.data;
  }

  static async create (data) {
    const res = await API.post('manager', data);
    return res.data;
  }

  static async update (data) {
    const res = await API.put(`manager`, data);
    return res.data;
  }

  static async delete (id) {
    const res = await API.delete(`manager/${id}`);
  }
}