import React from 'react';

const InputDinamic = ({ ...rest }) => {
  return (
    <div className="mb-4 flex items-center">
      <input
        className="border rounded-lg px-3 py-2 w-full focus:outline-none focus:border-blue-500"
        {...rest}
      />
    </div>
  );
};

export default InputDinamic;