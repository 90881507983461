import { Spinner } from "react-activity";
import { ArrowRight } from "react-feather";
import { Colors } from "../../values/colors";
import { Button } from "../Button";

export const ActionButton = ({ icon, color, onClick, showIcon = true, actionIcon, isLoading, textColor, style, children, type }) => {

  return (
    <Button
      disabled={isLoading}
      onClick={onClick}
      color={color || Colors.primaryPurple}
      textColor={textColor}
      type={type}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1rem', height: 50, padding: '0 2rem', ...style }}
    >
      {children}
      {!isLoading ?
        (showIcon ? (actionIcon || <ArrowRight size={18} style={{ marginLeft: 10 }} />) : null)
        : <Spinner color="#fff" size={12} style={{ marginLeft: 10 }} />
      }
    </Button>

  );

}