import styled from "styled-components";

export const BarContainer = styled.div`
display: flex;
`;

export const BarFlexContainer = styled.div`
margin-right: 1rem;
:first-of-type {
  flex: 1;
}
:last-of-type {
  width: 30px;
}
`;

export const BarCaption = styled.div`
font-family: Nunito Sans;
font-size: 15px;
color: #485465;
`;

export const Bar = styled.div`
overflow: hidden;
background: #EEEDED;
border-radius: 7px;
height: 12px;
width: 100%;
`;

export const BarProgress = styled.div`
background: ${props => props.color};
border-radius: 7px;
height: 12px;
width: ${props => props.percent}%;
`;

export const BarNumberLabel = styled.div`
  margin-left: 10px;
`;

export const BarLine = styled.div`
  display: flex;
  align-items: center;
`;

export const BarValue = styled.div`
font-family: Nunito Sans;
font-size: 15px;
color: #485465;
line-height: 1;
margin-top: .6rem;
`;

export const BarPercent = styled.div`
font-family: Nunito Sans;
font-weight: bold;
font-size: 15px;
color: #485465;
`;