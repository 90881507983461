import React from 'react';
import { ImageBg } from './styles';
import Person from '../../assets/images/person.svg';

function ProfilePhoto({src,size = 122}) {
    return(
        <ImageBg style={{
          background: `url('${src ? src : Person}')`, 
          width: size, 
          height: size, 
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }} />
    )
}

export default ProfilePhoto;