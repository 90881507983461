import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { ChevronLeft } from "react-feather";
import { useTranslation } from "react-i18next";
import Business from "../../../services/Business";
import { SuccessMessage } from "../../../utils/alerts";
import { Colors } from "../../../values/colors";
import { TextButton, Title, View } from "../../BaseComponents/styles";
import { Button } from "../../Button";
import { FormField } from "../../FormField";
import { Line } from "../../Line/styles";

export const MyData = ({ onClose, show }) => {
  const [ t, i18n ] = useTranslation('common');

  const [ name, setName ] = useState();
  const [ title, setTitle ] = useState(); 
  const [ cnpj, setCnpj ] = useState();
  const [ cep, setCep ] = useState();
  const [ street, setStreet ] = useState();
  const [ city, setCity ] = useState();
  const [ state, setState ] = useState();
  const [ neighborhood, setNeighborhood ] = useState();
  const [ creation, setCreation ] = useState();
  const [ active, setActive ] = useState();

  const getData = async () => {
    const { data } = await Business.get();
    setName(data.name);
    setTitle(data.title);
    setCnpj(data.cnpj);
    setCep(data.cep);
    setStreet(data.street);
    setNeighborhood(data.neighborhood);
    setCity(data.city);
    setState(data.state);
    setCreation(data.createdAt);
    setActive(data.active || true);
  };

  const saveData = async (e) => {
    e.preventDefault();
    await Business.update({
      title, name, cnpj,
      cep, street, city,
      state, neighborhood
    });

    SuccessMessage({ message: t('save_successfully') });
  };

  useEffect(() => {
    if (show == true) getData();
  }, [ show ]);

  return (
    <View>
      <Line>
        <ChevronLeft color={Colors.lightGray} onClick={onClose}/>
        <Title style={{marginLeft: '1rem'}}>{t('my_data')}</Title>
      </Line>

      <View style={{marginTop: '2rem'}}>
        <form onSubmit={saveData}>
          <FormField
            label={t('company_name')}
            placeholder={t('insert_company_name')}
            value={name}
            onChangeValue={setName}
            disabled
            required
          />
          <FormField
            label={t('fantasy_name')}
            placeholder={t('insert_fantasy_name')}
            value={title}
            onChangeValue={setTitle}
            disabled
            required
          />
          <FormField
            label={t('account_created_at')}
            placeholder={'00/00/0000'}
            value={moment(creation).format('DD/MM/YYYY')}
            disabled
          />
          <FormField
            label={t('cnpj')}
            placeholder={t('00.000.000/0000-00')}
            value={cnpj}
            onChangeValue={setCnpj}
            disabled
            required
          />
          <FormField
            label={t('cep')}
            placeholder={t('00.000-000')}
            value={cep}
            onChangeValue={setCep}
            disabled
            required
          />
          <FormField
            label={t('address')}
            placeholder={t('address')}
            value={street}
            onChangeValue={setStreet}
            disabled
            required
          />
          <FormField
            label={t('neighborhood')}
            placeholder={t('neighborhood')}
            value={neighborhood}
            onChangeValue={setNeighborhood}
            disabled
            required
          />
          <FormField
            label={t('city')}
            placeholder={t('city')}
            value={city}
            onChangeValue={setCity}
            disabled
            required
          />
          <FormField
            label={t('state')}
            placeholder={t('state')}
            value={state}
            onChangeValue={setState}
            disabled
            required
          />
          <FormField
            label={t('status')}
            placeholder={t('status')}
            value={active ? 'Ativo' : 'Inativo'}
            disabled
          />

          {/* <Line align={'center'} style={{marginTop: '2rem'}}>
            <Button color={Colors.primaryPurple}>{t('change_data')}</Button>
          </Line> */}
        </form>
      </View>
    </View>
  );
}