import styled from 'styled-components';

export const BarContainer = styled.div`
  display: flex;
  padding: 1rem 0;
  align-items: center;
`;

export const BarFlexContainer = styled.div`
  margin-right: 1rem;
  :last-of-type {
    flex: 1;
  }
`;

export const BarLabel = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  width: 100px;
  color: #485465;
  margin-right: 1rem;
`;

export const BarInfo = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-size: 15px;
`;

export const BarPrice = styled.div`
  font-family: Nunito Sans;
  display: inline;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #485465;
`;

export const Bar = styled.div`
  height: 12px;
  width: 100%;
  position:relative;
  :after {
    content: '';
    background: ${props => props.color || '#EEEDED'};
    opacity: ${props => props.color && '50%' || '100%'};
    height: 100%;
    width: 100%;
    border-radius: 7px;
    position:absolute;
    top:0;
    left:0;
    opacity:0.7;
  }
`;

export const BarProgress = styled.div`
  background: ${props => props.color};
  border-radius: 7px;
  height: 12px;
  width: ${props => props.percent}%;
`;