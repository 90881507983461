import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Box } from "../../components/Box";
import DataTable from "../../components/DataTable";
import TextBox from "../../components/TextBox";
import { getLeaderboard } from "../../services/Gamification";
import { DropdownSelect } from "../../components/DropdownSelect/styles";

import first_place_badge from "../../assets/images/gamification/first_place_badge.png";
import second_place_badge from "../../assets/images/gamification/second_place_badge.png";
import third_place_badge from "../../assets/images/gamification/third_place_badge.png";

import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

import List from "../../services/List";

import {
  TextContainer,
  UserContainer,
  UserName,
  UserPic,
} from "../Users/styles";

import {
  PointsText,
  PointsSecondText,
  PointsContainer,
  BadgeImg,
  RankText,
  UserAvatar,
} from "./styles";

import { Text } from "../../components/BaseComponents/styles";

const rankingBagdes = {
  1: first_place_badge,
  2: second_place_badge,
  3: third_place_badge,
};

const periodState = [
  "Total",
  "Semana atual",
  "Mês atual",
  "Semana passada",
  "Mês passado",
];

const periodEnum = {
  Total: "",
  "Semana atual": "week",
  "Mês atual": "month",
  "Mês passado": "lastMonth",
  "Semana passada": "lastWeek",
};

const renderUserNameWithProfilePicCol = (row) => {
  const rowFormated = { ...row, key: row.id };
  console.log("row", row);

  const userPicture = row.user_picture;
  return (
    <UserContainer>
      {userPicture ? (
        <UserAvatar src={userPicture} />
      ) : (
        <UserPic user={rowFormated} />
      )}
      <TextContainer>
        <UserName>{rowFormated?.name}</UserName>
      </TextContainer>
    </UserContainer>
  );
};

const renderPointsRow = (row) => {
  return (
    <PointsContainer>
      <PointsText>{row.points}</PointsText>
      <PointsSecondText>Pontos</PointsSecondText>
    </PointsContainer>
  );
};

const renderRanking = (row) => {
  if (row.rank > 3) return <RankText>{row.rank}°</RankText>;
  return <BadgeImg src={rankingBagdes[row.rank]} />;
};

export const LeaderBoard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [period, setPeriod] = useState("");

  const [setOptions] = useOutletContext();

  const { t } = useTranslation("common");

  useEffect(() => {
    getLeaderboardData("");
  }, []);

  console.log("leaderboardData", leaderboardData);

  useEffect(() => {
    setOptions({
      start: (
        <Text
          color={"white"}
          weight={700}
          style={{ textDecoration: "underline" }}
        >
          {t("top-user-actives")}
        </Text>
      ),
      middle: (
        <DropdownSelect
          onChange={(e) => onChangePeriod(e.currentTarget.value)}
          value={period}
        >
          {periodState.map((periods) => {
            return (
              <option key={periods} value={periods}>
                {periods}
              </option>
            );
          })}
        </DropdownSelect>
      ),
    });

    return () => setOptions(null);
  }, [period]);

  const onChangePeriod = async (value) => {
    try {
      setPeriod(value);
      console.log(periodEnum[value]);
      getLeaderboardData(periodEnum[value]);
    } catch (error) {}
  };

  const getLeaderboardData = async (period) => {
    try {
      const data = await getLeaderboard(period);
      formatLeaderboardData(data);
    } catch (error) {}
  };

  const formatLeaderboardData = (data) => {
    const formatedDate = data.map((item, index) => {
      return {
        ...item,
        name: item.name.substring(0, 16) + ".",
        rank: index + 1,
      };
    });

    setLeaderboardData(formatedDate);
  };

  return (
    <>
      <Box noPadding={true} noHeader>
        <DataTable
          collumns={[
            {
              title: "Posição",
              key: "rank",
              editable: false,
              field: TextBox,
              render: renderRanking,
            },
            {
              title: "Nome",
              key: "name",
              editable: false,
              field: TextBox,
              render: renderUserNameWithProfilePicCol,
            },
            {
              title: "CPF",
              key: "cpf",
              editable: false,
              field: TextBox,
            },
            {
              title: `Pontuação`,
              key: "points",
              editable: false,
              field: TextBox,
              render: renderPointsRow,
            },
          ]}
          data={leaderboardData}
          defaultActions={false}
          withPagination={false}
          isLeaderBoard={true}
          defaultActionNewStyle
        />
      </Box>
    </>
  );
};
