import React from 'react';
import { Container } from './styles';
import Manu from '../../../../assets/images/manu.png';


const Header = () => {

  return (

    <Container>
      <div className="manu-circle">
        <img src={Manu} />
      </div>
      <span style={{marginLeft: 16, color: 'white', fontWeight: 700}}>Manu</span>
    </Container>

  );

}


export default Header;