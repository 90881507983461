import React from 'react';
import { Spinner } from 'react-activity';
import { Colors } from '../../values/colors';
import { Container } from './styles';

export const LoadingOver = () => {

  return (
    <Container>
      <Spinner color={Colors.primaryPurple} style={{marginTop: 200}} size={24}/>
    </Container>
  );

}
