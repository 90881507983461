import { useEffect, useRef, useState } from "react"
import Image from "../../../components/Image";
import RadioCheck from "../../../assets/images/check_icon.svg";
import { Spinner } from "react-activity"
import { Plus, X } from "react-feather"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ActionButton } from "../../../components/ActionButton"
import { FlatButton, Input, Paragraph, Quote, Text, TextButton, Title, View, ToggleContainer, Label, ToggleWrapper, ToggleSlider, HiddenCheckbox } from "../../../components/BaseComponents/styles"
import { Table, TBody, THead } from "../../../components/DataTable/styles"
import { Line } from "../../../components/Line/styles"
import { Modal } from "../../../components/Modal"
import TextBox from "../../../components/TextBox"
import Employees from "../../../services/Employees"
import { Colors } from "../../../values/colors"

const userModel = {
  id: 0,
  name: '',
  email: '',
  cpf: '',
  sector: '',
  subsector: '',
  business_unit: ''
}

const dependentModel = {
  id: 0,
  name: '',
  email: '',
  cpf: '',
  sector: '',
  subsector: '',
  business_unit: '',
  phone: '',
  parent_id: null,
  dependency_type: ''
}

export const AddUserModal = ({ open, onClose, onAddUser, data = {} }) => {

  const { t } = useTranslation('common');

  const [userList, setUserList] = useState([userModel]);
  const [importCsvModalVisible, setImportCsvModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  const fileUpload = useRef();

  useEffect(() => {
    setSendEmail(true);
    !isChecked ? setUserList([userModel]) : setUserList([dependentModel])
  }, [open, isChecked]);

  const renderRows = () => {
    return (userList.map((item, index) =>
      <tr key={open && item.id}>
        <td>
          <TextBox containerStyle={{ padding: 0 }} required className="field-input" value={userList[index]?.name || ''} onValueChange={(e) => handleChange('name', e, index)} />
        </td>
        <td>
          <TextBox containerStyle={{ padding: 0 }} type={'email'} className="field-input" value={userList[index]?.email || ''} onValueChange={(e) => handleChange('email', e, index)} />
        </td>
        <td>
          <TextBox containerStyle={{ padding: 0 }} className="field-input" value={userList[index]?.cpf || ''} onValueChange={(e) => handleChange('cpf', e, index)} />
        </td>
        <td>
          <TextBox containerStyle={{ padding: 0 }} className="field-input" value={userList[index]?.phone || ''} onValueChange={(e) => handleChange('phone', e, index)} />
        </td>
        <td>
          <TextBox containerStyle={{ padding: 0 }} className="field-input" value={userList[index]?.sector || ''} onValueChange={(e) => handleChange('sector', e, index)} options={data.sectors || null} />
        </td>
        <td>
          <TextBox containerStyle={{ padding: 0 }} className="field-input" value={userList[index]?.subsector || ''} onValueChange={(e) => handleChange('subsector', e, index)} />
        </td>
        <td>
          <TextBox containerStyle={{ padding: 0 }} className="field-input" value={userList[index]?.business_unit || ''} onValueChange={(e) => handleChange('business_unit', e, index)} options={data.businessUnits || null} />
        </td>
        <td>
          {(index > 0 || userList.length > 1) && <X onClick={() => removeRow(index)} />}
        </td>
      </tr>
    ));
  }
  const renderRowsDependent = () => {
    return (userList.map((item, index) =>
      <tr key={open && item.id}>
        <td>
          <TextBox containerStyle={{ padding: 0 }} required className="field-input" value={userList[index]?.name || ''} onValueChange={(e) => handleChange('name', e, index)} />
        </td>
        <td>
          <TextBox containerStyle={{ padding: 0 }} type={'email'} className="field-input" value={userList[index]?.email || ''} onValueChange={(e) => handleChange('email', e, index)} />
        </td>
        <td>
          <TextBox containerStyle={{ padding: 0 }} className="field-input" value={userList[index]?.cpf || ''} onValueChange={(e) => handleChange('cpf', e, index)} />
        </td>
        <td>
          <TextBox containerStyle={{ padding: 0 }} className="field-input" value={userList[index]?.phone || ''} onValueChange={(e) => handleChange('phone', e, index)} />
        </td>
        <td>
          <select
            style={{
              padding: '0.7rem',
              boxShadow: '0 0 0 1px #6244EB',
              outlineColor: '#6244EB',
            }}
            value={userList[index]?.dependency_type || ''}
            onChange={(e) => handleChange('dependency_type', e.target.value, index)}
            className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
          >
            <option value="">Selecione</option>
            <option value="Cônjuge">Cônjuge</option>
            <option value="Filho">Filho</option>
          </select>
        </td>
        <td>
          <TextBox containerStyle={{ padding: 0 }} className="field-input" value={userList[index]?.parent_id || ''} onValueChange={(e) => handleChange('parent_id', e, index)} />
        </td>
        <td>
          {(index > 0 || userList.length > 1) && <X onClick={() => removeRow(index)} />}
        </td>
      </tr>
    ));
  }

  const handleChange = (field, value, index) => {
    setUserList(prev => {
      let _list = [...prev];
      _list[index] = { ..._list[index], [field]: value };
      return _list;
    });
  }

  const addRow = () => {
    setUserList(prev => [...prev, { ...userModel, id: (prev[prev.length - 1] ? prev[prev.length - 1].id : 0) + 1 }]);
  }

  const removeRow = (index) => {
    setUserList(prev => {
      let _list = [...prev];
      _list.splice(index, 1);
      return _list;
    });
  }

  const optionsMap = {
    "Cônjuge": "spouse",
    "Filho": "son"
  };

  const saveData = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    const updatedUserList = userList.map(item => ({
      ...item,
      parent_id: isChecked ? item?.parent_id : null,
      dependency_type: isChecked ? (optionsMap[item.dependency_type] || item.dependency_type) : null
    }));

    try {
      const response = await Employees.import({
        employees: updatedUserList,
        sendInvite: sendEmail
      });
      if (response.success) {
        toast.success(isChecked ? 'Dependentes adicionados com sucesso!' : 'Usuários adicionados com sucesso!');
        onClose();
        onAddUser && onAddUser();
        setLoading(false);
        setUserList([userModel]);
      } else {
        toast.error(isChecked ? 'Erro ao adicionar dependentes, verifique seu arquivo e tente novamente!' : 'Erro ao adicionar usuários, verifique seu arquivo e tente novamente!');
      }
    } catch (e) {
      console.log(e);
      toast.error(isChecked ? 'Erro ao adicionar dependentes, verifique seu arquivo e tente novamente!' : 'Erro ao adicionar usuários, verifique seu arquivo e tente novamente!');
      setLoading(false);
    }

  }

  const handleSelectFile = () => {
    fileUpload.current.click();
  }

  const handleUploadCsvFile = async (event) => {
    setLoadingUpload(true);
    const file = event.target.files[0];
    if (!file) {
      toast.error('Erro ao ler dados, verifique seu arquivo e tente novamente.');
    }

    let formData = new FormData();
    formData.append("csv", file);
    const response = await Employees.parseCsv(formData);
    if (response.success) {
      setUserList(prev => response.users.map((u, i) => ({ ...u, id: prev[prev.length - 1].id + i + 1 })));
      setImportCsvModalVisible(false);
      toast.success('Lista carregada com sucesso!');

    } else {
      toast.error('Erro ao ler dados, verifique seu arquivo e tente novamente.');
    }
    setLoadingUpload(false);
    fileUpload.current.value = null;
  }

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <Modal containerStyle={{ width: 'unset', padding: '1rem' }} title={t('add_users')} open={open} onClose={onClose}>
        <form onSubmit={saveData}>
        {!isChecked ? (<>
          <Text size={14} color={'red'}>{'• Usuários que não contenham nem e-mail e nem CPF serão descartados.'}</Text>
          <View style={{ overflow: 'auto', maxHeight: 400 }}>
            <Table className="no-border">
              <THead>
                <tr>
                  <th>
                    Nome *
                  </th>
                  <th>
                    E-mail
                  </th>
                  <th>
                    CPF
                  </th>
                  <th>
                    Celular
                  </th>
                  <th>
                    Setor
                  </th>
                  <th>
                    Subsetor
                  </th>
                  <th>
                    Unidade de negócio
                  </th>
                  <th>
                  </th>
                </tr>
              </THead>
              <TBody>
                {renderRows()}
              </TBody>
            </Table>


          </View>
        </>) : (<>
          <View style={{ overflow: 'auto', maxHeight: 400 }}>
            <Table className="no-border">
              <THead>
                <tr>
                  <th>
                    Nome *
                  </th>
                  <th>
                    E-mail
                  </th>
                  <th>
                    CPF
                  </th>
                  <th>
                    Celular
                  </th>
                  <th>
                    Tipo de relacionamento
                  </th>
                  <th>
                    ID do titular
                  </th>
                  <th>
                  </th>
                </tr>
              </THead>
              <TBody>
                {renderRowsDependent()}
              </TBody>
            </Table>
          </View>
        </>)}

          <Line align={'space-between'} style={{ marginTop: '1rem' }}>
            <TextButton onClick={addRow} style={{ fontWeight: 800, fontSize: '18px', color: Colors.cadetBlue }}>+ {t('add_more_user')}</TextButton>
            <TextButton onClick={() => setImportCsvModalVisible(true)} style={{ fontWeight: 800, fontSize: '18px', color: Colors.cadetBlue }}>+ {t('import_via_csv')}</TextButton>
          </Line>

          <Line align={'space-between'} style={{ marginTop: '2rem', marginBottom: '1rem' }}>
            <TextButton style={{ marginTop: 10 }}>
              <Line onClick={(e) => setSendEmail(!sendEmail)}>
                <Input type="checkbox" color={Colors.primaryPurple} size={5} value="1" onChange={(e) => setSendEmail(!sendEmail)} checked={sendEmail} />
                <Text color={Colors.primaryColor} weight={700} style={{ marginLeft: 5 }}>Enviar email de convite</Text>
              </Line>
            </TextButton>
            <ActionButton isLoading={loading}>{t('add')}</ActionButton>
          </Line>

        </form>


      </Modal>
      {data.dependent_enabled === null ? (<>
        <Modal title={t('add_user_by_csv')} open={importCsvModalVisible} onClose={() => setImportCsvModalVisible(false)}>
          <Title style={{ fontSize: 16 }}>O arquivo deve estar de acordo com as regras abaixo</Title>

          <View style={{ marginTop: '1rem' }}>
            <Paragraph style={{ color: '#485465', fontWeight: 700, display: "flex", alignItems: "flex-start" }}>
            <Image src={RadioCheck} width={40} height={20} /> O cabeçalho (primeira linha) deve incluir os nomes das colunas separadas por vírgula
            </Paragraph>
            <Quote size={14} weight={700}>
              Nome,Email,Cpf,Celular,Setor,Sub Setor,Unidade de Negocios
            </Quote>

            <Paragraph style={{ marginTop: '1rem', color: '#485465', fontWeight: 700, display: "flex", alignItems: "flex-start" }}>
            <Image src={RadioCheck} width={40} height={20} /> O cabeçalho e as linhas seguintes devem ter a mesma quantidade de valores
            </Paragraph>
            <Quote size={14} weight={700}>
              Nome,Email,Cpf,Celular,Setor,Sub Setor,Unidade de Negocios<br />
              Funcionário 1,funcionario1@suaempresa.com,cpf,celular,setor,sub setor,unidade<br />
              Funcionário 2,funcionario1@suaempresa.com,cpf,celular,setor,sub setor,unidade
            </Quote>

            <Line align={'center'} style={{ margin: '2rem 0 1rem 0', justifyContent: 'flex-end' }}>
              {loadingUpload ?
                <Spinner color={Colors.primaryPurple} />
                :
                <FlatButton style={{ display: 'flex', borderRadius: 50 ,backgroundColor: Colors.primaryPurple, justifyContent: 'center', alignItems: 'center', fontSize: '1rem', height: 40, padding: '0 2rem' }} onClick={handleSelectFile}>
                  <input type="file" ref={fileUpload} onChange={handleUploadCsvFile} accept=".csv" style={{ display: 'none', position: 'absolute' }} />
                  <Plus style={{ padding: 4, borderRadius: 4 }} size={20} color={'white'} />
                  <Text style={{ marginLeft: 10, fontSize: '1rem', color: 'white' }} weight={700} >{t('select_a_file')}</Text>
                </FlatButton>
              }
            </Line>
          </View>
        </Modal>
      </>) : (<>
        <Modal title={t('add_user_by_csv')} open={importCsvModalVisible} onClose={() => setImportCsvModalVisible(false)}>
          <Title style={{ fontSize: 16, color: '#8492A6', fontWeight: 400, marginBottom: 15 }}>Escolha o tipo de usuários que deseja subir</Title>
          <ToggleContainer>
            <Label active={!isChecked}>Titular</Label>
            <ToggleWrapper onClick={handleToggle}>
              <HiddenCheckbox checked={isChecked} readOnly />
              <ToggleSlider checked={isChecked} />
            </ToggleWrapper>
            <Label active={isChecked}>Dependente</Label>
          </ToggleContainer>
          {!isChecked ? (<>
            <Title style={{ fontSize: 16, color: '#485465', marginBottom: 15, marginTop: 15 }}>Usuário titular da conta</Title>
            <Title style={{ fontSize: 16, color: '#8492A6', fontWeight: 400, marginBottom: 15, marginTop: 15 }}>Para enviar usuários titulares, o arquivo deve estar de acordo com as regras abaixo:</Title>
          </>) : (<>
            <Title style={{ fontSize: 16, color: '#485465', marginBottom: 15, marginTop: 15 }}>Usuário dependente da conta</Title>
            <Title style={{ fontSize: 16, color: '#8492A6', fontWeight: 400, marginBottom: 15, marginTop: 15 }}>Para enviar usuários dependentes, o arquivo deve estar de acordo com as regras abaixo:</Title>
          </>) }
          <Title style={{ fontSize: 16 }}>O arquivo deve estar de acordo com as regras abaixo:</Title>

          <View style={{ marginTop: '1rem' }}>
            {!isChecked ? (
              <>
                <Paragraph style={{ color: '#485465', fontWeight: 700, display: "flex", alignItems: "flex-start" }}>
                <Image src={RadioCheck} width={40} height={20} /> O cabeçalho (primeira linha) deve incluir os nomes das colunas separadas por vírgula
                </Paragraph>
                <Quote size={14} weight={700}>
                  Nome,Email,Cpf,Celular,Setor,Sub Setor,Unidade de Negocios
                </Quote>

                <Paragraph style={{ marginTop: '1rem', color: '#485465', fontWeight: 700, display: "flex", alignItems: "flex-start" }}>
                <Image src={RadioCheck} width={40} height={20} /> O cabeçalho e as linhas seguintes devem ter a mesma quantidade de valores
                </Paragraph>
                <Quote size={14} weight={700}>
                  Nome,Email,Cpf,Celular,Setor,Sub Setor,Unidade de Negocios<br />
                  Funcionário 1,funcionario1@suaempresa.com,cpf,celular,setor,sub setor,unidade<br />
                  Funcionário 2,funcionario1@suaempresa.com,cpf,celular,setor,sub setor,unidade
                </Quote>
            </>
          ) : (
            <>
              <Paragraph style={{ color: '#485465', fontWeight: 700, display: "flex", alignItems: "flex-start" }}>
              <Image src={RadioCheck} width={40} height={20} /> O cabecalho (primeira linha) deve incluir os nomes das colunas separadas por vírgula
              </Paragraph>
              <Quote size={14} weight={700}>
              Nome,Email,Cpf,Celular,Tipo de relacionamento,ID do titular
              </Quote>

              <Paragraph style={{ marginTop: '1rem', color: '#485465', fontWeight: 700, display: "flex", alignItems: "flex-start" }}>
              <Image src={RadioCheck} width={40} height={20} /> O cabecalho e as linhas seguintes devem ter a mesma quantidade de valores
              </Paragraph>
              <Quote size={14} weight={700}>
                Nome,Email,Cpf,Celular,Tipo de relacionamento,ID do titular<br />
                Dependente 1,dependente1@email.com,cpf,celular,Tipo de relacionamento,ID do titular 1<br />
                Dependente 2,dependente2@email.com,cpf,celular,Tipo de relacionamento,ID do titular 2
              </Quote>
            </>
          )}

            <Line align={'center'} style={{ margin: '2rem 0 1rem 0', justifyContent: 'end' }}>
              {loadingUpload ?
                <Spinner color={Colors.primaryPurple} />
                :
                <FlatButton style={{ display: 'flex', borderRadius: 50 ,backgroundColor: Colors.primaryPurple, justifyContent: 'center', alignItems: 'center', fontSize: '1rem', height: 40, padding: '0 2rem' }} onClick={handleSelectFile}>
                  <input type="file" ref={fileUpload} onChange={handleUploadCsvFile} accept=".csv" style={{ display: 'none', position: 'absolute' }} />
                  <Plus style={{ padding: 4, borderRadius: 4 }} size={20} color={'white'} />
                  <Text style={{ marginLeft: 10, fontSize: '1rem', color: 'white' }} weight={700} >{t('select_a_file')}</Text>
                </FlatButton>
              }
            </Line>
          </View>
        </Modal>
      </>)}

    </>
  );

}