import styled from 'styled-components';

export const Background = styled.div`
    background-image: url(${props => props.src});
    background-size: cover;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -2;

    // @media(max-width: 1124px) AND (min-width: 680px){
    //     width: auto;
    //     height: 100vh;
    // }

    @media(max-width: 680px) {
        display: none;
    }
`;
