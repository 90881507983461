/**
 * @typedef {Object} Base
 * @prop {string} name
 * @prop {string} sector
 * @prop {string} subsector
 * @prop {string} business_unit
 */

/**
 * @typedef {Object} GetBase
 * @prop {number} id
 * @prop {string} email
 * @prop {string} score
 * @prop {number} namu_id
 * @prop {string} picture
 */

/**
 * @typedef {Object} PostBase
 * @prop {string} email
 */

/**
 * @typedef {Object} PutBase
 * @prop {number} id
 */

/**
 * @typedef {Base & GetBase} EmployeeGet
 * @typedef {Base & PostBase} EmployeePost
 *  * @typedef {Base & PutBase} EmployeePut
 */

/**
 * @typedef EmployeeGet
 * @prop {boolean} success
 * @prop {number} employee_count
 * @prop {EmployeeGet[]} employees
 */

/**
 * @typedef EmployeeImport
 * @prop {EmployeePost[]} employees
 */

/**
 * @typedef NewestEmployee
 * @prop {number} id
 * @prop {string} name
 * @prop {string} picture
 */

/**
 * @typedef EmployeeStats
 * @prop {number} total
 * @prop {number} active
 * @prop {number} pending
 */

import API from '../api';

export default class {
  /**
   * @param {string} sector
   * @param {string} business_unit
   * @returns {Promise<EmployeeGet>}
   */
  static async get (sector, business_unit, q, page, perPage) {
    const res = await API.get('users', {
      params: {
        sector,
        business_unit,
        page,
        q,
        perPage
      }
    });
    return res.data;
  }

  /**
   * @returns {Promise<>}
   */
  static async getStats () {
    const res = await API.get('users/stats');
    return res?.data?.stats;
  }

  /**
   * @returns {Promise<NewestEmployee[]>}
   */
  static async getNewest () {
    const res = await API.get('users/newest');
    return res.data;
  }

  /**
   * @param {EmployeeImport} data
   */
  static async import (data) {
    const res = await API.post('users', data);
    return res.data;
  }

  /**
   * @param {EmployeeImport} data
   */
  static async parseCsv (formData) {
    const res = await API.post('parseusers', formData, { headers: { 'Content-Type': 'multipart/form-data' }});
    return res.data;
  }

  /**
   * @param {EmployeePost} data
   */
  static async create (data) {
    const res = await API.post('user', data);
    return res.data;
  }

  /**
   * @param {EmployeePut} data
   */
  static async update (data) {
    const res = await API.put('user', data);
    return res.data;
  }

  static async delete (id) {
    const res = await API.delete(`user/${id}`);
    return res.data;
  }
  
  static async resendInvites ({id}) {
    const res = await API.post(`users/reinvite${id ? '/'+id : ''}`);
    return res;
  }
  
  static async exportAll (sector, business_unit, format) {
    const res = await API.get(`users/${format}`, {
      params: {
        sector,
        business_unit,
        format
      }
    })
  }

  static async associate (token) {
    const response = await API.post(`/associate/${token}`);
    return response;
  }

  static async downloadCsv (bussines_unit, sector) {
    const res = await API.get(`/users/csv?business_unit=${bussines_unit}&sector=${sector}`, { responseType: 'blob' });
    return res;
  }

  static async downloadPdf (bussines_unit, sector) {
    const res = await API.get(`/users/pdf?business_unit=${bussines_unit}&sector=${sector}`, { responseType: 'blob' });
    return res;
  }

}