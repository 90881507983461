import Image from "../../../components/Image";
import LogoFullWhite from "../../../assets/images/logo_full_white.svg";
import { Container } from "./styles";
import { FlatButton, Input, Text, Title, View } from "../../../components/BaseComponents/styles";
import { Colors } from "../../../values/colors";
import { useTranslation } from "react-i18next";
import { BackgroundCircle } from "../../../components/BackgroundCircle";
import { FormFieldBox } from "../../../components/FormFieldBox";
import { Eye, EyeOff } from "react-feather";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { TextLink } from "../../../components/TextLink";
import { Line } from "../../../components/Line/styles";
import { useEffect, useState } from "react";
import { ActionButton } from "../../../components/ActionButton";
import Auth from "../../../services/Auth";
import { ErrorMessage, SuccessMessage } from '../../../utils/alerts';
import { useQuery } from "../../../hooks";
import TranslateError from "../../../utils/error";
import Employees from "../../../services/Employees";

const STEPS = {
  INVITE: 0,
  REJECTED: 1,
  ACCEPTED: 2
}

export default () => {
  const navigate = useNavigate();
  const [ params ] = useSearchParams();

  const  [ t, i18n ] = useTranslation('common');

  const [ currentStep, setCurrentStep ] = useState(STEPS.INVITE);
  const [ token, setToken ] = useState(params.get('token'));
  const [ isLoading, setIsLoading ] = useState(false);

  const location = useLocation();

  const acceptInvite = async () => {
    setIsLoading(true);
    try{
      const { data } = await Employees.associate(token);
      if(data.success){
        setCurrentStep(STEPS.ACCEPTED);
      }else{
        if(data.error){
          let errorMessage = TranslateError(data.error);
          ErrorMessage({message: errorMessage});
        }
      }
    }catch(e){
      console.log(e);
      ErrorMessage({message: 'Erro ao aceitar convite'});
    }
    setIsLoading(false);
  }


 
  return (
    !Auth.signedIn ?
    <Container>
      <View className="login-container" style={{backgroundColor: Colors.primaryBlue2}}> 
        <View style={{zIndex: 1}}>
          <Image src={LogoFullWhite}/>
          <div className="horizontal-line"></div>
          <Title className="home-text">{t('homepage_text')}</Title> 
        </View>

        <BackgroundCircle circleStyle={{zIndex: 0, background: Colors.primaryBlue, position: 'absolute'}} level={0}/>
        <BackgroundCircle circleStyle={{zIndex: 0, background: `${Colors.primaryBlue}66`, position: 'absolute'}} level={1}/>
      </View>
      <View className="login-container" style={{alignItems: 'start', textAlign: 'left'}}>

        <Title className="login-title">{t('accept_invite')}</Title>

          {currentStep === STEPS.INVITE &&
            (!token ? 
            <>
              <Title style={{fontSize: '1.5rem', fontWeight: 600}}>{'Não conseguimos identificar o seu convite Namu Business'}</Title>
              <Line align={'space-between'} style={{marginTop: '3rem'}}>
                <TextLink color={Colors.red} to="/">{t('go_to_home')}</TextLink>
              </Line>
            </>
            :
            <>
              <Title style={{fontSize: '1.5rem', fontWeight: 600}}>{'Você foi convidado para participar do Namu Business'}</Title>
              <Line align={'space-between'} style={{marginTop: '3rem'}}>
                <FlatButton style={{color: Colors.red, fontWeight: 700, fontSize: 16, marginRight: '1rem'}} onClick={()=>setCurrentStep(STEPS.REJECTED)}>{t('refuse')}</FlatButton>
                <ActionButton isLoading={isLoading} onClick={acceptInvite}>
                  {t('accept')}
                </ActionButton>
              </Line>
            </>)
          }

          {currentStep === STEPS.REJECTED &&
            <>
              <Title style={{fontSize: '1.5rem', fontWeight: 600}}>{'Você não aceitou o convite para participar do Namu Business'}</Title>
              <Line align={'space-between'} style={{marginTop: '3rem'}}>
                <TextLink color={Colors.red} to="/">{t('go_to_home')}</TextLink>
              </Line>
            </>
          }

      </View>
    </Container>
  : 
  <Navigate to="/" replace state={{ path: location.pathname }}/>
  );
}