import styled from "styled-components";
import { Colors } from "../../values/colors";


export const Container = styled.div`
  position: relative;
  display: flex;

  .search-button{
    color: ${Colors.mediumGray};
    transition: color .2s ease-in-out;
  }

  &.show {
    .search-field{
      width: 200px;
      opacity: 1;
      transition: width .3s ease-in-out, opacity .3s ease-in-out;
    }

    .search-button{
      color: ${Colors.successGreen};
    }
  }

  .search-field {
    width: 0px;
    border: 1px solid ${Colors.ultraLightGray};
    border-radius: 4px;
    padding: 5px;
    overflow: hidden;
    opacity: 0;

    transition: width .3s ease-in-out, opacity .3s ease-in-out;
    margin-right: 5px;
  }

`;

export const SearchInput = styled.input`
  outline: none;
  border: none;
  margin-left: 5px;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
`;