import { useEffect } from "react";
import { useState, createContext } from "react";

export const CompaniesManagementContext = createContext({})

export const CompaniesManagementProvider = ({children}) => {
  const [data, setData] = useState({})

  useEffect(()=> {
    const values = JSON.parse(localStorage.getItem('companiesData'))
    if(values) setData(values)
  }, [])
 
  const setDataValue = data => { 
    setData(data)
    localStorage.setItem('companiesData', JSON.stringify(data))
  }
  
  return (
    <CompaniesManagementContext.Provider value={{
      data,
      setDataValue
    }}>
      {children}
    </CompaniesManagementContext.Provider>
  )
}