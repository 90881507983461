import React, { useState } from 'react';
import { Info, Send } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { FlatButton, Text, Title } from '../../../../components/BaseComponents/styles';
import { Button } from '../../../../components/Button';
import { Line } from '../../../../components/Line/styles';
import { Modal } from '../../../../components/Modal';
import { Colors } from '../../../../values/colors';
import { Container } from './styles';


const SendContainer = ({ onSubmit }) => {

  const [ message, setMessage ] = useState('');
  const [ useConditionsModal, setUseConditionsModal ] = useState(false);
  const { t } = useTranslation('common');
  const submit = (e) => {
    e.preventDefault()
    if(message.trim() == ''){
      return;
    }
    onSubmit(message)
    setMessage('')
  }



  return (
    <>
    <Container onSubmit={submit}>
        <Line className="message-container" align="space-between">
          <input type="text" className="message" value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Digite uma mensagem...'/>
          <Info onClick={()=>setUseConditionsModal(true)} size={20} color={Colors.mediumGray}/>
        </Line>
        <FlatButton type="submit" className="circle-button" color={Colors.defaultBlue}>
          <Send color="white" style={{marginLeft: -2, marginBottom: -2}} size="22"/>
        </FlatButton>
    </Container>
    <Modal disableHeader bodyStyle={{padding: '1.5rem'}} open={useConditionsModal}>
        <Title style={{textAlign: 'center', marginBottom: 15}}>{t('use_conditions')}</Title>
        <div style={{textAlign: 'center', width: '100%'}}>
          <Text style={{ textAlign: 'center', width: '70%', marginTop: '1rem' }}>
            {'Ao utilizar a Manu + ChatGPT como seu assistente virtual, é importante lembrar que as respostas fornecidas podem não ser precisas ou relevantes para a sua situação específica. Sendo assim, é aconselhável utilizar o Chatbot como uma ferramenta de auxílio e não como um substituto da interação humana. Caso necessite de ajuda adicional, fique à vontade para entrar em contato com nosso suporte. Além disso, ressaltamos que o desempenho do Chatbot é monitorado regularmente para garantir a melhor assistência possível.'}
            </Text>
        </div>
        <Line align={'center'} style={{ marginTop: '1.5rem' }}>
          <Button rounded onClick={()=>setUseConditionsModal(false)} color={Colors.primaryBlue2} style={{height: 'auto', fontSize: 16}}>{'Ok'}</Button>
        </Line>
      </Modal>
    </>
  );

}


export default SendContainer;