import Image from "../Image";
import { Backdrop, Container, DetailContainer, LineButton } from "./styles"
import { Line } from "../Line/styles";
import DefaultImage from "../../assets/images/profile_business.jpg";
import { ChevronRight, Link as LinkIcon, Lock } from "react-feather";
import { Colors } from "../../values/colors";
import { TextButton, Title, View } from "../BaseComponents/styles";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { MyData } from "./Details/MyData";
import { AdministrativeUsers } from "./Details/AdministrativeUsers";
import { ChangePass } from "./Details/ChangePass";
import { Terms } from "./Details/Terms";
import { Suggestions } from "./Details/Suggestions";
import Auth from "../../services/Auth";
import { BusinessList } from "./Details/BusinessList";
import { usePermissions } from "../../hooks";

export const SideBar = ({ visible, onClose }) => {

	const [ detailToShow, setDetailToShow ] = useState(null);
	const [ t, i18n ] = useTranslation('common');
	const [ showScreen, setShowScreen ] = useState(false);
	const { checkPermissions } = usePermissions();

	const renderDetail = (show) => {
		switch(detailToShow){
			case 'my_data': return <MyData onClose={closeDetail} show={show}/>;
			case 'admins': return <AdministrativeUsers onClose={closeDetail} show={show}/>;
			case 'change_pass': return <ChangePass onClose={closeDetail}/>;
			case 'terms': return <Terms onClose={closeDetail}/>;
			case 'help': return <MyData onClose={closeDetail}/>;
			case 'suggestions': return <Suggestions onClose={closeDetail}/>;
			case 'switch_business': return <BusinessList show={show} onClose={closeDetail} />;
			default: return null;
		}
	}

	const closeDetail = () => {
		setShowScreen(false);
	}

	const showDetail = (detail) => {
		setDetailToShow(detail)
		setShowScreen(true);
	}

	return (visible &&
		<Backdrop onMouseDown={onClose}>

			<Container onMouseDown={e => e.stopPropagation()}>
				<Line align={'space-between'}>
					<Image src={DefaultImage} style={{borderRadius: '100%'}} width={48} height={48} />
					<TextButton>
						<ChevronRight color={Colors.lightGray} onClick={onClose} />
					</TextButton>
				</Line>

				<Title style={{ marginTop: '2rem' }}>{t('general_configs')}</Title>

				<View>
					<LineButton onClick={()=>showDetail('my_data')}>
						<Line align={'space-between'}>
							{t('my_data')}
							<ChevronRight size={20} color={Colors.lightGray}/>
						</Line>
					</LineButton>
					{checkPermissions('sidebar:administrativeusers') && <LineButton onClick={()=>showDetail('admins')}>
						<Line align={'space-between'}>
							{t('administrative_users')}
							<Line>
								<LinkIcon size={20} color={Colors.lightGray}/>
								<ChevronRight size={20} color={Colors.lightGray}/>
							</Line>
						</Line>
					</LineButton>}
					<LineButton onClick={()=>showDetail('change_pass')}>
						<Line align={'space-between'}>
							{t('change_pass')}
							<ChevronRight size={20} color={Colors.lightGray}/>
						</Line>
					</LineButton>
				</View>

				<Title style={{ marginTop: '2rem' }}>{t('general_infos')}</Title>

				<View>
					<LineButton onClick={()=>showDetail('terms')}>
						<Line align={'space-between'}>
							{t('terms_and_privacy')}
							<Line>
								<Lock size={20} color={Colors.lightGray}/>
								<ChevronRight size={20} color={Colors.lightGray}/>
							</Line>
						</Line>
					</LineButton>
					{/* <LineButton>
						<Line align={'space-between'}>
							{t('help')}
							<Line>
								<HelpCircle size={20} color={Colors.lightGray}/>
								<ChevronRight size={20} color={Colors.lightGray}/>
							</Line>
						</Line>
					</LineButton> */}
					<LineButton onClick={()=>showDetail('suggestions')}>
						<Line align={'space-between'}>
							{t('suggestions')}
							<ChevronRight size={20} color={Colors.lightGray}/>
						</Line>
					</LineButton>
				</View>

				{checkPermissions('sidebar:switchbusiness') && <>
					<Title style={{ marginTop: '2rem' }}>{t('master')}</Title>

					<View>
						<LineButton onClick={()=>showDetail('switch_business')}>
							<Line align={'space-between'}>
								{t('switch_business')}
								<Line>
									<ChevronRight size={20} color={Colors.lightGray}/>
								</Line>
							</Line>
						</LineButton>

					</View>
				</>}
				<View margin={'1rem 0 0 0'}>
					<TextButton onClick={Auth.signOut} color={'red'}>{t('logout')}</TextButton>
				</View>
			</Container>

			<DetailContainer onMouseDown={e=>e.stopPropagation()} className={!showScreen && 'hide'}>
			 {renderDetail(showScreen)} 
			</DetailContainer>

		</Backdrop>);
}