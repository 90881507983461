import styled from "styled-components";
import { Colors } from "../../values/colors";


export const UsageContainer = styled.div`
  padding: ${props => props.padding || 0};
  margin: ${props => props.margin || 0};
  display: grid;
  column-gap: 2rem;
  grid-template-columns: auto auto;

  & > div {
    padding: 2rem 0;
  }

  & > div:not(:last-of-type){
    border-right: 1px solid #EFF2F7;
  }
  & > div:first-of-type{
    padding-left: 2rem;
  }

`;