import React, { useEffect, useState } from 'react';
import { Title } from '../../components/BaseComponents/styles';
import { Box } from '../../components/Box';
import { Background, CardBusiness, Section } from './styles';
import LogoHome from "../../assets/images/logo_full_white.svg";
import Image from '../../components/Image';
import { ChevronRight } from 'react-feather';
import { Colors } from '../../values/colors';
import DefaultImage from "../../assets/images/profile_business.jpg";
import Auth from '../../services/Auth';
import { useNavigate } from 'react-router-dom';


export default () => {
  const [ businesses, setBusinesses ] = useState([]);

  const navigate = useNavigate();
  
  const getData = async () => {
    const data = await Auth.getBusinesses();
    setBusinesses(data.list);
  }

  useEffect(() => {
    getData();
  }, []);


  const switchBusiness = async (id) => {
    await Auth.switchBusiness(id);
    navigate('/');
  }

  return (
    <Background>
      <Section>
        <Image src={LogoHome} width={'auto'} height={'80px'}/>

        <Box noHeader style={{margin: 10, marginTop: '2rem', marginBottom: '2rem'}}>
          <Title style={{textAlign: 'center'}}>
            Selecione a empresa que deseja visualizar
          </Title>
          {businesses.map(business => 
          <CardBusiness onClick={() => switchBusiness(business.id)}>
            <Image src={DefaultImage} width={36} height={36} style={{borderRadius: '100%'}}/>
            {business.title}
            <ChevronRight color={Colors.primaryColor}/>
          </CardBusiness>
          )}
        </Box>
      </Section>
    </Background>
  );

}