import { Container, Head, Body, Input, Dropdown, Item } from "./styles";
import { useEffect, useRef, useState } from 'react';

export default ({ options: _options, value, containerStyle, onValueChange, ...props}) => {
  const [ open, setOpen ] = useState(false);
  const [ inputValue, setInputValue ] = useState(value || '');
  const [ filtered, setFiltered ] = useState([]);
  const [ options, setOptions ] = useState(_options);
  const hasOptions = !!options;

  const containerRef = useRef();

  useEffect(() => {
    if (!hasOptions) return;
    const _options = [ 
      ...new Set([
        inputValue,
        ...options
      ])
    ];
    
    setFiltered(_options.filter(option => 
      option.trim().length != 0 && 
      option.includes(inputValue)
    ));
  }, [ inputValue, options ]);
  
  const onChange = (value) => {
    setInputValue(value);
    if (onValueChange) onValueChange(value);
  };

  return (
    <Container ref={containerRef} open={open && hasOptions} onFocus={() => setOpen(true)} onBlur={(e) => { setTimeout(() => setOpen(false), 250)}}>
      <Head style={containerStyle} open={open && hasOptions}>
        <Input open={open && hasOptions} type="text" onChange={(e) => {onChange(e.target.value)}} value={inputValue} {...props}/>
      </Head>
      {hasOptions &&
      <Dropdown style={containerRef.current ? {width: containerRef.current.offsetWidth } : {}} open={open}>
        {filtered.map(option => <Item key={option} onClick={(e) => {onChange(option)}}>{option}</Item>)}
      </Dropdown>}
    </Container>
  );
}