import { Colors } from "../../values/colors";
import { View } from "../BaseComponents/styles";
import { Container, SuffixIconContainer } from "./styles";

export const FormFieldBox = ({value, onChange, type = 'text', labelStyle, placeholder, label, suffixIconButton, ...props}) => {

  return (
    <Container>
      <label style={labelStyle}>{label}</label>
      <View className="input-container" style={{position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap'}}>
        <input 
          type={type} 
          {...props}
          placeholder={placeholder} 
          value={value} 
          style={{borderRight: suffixIconButton ? `1px solid ${Colors.ultraLightGray}` : 'none'}}
          onChange={onChange}
          className={suffixIconButton ? 'icon-right' : ''}
          />
        {!!suffixIconButton &&
          <SuffixIconContainer>
            {suffixIconButton}
          </SuffixIconContainer>
        }
      </View>

    </Container>
  );


}