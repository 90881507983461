import API from '../api';

/**
 * @typedef Users
 * @prop {number} registered
 * @prop {number} active
 */

/**
 * @typedef Sector
 * @prop {string} title
 * @prop {number} total_users
 * @prop {number} active_users
 */

/**
 * @typedef Category
 * @prop {string} title
 * @prop {number} active_users
 */

/**
 * @typedef Usage
 * @prop {number} period
 * @prop {number} total_users
 * @prop {number} active_users
 * @prop {number} registered_users
 * @prop {Sector[]} sectors
 * @prop {Category[]} categories
 */

/**
 * @typedef Activity
 * @prop {[]} categories
 * @prop {number} total
 */

/**
 * @typedef UsageData
 * @prop {number} period
 * @prop {number} total_users
 * @prop {number} active_users
 * @prop {number} sum_active_users
 * @prop {number} usage_rate
 * @prop {number} average_frequency
 * @prop {number} avg_interval_activity
 * @prop {number} registered_users
 */

/**
 * @typedef UsageDetails
 * @prop {Activity} activity
 * @prop {UsageData[]} usage_data
 */

/**
 * @typedef OvertimeUsage
 * @prop {number} period
 * @prop {number} active_users
 */

/**
 * @typedef DataPair
 * @prop {string} title
 * @prop {number} value 
 */

/**
 * @typedef UserProfiler
 * @prop {DataPair[]} goals
 * @prop {DataPair[]} interests
 * @prop {DataPair[]} dietaryRestrictions
 * @prop {DataPair[]} muscleRestrictions
 */

/**
 * @typedef Session
 * @prop {string} category
 * @prop {number} categoryId
 * @prop {number} sessions
 * @prop {number} max
 * @prop {number} min
 * @prop {number} avg
 */

/**
 * @typedef QuickSight
 * @prop {number} business_id
 * @prop {string} embed_url
 */

/**
 * @typedef QuickSightTabs
 * @prop {bool} tele_enabled
 * @prop {bool} sipat_enabled
 */

export default class {
  /**
   * @returns {Promise<Users>} 
   */
  static async users () {
    const { data } = await API.get('/stats/users');
    return data.stats;
  }

  /**
   * @returns {Promise<Usage[]>}
   */
  static async usage (params) {
    const { data } = await API.get('/usagedata', { params });
    return data.stats;
  }

  /** 
   * @returns {Promise<Sector[]>}
   */
  static async usagePerSector (params) {
    const { data } = await API.get('/stats/usage_per_sector', { params });
    return data.stats;
  }
  
  /**
   * @returns {Promise<UsageDetails>}
   */
  static async usageDetails (params) {
    const { data } = await API.get('/stats/usage', { params });
    return data.stats;
  }

  /**
   * @returns {Promise<OvertimeUsage[]>}
   */
  static async overtimeUsage (params, headers = {}) {
    const { data } = await API.get('/stats/over_time/usage', { params, headers });
    return data;
  }
  
  /**
   * @returns {Promise<UserProfiler>}
   */
  static async userProfiler (params) {
    const { data } = await API.get('/stats/profiler', { params });
    return data;
  }

  /**
   * @returns {Promise<Session[]>}
   */
  static async sessions () {
    const { data } = await API.get('/stats/sessions');
    return data.stats;
  }

  /**
   * @returns {Promise<QuickSight>}
   * @param {'main' | 'tele' | 'sipat'} tab
   */
  static async getQSEmbed (tab, type = 'single') {
    const { data } = await API.get(`/stats/quicksight/${tab}/${type}`);
    return data;
  }

  /**
   * @returns {Promise<QuickSightTabs>}
   */
  static async getQSTabs (type = 'single') {
    const { data } = await API.get(`/stats/quicksight/tabs/${type}`);
    return data;
  }
}