import styled from "styled-components";
import { Colors } from "../../values/colors";

export const Container = styled.div`
  margin-top: 1rem;
  width: 100%;
  label {
    color: ${Colors.blueGray2};
    display: block;
    font-weight: 700;
    font-size: 1rem;
    text-align: left;
  }
  
  .input-container {
    width: 100%;
    border: 1px solid ${Colors.ultraLightGray};
    border-radius: 7px;
    box-shadow: inset 0px 8px 8px rgba(101, 126, 148, 0.04);
    display: block;
    background: white;
    outline: none;
    font-size: 1rem;
    font-weight: 700;
    margin-top: .5rem;

    input {
      background: transparent;
      border: 0;
      width: 100%;
      padding: .75rem .5rem;
      display: block;
      outline: none;
      font-size: 1rem;
      font-weight: 700;
    }
  }

`;

export const SuffixIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1rem;
  height: 100%;
`;