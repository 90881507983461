import styled, { keyframes } from "styled-components";
import { Colors } from "../../values/colors";

const SIDEBAR_WIDTH = 500;

const SidebarAnim = keyframes`
  from {
    transform: perspective(100px) rotateY(-10deg) translateX(${SIDEBAR_WIDTH}px);
		
		@media (max-width: 500px){
			transform: perspective(100px) rotateY(-10deg) translateX(100%);

		}

  }
  to {
    transform: perspective(100px) rotateY(0deg) translateX(0px);
  }
`;

export const Backdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	background: ${Colors.black40};
	width: 100%;
	height: 100%;
`;

export const Container = styled.div`
	background: white;
	position: absolute;
	right: 0;
	animation-name: ${SidebarAnim};
	animation-duration: .5s;
	padding: 2rem;
	top: 0;
	width: ${SIDEBAR_WIDTH}px;
	height: 100%;
	z-index: 100;

	@media (max-width: 500px){
		width: 100%;
	}
`;

export const DetailContainer = styled.div`
	background: white;
	position: absolute;
	overflow-y: auto;
	right: 0;
	padding: 2rem;
	top: 0;
	width: ${SIDEBAR_WIDTH}px;
	height: 100%;
	z-index: 100;
	transform: translateX(0px);
	transition: transform .3s ease-in-out;
	&.hide{
		transform: translateX(${SIDEBAR_WIDTH}px);
	}

	@media (max-width: 500px){
		width: 100%;

		&.hide{
			transform: translateX(100%);
		}
	}
`;

export const LineButton = styled.button`
	width: 100%;
	background: ${props => props.backgroundColor || 'none'};
	color: ${Colors.blueGray2};
	border: none;
	outline: inherit;
	border-bottom: 1px solid ${Colors.lightGray};
	font-size: 1rem;
	padding: .5rem 0;
	margin-top: 1rem;
	cursor: pointer !important;
`;
