import styled from "styled-components";


export const Container = styled.div`
  display: flex; 
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
  justify-content: stretch;

  .login-container {
    width: 50%;
    padding: 5rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    form {
      width: 100%;
    }
  }

  .horizontal-line{
    margin-top: 100px;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, #FFFFFF00, #FFFFFF,#FFFFFF00);
  }

  .home-text{
    color: white;
    font-weight: 600;
    font-size: 30px;
    white-space: pre-line;
    margin-top: 50px;
  }

  .login-title{
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 3rem;
  }

  @media (max-width: 869px){
    .login-container:first-child {
      // display: none;
      height: 200px;
      .home-text, .horizontal-line{
        display: none;
      }
    }
    
    flex-direction: column;

    .login-container {
      width: 100%;
    }
    .login-container:nth-child(2) {
      justify-content: start;
    }
  }
`;