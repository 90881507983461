import styled from "styled-components";
import { Colors } from "../../../../values/colors";



export const Container = styled.div`
  z-index: 5;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  overflow-y: auto;
  position: absolute;
  top: 55px;
  bottom: 65px;

  &.adjust-top{
    top: 0;
  }

  .message {
    position: relative;
    padding: 25px 25px 35px 25px;
    width: 90%;
    z-index: 6;
    white-space: pre-wrap;
    border: 1px solid #3ed1d3;
    .time {
      font-size: 10px;
      position: absolute;
      right: 15px;
      bottom: 12px;
    }
  }

`;


export const SentMessage = styled.div`
  border-radius: 25px 7px 7px 7px;
  background-color: white;
  align-self: flex-end;
  color: ${Colors.blueGray2};

  &.message {
    .time {
      color: ${Colors.mutedGray}
    }
  }
  `;

export const ReceivedMessage = styled.div`
  background-color: #68d4d6;
  border-radius: 7px 25px 7px 7px;
  font-weight: 600;
  color: white;

  .ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 10px;
  }
  .ellipsis div {
    position: absolute;
    /* top: 33px; */
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #ffffffaa;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ellipsis div:nth-child(1) {
    left: 8px;
    animation: ellipsis1 0.6s infinite;
  }
  .ellipsis div:nth-child(2) {
    left: 8px;
    animation: ellipsis2 0.6s infinite;
  }
  .ellipsis div:nth-child(3) {
    left: 20px;
    animation: ellipsis2 0.6s infinite;
  }
  .ellipsis div:nth-child(4) {
    left: 32px;
    animation: ellipsis3 0.6s infinite;
  }
  @keyframes ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(12px, 0);
    }
  }

`;