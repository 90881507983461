import styled, { css } from 'styled-components';
import { Colors } from '../../values/colors';


const getUserPicBgColor = (key) => {
  const userPicPalette = [
    '#8F77FF',
    '#56E2E3',
    '#F2B944',
    '#FE7B8F',
    '#6FDFBE'
  ];

  if (!(key >= 0)) {
    return userPicPalette[Math.floor((Math.random() * (userPicPalette.length - 1)) + 0)];
  }

  return userPicPalette[key === 0 ? 0 : key % userPicPalette.length];
}

export const UserContainer = styled.div`
  display: flex;
  align-items: center;

  &.inactive { 
    opacity: .5;
  }
`;

export const UserPic = styled.div`
  width: ${props => props.size || 53}px;
  height: ${props => props.size || 53}px;
  border-radius: 50%;
  ${props => props.user && props.user.picture ?
    css`
      background-image: url('${props.user.picture}');
      background-size: cover;
    `
    :
    css`
      background: ${getUserPicBgColor(props.user.key)};
      display: grid;
      place-items: center;
      &:after{
        content: '${props.user.name ? props.user.name.toString().substring(0, 1).toUpperCase() : '~'}';
        color: white;
        font-family: 'Nunito Sans', sans-serif;
        font-size: ${props => props.size ? (props.size - 50) + 'px' : '1rem'};
        font-weight: 700;
      }
    `
  }
  
`;

export const TextContainer = styled.div`
  margin-left: 1rem;
  flex-grow: 1;
  flex-basis: min-content;
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.h4`
  font-weight: 700;
  font-size: 15px;
`;

export const UserMail = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: ${Colors.cadetBlue};
`;