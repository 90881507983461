import { Copy } from "react-feather";
import { useTranslation } from "react-i18next";
import { Colors } from "../../values/colors";
import { View } from "../BaseComponents/styles";
import BreadCrumb from "../BreadCrumb";
import { Button } from "../Button";
import { DropdownSelect } from "../DropdownSelect/styles";
import { Line } from "../Line/styles";
import MacroContainer from "../MacroContainer";
import { Container, Content } from "./styles";


export const OptionsSection = ({options}) => {

    const [ t, i18n ] = useTranslation('common');

    return (
        <Container>
            <MacroContainer>
            <Line align={'space-between'}>
                {options && options.start ||
                <Line>
                    <BreadCrumb/>
                </Line>
                }
                {options && options.middle ||
                    null
                }
                {options && options.end &&
                <Line align={'end'} wrap={'wrap'}>
                    {!Array.isArray(options.end) ? 
                    options.end
                    :
                    options.end.map(op=> {
                        return <View style={{margin: 5}}>
                            {op}
                        </View>;
                    })
                    }
                </Line>
                }
            </Line>
            </MacroContainer>
        </Container>
    );
}