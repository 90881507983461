import { Box } from "../../components/Box";
import { useState } from "react";
import { useEffect } from "react";
import { Colors } from "../../values/colors";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import 'moment/locale/pt-br';
import NoData from "../../components/NoData";
import FlexPanel from "../../components/FlexPanel";
import { TextValue, Title, View } from "../../components/BaseComponents/styles";
import { Line } from "../../components/Line/styles";
import { UsageContainer } from "./styles";
import { TargetCircle } from "../Usage/TargetCircle";
import { TextContainer, UserContainer, UserName, UserPic } from "../Users/styles";
import { AccessCard } from "../../components/AccessCard";
import PcImage from "../../assets/images/dash_pc.png";
import User3dImage from "../../assets/images/users_3d.png";
import Employees from "../../services/Employees";
import Auth from "../../services/Auth";
import { usePermissions } from "../../hooks";


export default () => {
  const [ t, i18n ] = useTranslation('common');
  const [ loading, setLoading ] = useState(false);
  const [ newestUsers, setNewestUsers ] = useState([]);
  const [ stats, setStats ] = useState(null);
  const { checkPermissions } = usePermissions();
  const navigate = useNavigate();

  const getNewestUsers = async () => {
    const data = await Employees.getNewest();
    setNewestUsers(data.map(e => ({ key: e.id, name: e.name, picture: e.picture })))
  }

  const getStats = async () => {
    const data = await Employees.getStats();
    setStats(data);
  }
  
  useEffect(() => {
    setLoading(true);
    getNewestUsers();
    getStats();
    setLoading(false);
  }, [ ]);

  const renderUserCol = (row) => (
    <UserContainer style={{marginBottom: 12}}>
      <UserPic user={row} size={45}/>
      <TextContainer>
        <UserName>{row?.name}</UserName>
      </TextContainer>
    </UserContainer>
  );

  return (
    <>
    <FlexPanel>
      <View style={{flex: 5}}>
        <Box title={'Total de usuários'} noPadding >
          {loading ?
            <Line align={'center'} style={{margin: '1rem'}}>
              <NoData loading={loading} />
            </Line>
          :
          <UsageContainer>
            <View>
                <Title style={{color: 'black', fontSize: '1rem', fontWeight: '400'}}>
                  <Trans t={t} i18nKey="total_registered_users"/>
                </Title>
                <TextValue color={Colors.primaryPurple}>{stats?.total}</TextValue>

                <Title style={{color: 'black', fontSize: '1rem', fontWeight: '400', marginTop: 47 }}>
                  <Trans t={t} i18nKey="total_need_accept_benefit"/>
                </Title>
                <TextValue color={Colors.defaultBlue}>{stats?.pending}</TextValue>

            </View>
            <View>
                <Title style={{color: 'black', fontSize: '1rem', fontWeight: '400'}}>
                  <Trans t={t} i18nKey="total_actives"/>
                </Title>

                <Line style={{marginTop: '1rem'}}>
                  <TargetCircle current={stats?.active} target={stats?.total} size={200} color={Colors.defaultBlue}/>
                </Line>
            </View>

          </UsageContainer>
          }

        </Box>
        <FlexPanel>
          {checkPermissions('menu:dashboard', true) && <AccessCard onClick={()=>navigate('/dashboards')} title={'Dashboards'} subtitle={'Acesso aos dashboards'} image={PcImage} imageSize={150} gradient backgroundColor={['#4A2CD3', '#B19FFF']}/>}
          {checkPermissions('menu:users') && <AccessCard onClick={()=>navigate('/users')} title={'Usuários'} subtitle={'Acesso ao controle de usuários'} image={User3dImage} gradient backgroundColor={[Colors.defaultBlue, '#ACFEFF']}/>}
        </FlexPanel>

      </View>
      <Box style={{flex: 3}} title={'Últimos usuários que aceitaram o convite'}>
        {!newestUsers.length ?
        <Line align={'center'} style={{margin: '1rem'}}>
          <NoData loading={loading} />
        </Line>
        :
        newestUsers.map((r)=>{
          return renderUserCol(r);
        })}
      </Box>

    </FlexPanel>
    </>
  );
};