import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

import {
  createNewUserOn2b2PreRegister,
} from "../../../services/PreRegister";
import { SuccessMessage, ErrorMessage } from "../../../utils/alerts";

import {
  Container,
  Header,
  MenuBusinessImageContainer,
  MenuImageElipseContainer,
  MenuNamuBusinessLogo,
  MenuBusinessImage,
  MenuImage,
  ContentContainer,
  MainTitle,
  MainSubTitle,
  SuccessMain,
  SuccessContainer,
  DownloadLinkContainer,
  DownloadImage,
  CheckImg,
  SuccessTitle,
  SucessSubTitle,
} from "./styles";

import {Main, PreSingUpForm} from '../defaultStyles'

import {FormFieldBox} from '../../FormFieldBox';
import { ActionButton } from "../../ActionButton";

import { cpfMask, phoneMask } from "../../../utils/masks";

import Appstore from "../../../assets/images/appstore.png";
import Googleplay from "../../../assets/images/googleplay.png";


import NamuLogo from "../../../assets/images/namu_logo.svg";
import Plus from "../../../assets/images/plus.svg";
import Check from "../../../assets/images/check.svg";
import { useEffect, useState } from "react";

export const Success = () => {
  const { t } = useTranslation("common");
  return (
    <SuccessMain>
      <SuccessContainer>

      <CheckImg src={Check} />

      <SuccessTitle>
        {t("pre-registration-done-successfully-b2c")}
      </SuccessTitle>

      <SucessSubTitle>
        {t("download_the_app_and_log_in_with_your_account")}
      </SucessSubTitle>

      <DownloadLinkContainer onClick={() => window.open('http://onelink.to/namu', '_blank')}>
        <DownloadImage style={{width: '10.25rem'}} src={Appstore} />
        <DownloadImage src={Googleplay} />
      </DownloadLinkContainer>
      </SuccessContainer>
    </SuccessMain>
  );
};

const FORM_FIELDS_INITIAL_STATE = {
  name: "",
  email: "",
  cpf: "",
  phone: "",
  state: "",
  city: "",
};

const RECAPTCHA_SITE_KEY = "6LffZQQlAAAAAHohBImoUeks8i7wt3c_koHnDKeQ";

export const B2CTheme = ({businessInfo}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [formFields, setFormFields] = useState(FORM_FIELDS_INITIAL_STATE);
  const { t } = useTranslation("common");
  const { currentBusiness } = useParams();
  const [recaptchaValue, setRecaptchaValue] = useState("");

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`,
      function () {
        console.log("Script loaded!");
      }
    );
  }, []);

  const onChangeField = (value, fieldName) => {
    if (fieldName === "cpf") {
      return setFormFields((state) => ({
        ...state,
        [fieldName]: cpfMask(value),
      }));
    }
    if (fieldName === "phone") {
      return setFormFields((state) => ({
        ...state,
        [fieldName]: phoneMask(value),
      }));
    }
    setFormFields((state) => ({ ...state, [fieldName]: value }));
  };

  const onSubmitPreRegisterForm = async (event) => {
    try {
      event.preventDefault();

      if (!formFields.name || !formFields.email || !formFields.cpf || !formFields.phone) {
        return ErrorMessage({ message: "Por favor, preencha todos os campos obrigatórios." });
      }

      await window.grecaptcha.ready(async () => {
        await window.grecaptcha
          .execute(RECAPTCHA_SITE_KEY, { action: "submit" })
          .then((token) => {
            setRecaptchaValue(token);
            finallySubmit();
          })
          .catch((error) => {
            return ErrorMessage({
              message: "Preencha o recaptcha (não sou um robo!)",
            });
          });
      });
    } catch (error) {
      ErrorMessage({ message: t("failed-to-create-new-user") });
      console.warn(error);
    }
  };

  const finallySubmit = async () => {
    try {
      await createNewUserOn2b2PreRegister(formFields, currentBusiness);
      SuccessMessage({ message: t("new-user-successfully-created") });
      setIsSuccess(true);
    } catch (error) {
      ErrorMessage({ message: t("failed-to-create-new-user") });
      console.warn(error);
    }
  };

  return (
    <Container>
      <Header>
        {businessInfo?.logo && (
          <MenuBusinessImageContainer>
            <MenuBusinessImage src={businessInfo?.logo} />
          </MenuBusinessImageContainer>
        )}

        <MenuImageElipseContainer>
          <MenuImage src={Plus} />
        </MenuImageElipseContainer>
        <MenuNamuBusinessLogo>
          <MenuImage src={NamuLogo} />
        </MenuNamuBusinessLogo>
      </Header>
      <ContentContainer>
        {isSuccess ? (
          <Success />
        ) : (
          <Main>
            <MainTitle>{t("make-your-pre-registration")}</MainTitle>

            <MainSubTitle>{t("fill-in-the-fields-below")}</MainSubTitle>

            <PreSingUpForm onSubmit={onSubmitPreRegisterForm}>
              <FormFieldBox
                label={"Nome completo *"}
                placeholder={"Nome sobrenome"}
                name="name"
                onChange={(event) => onChangeField(event.target.value, "name")}
              />

              <FormFieldBox
                label={"E-mail *"}
                placeholder={"email@email.com"}
                name="email"
                type="email"
                onChange={(event) => onChangeField(event.target.value, "email")}
              />

              <FormFieldBox
                label={"Celular *"}
                placeholder={"(DDD) 99999-9999"}
                value={formFields.phone}
                name="phone"
                onChange={(event) => onChangeField(event.target.value, "phone")}
              />

              <FormFieldBox
                label={"CPF *"}
                placeholder={"000.000.000-00"}
                value={formFields.cpf}
                name="cpf"
                onChange={(event) => onChangeField(event.target.value, "cpf")}
              />

              <FormFieldBox
                label={"Estado"}
                placeholder={"Estado"}
                name="state"
                onChange={(event) => onChangeField(event.target.value, "state")}
              />

              <FormFieldBox
                label={"Cidade"}
                placeholder={"Cidade"}
                name="city"
                onChange={(event) => onChangeField(event.target.value, "city")}
              />

              <ActionButton
                class="g-recaptcha"
                data-sitekey="6LffZQQlAAAAAHohBImoUeks8i7wt3c_koHnDKeQ"
                data-callback="onSubmit"
                type="submit"
                style={{ marginTop: "1.5rem" }}
                color="#29C9CA"
              >
                Adicionar
              </ActionButton>
            </PreSingUpForm>
          </Main>
        )}
      </ContentContainer>
    </Container>
  );
};
