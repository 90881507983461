import axios from 'axios';
import { ErrorMessage } from '../utils/alerts';
import * as Sentry from "@sentry/react";

const api = axios.create({
  baseURL: 'https://apib2b.namu.com.br'
  // baseURL: 'https://fancy-yaks-itch-201-49-141-45.loca.lt'
  // baseURL: 'http://localhost:4000'
});

Sentry.init({
  dsn: "https://9be84cbadd7c417a965831797d0ff5be@o858798.ingest.sentry.io/6106655",
  tracesSampleRate: 1.0,
  autoSessionTracking: false,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    config.headers['Bypass-Tunnel-Reminder'] = 1;
    return config;
  }
);

api.interceptors.response.use((response) => response, 
  (error) => {
    if(error.response.status === 401){
      localStorage.removeItem('access_token');
      window.location = "/login";
    } else if(error.response.status === 403){
      const title = error.response.data.title;
      ErrorMessage({message: 'Você não tem permissões para acessar este recurso!', headerMessage: title ? <b>{title}</b> : null});
    } else {
      Sentry.captureException(error);
      return Promise.reject(error);
    }
  })

export default api;