import { Colors } from "../../values/colors";
import { BackgroundCircle } from "../BackgroundCircle";
import { Line } from "../Line/styles";
import { Container, Head, Body, Title, Bg, SubTitle } from "./styles";
import BgCircle from '../../assets/images/bg_circle.png';
import { View } from "../BaseComponents/styles";
import Image from "../Image";
import { ArrowRight } from "react-feather";

export const AccessCard = ({ title, subtitle, image, gradient, imageSize = 120, onClick, ...rest}) => {
  return (
    <Container gradient={gradient} onClick={onClick} {...rest}>
        <Line align={'space-between'} style={{width: '100%'}}>
          <View>
            <Title>{title}</Title>
            <SubTitle>{subtitle}</SubTitle>
            <ArrowRight size={20} color={'white'} />
          </View>
          {image && <Image src={image} style={{
            width: imageSize,
            height: imageSize,
            objectFit: 'contain'
          }} />}
        </Line>
        <Bg height="110%" src={BgCircle} />

    </Container>
  );
};