import styled from 'styled-components';

export const Span = styled.span`
  font-weight: 700;
  font-size: 1rem;
  color: #FFFFFF;
  margin-right: .3rem;
  pointer-events: none;
`;

export const Link = styled.a`
  font-weight: 700;
  font-size: 1rem;
  color: #FFFFFF;
`;