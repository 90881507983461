import { Link, useLocation } from "react-router-dom";
import { Colors } from "../../values/colors";

export const TextLink = ({ children, to, color, style }) => {
  const location = useLocation();
  const match = location.pathname === to;

  return (
      <Link 
        className={match ? "active" : ""}
        to={to} 
        style={{
          color: color || Colors.mediumGray,
          textDecoration: 'none',
          fontWeight: 700,
          fontSize: '1rem',
          ...style
        }}
      >{children}</Link>
  );
}