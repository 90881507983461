import styled from "styled-components";

export const DropdownSelect = styled.select`
    background: transparent;
    border: 1px solid white;
    border-radius: 7px;
    max-width: 250px;
    padding: 12px;
    color: white;
    font-size: 0.9rem;
    font-weight: 400;

    option {
        color: #000;
    }
`;