import { Bar, BarCaption, BarContainer, BarFlexContainer, BarLine, BarNumberLabel, BarPercent, BarProgress, BarValue } from "./styles";


export const PercentBar = ({ name, value, total, color, style, labelStyle, hideQtd }) => {
  const percent = parseInt(value * 100 / total) || 0;
  return (
    <BarContainer style={style}>
      <BarFlexContainer>
        <BarCaption style={{...labelStyle, marginBottom: hideQtd ? 0 : -12}}>{name}</BarCaption>
        <BarLine>
          <Bar>
            <BarProgress percent={percent} color={color}/>
          </Bar>
            <BarNumberLabel>
              {!hideQtd && <BarValue style={labelStyle}>{value}</BarValue>}
              <BarPercent style={labelStyle}>{percent}%</BarPercent>
            </BarNumberLabel>
        </BarLine>
      </BarFlexContainer>
    </BarContainer>
  );
};