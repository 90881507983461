import { useState } from "react";
import { Edit2 } from "react-feather";
import { Colors } from "../../../../values/colors";
import { Container, Dropdown, InputText, Item, SuffixIcon } from "./styles";


export const InputInfoText = ({ onValueChange, style, options, ...props }) => {
  const [ open, setOpen ] = useState(false);

  const onChange = (value) => {
    // setInputValue(value);
    if (onValueChange) onValueChange(value);
  };

  return (
    <Container>
      <InputText {...props} style={{...style, paddingRight: 30}} onChange={(e) => { onValueChange(e.target.value) }} onFocus={() => setOpen(true)} onBlur={(e) => { setTimeout(() => setOpen(false), 250)}}/>
      {!props.disabled &&
      <SuffixIcon>
       <Edit2 size={14} color={Colors.mediumGray}/>
      </SuffixIcon>
      }
      {options && <Dropdown open={open}>
        {options.map(option => <Item onClick={(e) => {onChange(option)}}>{option}</Item>)}
      </Dropdown>}
    </Container>
  );


}