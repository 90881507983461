import React from 'react';
import { Container, ReceivedMessage, SentMessage } from './styles';
import Manu from '../../../../assets/images/manu.png';
import moment from 'moment';
import { useState } from 'react';
import { useEffect } from 'react';
import { Badge } from '../../../../components/BaseComponents/styles';
import { Colors } from '../../../../values/colors';

const MessageContainer = ({ data, loading, adjustToTop, messagesRef }) => {


  const [ messages, setMessages ] = useState([]);

  useEffect(()=>{

    const result = data.reduce((acc, obj) => {
      const dateKey = moment(obj.timestamp).format('YYYY-MM-DD');
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(obj);
      return acc;
    }, {});

    const formatted = Object.fromEntries(
      Object.entries(result).sort(([date1], [date2]) => new Date(date1) - new Date(date2))
    );

    setMessages(formatted);


  }, [data]);

  const renderDay = (date) => {
    const formattedDate = moment(date).calendar(null, {
      sameDay: '[Hoje]',
      lastDay: '[Ontem]',
      lastWeek: 'DD/MM/YYYY',
      sameElse: 'DD/MM/YYYY',
      nextDay: '[Amanhã]'
    })
    return <Badge color={'primaryBlue2'} style={{alignSelf: 'center'}}>{formattedDate}</Badge>
  }

  return (

    <Container ref={messagesRef} className={adjustToTop ? 'adjust-top' : ''}>
      
      {Object.entries(messages).map(([date, entries]) => {
        // console.log(t);
        return <>
          {renderDay(date)}
          {entries.map((message, i) => {
            return message.type == 'user' ?
              <SentMessage className="message" key={Math.random()}>
                <p>{message.text}</p>
                <span className="time">{message.timestamp ? moment(message.timestamp).format('D [de] MMMM [às] HH:mm') : ''}</span>
              </SentMessage>
              :
              <ReceivedMessage className="message" key={Math.random()}>
                {(loading && i == data.length - 1) ? 
                  <div className="ellipsis"><div></div><div></div><div></div><div></div></div>
                :
                message.text}
                <span className="time">{message.timestamp ? moment(message.timestamp).format('D [de] MMMM [às] HH:mm'): ''}</span>
              </ReceivedMessage>
    
          })}
        </>
      })
        
      
      }

    </Container>

  );

}


export default MessageContainer;