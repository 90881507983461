import styled from 'styled-components';
import { Upload } from "react-feather";

export const FileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;


export const FileInputButton = styled.label`
  display: inline-block;
  padding: 0px 3px;
  background-color: #4FD5AE;
  border: none;
  border-radius: 30px;
  color: #FFFFFF;
  font-size: 16px;
  cursor: pointer !important;
`;

export const Icon = styled(Upload)`
  margin-right: 5px;
`;

export const ImageLogo = styled.img`
  width: 200px;
`;

export const NewCompanie = styled.p`
  display: flex;
  margin-bottom: 20px;
  margin-top: -38px;
  justify-content: space-around;
  font-size: 32px;
  font-weight: 700; 
  color: #FFFF;
`;