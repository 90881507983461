
import { Container } from "./styles";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Img } from "../../components/Image/styles";
import CircleBgBlue from '../../assets/images/circle_bg.svg';
import Header from "./components/Header";
import SendContainer from "./components/SendContainer";
import MessageContainer from "./components/MessageContainer";
import { Colors } from "../../values/colors";
import axios from "axios";
import moment from 'moment';
import { ErrorMessage } from "../../utils/alerts";
import { useCallback } from "react";

// const sourceEndpoint = 'http://localhost:4000';
const sourceEndpoint = 'https://apib2b.namu.com.br';

export default () => {
  const [ params ] = useSearchParams();

  const [ token, setToken ] = useState(params.get('token'));

  const [ hideHeader, setHideHeader ] = useState(!!params.get('hideHeader') || false);

  const[ loading, setLoading ] = useState(false);
  const [ messages, setMessages ] = useState([]);

  const messagesRef = useRef();

  useEffect(() => {
    document.body.style.background = Colors.lightBg;
    document.body.style.overflow = 'hidden';

    fetchMessages();
  }, []);

  const fetchMessages = useCallback(async() => {
    try{
      const {data } = await axios.get(`${sourceEndpoint}/manu/messages?token=`+token);
      setMessages(data.messages.map(d => ({
        type: d.role, 
        text: d.message,
        timestamp: moment(d.timestamp).toLocaleString()
      })));

      if(data.isNew) {
        setTimeout(() => {
          streamMessage('Olá', true);
        }, 2000)
      }
    }catch(e){
      ErrorMessage({message: 'Houve um problema, tente novamente em breve.'});
    }
  }, []);

  
  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 200)
  }, [ messages ]);


  const scrollToBottom = () => {
    messagesRef.current.scrollTop = messagesRef.current?.scrollHeight;
  }

  const streamMessage = (text, isNew = false) => {
    let concatenatedMsg = '';
    const source = new EventSource(`${sourceEndpoint}/manu/chat?token=${token}&message=${text}&isNew=${isNew}`);
    
    source.onopen = (e)=>{
      if(!isNew) insertMessage('user', text);
      insertMessage('assistant', '');
    }
  
    source.onmessage = (event)=>{
      setLoading(false);
  
      const data = JSON.parse(event.data);
  
      if(data?.done || data?.error){
        source.close();
  
        if(data?.error){
          ErrorMessage({message: data?.error});
        }
  
        return;
      }
      
      concatenatedMsg += data.part;
      setMessages((prev) => {
        const i = prev.length;
        const _new = [...prev];
        _new[i - 1].text = concatenatedMsg.replace(/\\n/gi, '\n');
        return _new;
      });
  
    };
  
    source.onerror = (event)=>{
      setLoading(false);
      source.close();
      throw event;
      
    };

  }

  const submit = (text) => {
    setLoading(true);
    try{
      streamMessage(text)
    }catch(e){
      console.log(e);
      ErrorMessage({message: 'Houve um problema, tente novamente em breve.'});
    }

    // setLoading(false);
  }

  const insertMessage = (type, text, timestamp = moment().toLocaleString()) => {
    setMessages(prev => {
      return [ ...prev, {type, text, timestamp}]
    });
  }
 
  return (
    <Container>

      {!hideHeader && <Header/>}
      <MessageContainer adjustToTop={hideHeader} messagesRef={messagesRef} loading={loading} className="message-container" data={messages}/>
      <SendContainer onSubmit={submit}/>
      <Img src={CircleBgBlue} className="circle-bg" />
    </Container>);
}