import { Line } from "../Line/styles";
import { Container, Head, Body, Title } from "./styles";

export const Box = ({ title, children, noPadding, noHeader, actions, headStyle, bodyStyle, ...rest }) => {
  return (
    <Container {...rest}>
      {!noHeader && <Head style={headStyle}>
        <Line align={'space-between'}>
          <Title>{title}</Title>
          {actions && 
          <Line align={'space-evenly'}>
            {actions}
          </Line>}
        </Line>
      </Head>}
      <Body style={bodyStyle} noPadding={noPadding}>{children}</Body>
    </Container>
  );
};