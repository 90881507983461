import Image from "../../../components/Image";
import LogoFullWhite from "../../../assets/images/logo_full_white.svg";
import { Container } from "./styles";
import { FlatButton, Input, Text, Title, View } from "../../../components/BaseComponents/styles";
import { Colors } from "../../../values/colors";
import { useTranslation } from "react-i18next";
import { BackgroundCircle } from "../../../components/BackgroundCircle";
import { FormFieldBox } from "../../../components/FormFieldBox";
import { Eye, EyeOff } from "react-feather";
import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { TextLink } from "../../../components/TextLink";
import { Line } from "../../../components/Line/styles";
import { useEffect, useState } from "react";
import { ActionButton } from "../../../components/ActionButton";
import Auth from "../../../services/Auth";
import { ErrorMessage, SuccessMessage } from '../../../utils/alerts';
import { useQuery } from "../../../hooks";
import TranslateError from "../../../utils/error";

export default () => {
  const navigate = useNavigate();
  const [ params ] = useSearchParams();

  const  [ t, i18n ] = useTranslation('common');

  const [ token, setToken ] = useState(params.get('token'));
  const [ email, setEmail ] = useState('');
  const [ showPass, setShowPass ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);

  const location = useLocation();

  const onSubmit = async (e) => {
    e.preventDefault();
    if(token){
      submitResetPassword();
    }else{
      submitSendMail();
    }
  }

  const submitSendMail = async () => {
    setIsLoading(true);
    try{
      const response = await Auth.sendResetMail({email})
      if (response.success) {
          setIsLoading(false);
          SuccessMessage({message: 'E-mail enviado com sucesso!'});
      } else {
          setIsLoading(false);
          if (response.error) {
              let errorMessage = TranslateError(response.error);
              ErrorMessage({ message: errorMessage })
          }
      }
    } catch (error) {
        setIsLoading(false);
        console.log(error);
    }
  }

  const submitResetPassword = async () => {
    if (password !== confirmPassword) {
        ErrorMessage({ message: 'As senhas não conferem' })
        return;
    }
    setIsLoading(true);
    try{
      let data = {
          password: password,
          token
      }

      const response = await Auth.resetPassword(data);

      if (response.success) {
        SuccessMessage({message: 'Senha redefinida com sucesso!'});
        window.location.href = '/login';
      } else {
          setIsLoading(false);
          if (response.error) {
              let errorMessage = TranslateError(response.error);
              ErrorMessage({ message: errorMessage })
          }
      }
      
    } catch (error) {
        setIsLoading(false);
        console.log(error);
    }
  }

 
  return (
    !Auth.signedIn ?
    <Container>
      <View className="login-container" style={{backgroundColor: Colors.primaryBlue2}}> 
        <View style={{zIndex: 1}}>
          <Image src={LogoFullWhite}/>
          <div className="horizontal-line"></div>
          <Title className="home-text">{t('homepage_text')}</Title> 
        </View>

        <BackgroundCircle circleStyle={{zIndex: 0, background: Colors.primaryBlue, position: 'absolute'}} level={0}/>
        <BackgroundCircle circleStyle={{zIndex: 0, background: `${Colors.primaryBlue}66`, position: 'absolute'}} level={1}/>
      </View>
      <View className="login-container" style={{alignItems: 'start', textAlign: 'left'}}>

        <Title className="login-title">{t(!token ? 'forgot_my_pass' : 'reset_pass')}</Title>

        <form onSubmit={onSubmit}>

          {!token ? 
          <>
            <Text>{'Digite seu e-mail para receber um e-mail de redefinição de senha.'}</Text>
            <FormFieldBox
              label={'E-mail'}
              labelStyle={{fontSize: '1.2rem'}}
              onChange={e => setEmail(e.target.value)}
            />
          </>
          :
          <>
            <Text>{'Digite sua nova senha.'}</Text>
            <FormFieldBox
              type={showPass ? 'text' : 'password'}
              label={'Senha'}
              labelStyle={{fontSize: '1.2rem'}}
              onChange={e => setPassword(e.target.value)}
              suffixIconButton={
                <FlatButton
                  type='button'
                  onClick={()=>setShowPass(prev=>!prev)}
                >
                  {
                    showPass ? 
                    <EyeOff color={Colors.primaryPurple}/>
                    :
                    <Eye 
                      color={Colors.primaryPurple}
                      />

                  }
                </FlatButton>
              }
            />
            <FormFieldBox
              type={showPass ? 'text' : 'password'}
              label={'Confirmar senha'}
              labelStyle={{fontSize: '1.2rem'}}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </>

          }


          <Line align={'space-between'} style={{marginTop: '3rem'}}>
            <ActionButton isLoading={isLoading}>
              {token ? t('reset') : t('send_mail')}
            </ActionButton>
            <TextLink color={Colors.primaryPurple} to="/login">{t('do_login')}</TextLink>
          </Line>
        </form>

      </View>
    </Container>
  : 
  <Navigate to="/" replace state={{ path: location.pathname }}/>
  );
}