import API from '../api';

/**
 * @typedef Business
 * @prop {string} title
 * @prop {number} name
 * @prop {string} cnpj
 * @prop {string} cnpj
 * @prop {string} cep
 * @prop {string} street
 * @prop {string} city
 * @prop {string} state
 * @prop {string} neighborhood
 * @prop {number} employees_count
 * @prop {string} field
 */

export default class {
  /**
   * @param {Business} data
   */
  static async get () {
    const res = await API.get('/business');
    return res.data;
  }

  static async update (data) {
    const res = await API.put('/business', data);
    return res.data;
  }

  static async getPlanMetaByKey (key) {
    const res = await API.get(`/business/meta/${key}`);
    return res.data;
  }
}