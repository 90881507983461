import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Span, Link } from './styles';

export default () => {
  const location = useLocation();

  const [ t, i18n ] = useTranslation('common');

  const paths = {
    '': t('overview'),
    'user_profiler': t('user_profiler'),
    'usage': t('usage'),
    'indicators': t('indicators'),
    'tele_stats': t('teleattendances'),
    'report': t('management_report'),
    'users': t('users'),
    'dashboards': t('internal_dashboard'),
    'vitalscan-alerts': t('vitalscan_alerts'),
    'general-dashboard': t('general_dashboard'),
    'new_dashboard': t('new_dashboard')
  };


  return (
    <>
      <Span>{t('home')}</Span>
      { !!paths[location.pathname.slice(1)] &&
        <>
        <Span>{'>'}</Span>
        <Link href={location.pathname}>{paths[location.pathname.slice(1)]}</Link>
        </>
      }
    </>
  );
};