import styled from "styled-components";

export default styled.div`
  display: flex;
  
  & > div, & > button {
    flex: 1;
  }

  & > div:not(:last-of-type), & > button:not(:last-of-type) {
    margin-right: 1rem;
  }

  @media(max-width: 1080px) {
    display: block;

    & > div:not(:last-of-type), & > button:not(:last-of-type) {
      margin-right: 0;
    }
  }
`;