import styled, { css, keyframes } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding 1rem;
`

export const Label = styled.div`
  font-weight: bold;
  color: #BBC3CD;
`

const spinning = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
`

export const Spinner = styled.div`
  animation: ${spinning} 2s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
`

